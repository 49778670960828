var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Zqa,$qa,ara,bra,V2,W2,X2,Y2,cra,dra,era,fra,gra,hra,ira,jra,kra,h3,i3,lra,mra,nra,g3,f3,ora,j3;$CLJS.P2=function(a,b){$CLJS.cb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Zqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Zk(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};$qa=function(a,b){return function f(d,e){return new $CLJS.je(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Dd(k,t))l=$CLJS.Ic(m);else{k=$CLJS.be(l,f($CLJS.Ic(m),$CLJS.Zd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
ara=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ta($CLJS.y($CLJS.rz(c,d)))?c:$CLJS.P.M($CLJS.nW,c,d,e,b)};$CLJS.Q2=function(a){return(0,$CLJS.na)($CLJS.wz(a,$CLJS.Yma,""))};$CLJS.R2=function(a,b){return $CLJS.d0.g(a,b instanceof $CLJS.M?b:$CLJS.uk.h(b))};
$CLJS.S2=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Zj.g($CLJS.iV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.F1.v(a,b,f,c)}),d);return $CLJS.Zj.g(function(f){return $CLJS.T.j(f,$CLJS.BY,$CLJS.Dd(e,f))},c)}return null};$CLJS.T2=function(a){return $CLJS.E.g($CLJS.OA(a),$CLJS.fK)};bra=function(a,b){return $CLJS.n($CLJS.ID.h(a))?$CLJS.ak.j(a,$CLJS.ID,function(c){return $CLJS.sd(c)?$CLJS.Zj.g(function(d){return $CLJS.U2.g?$CLJS.U2.g(d,b):$CLJS.U2.call(null,d,b)},c):c}):a};
V2=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.vd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.vd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.de(d,$CLJS.aF))try{var e=$CLJS.F(a,3);if($CLJS.vd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.de(f,$CLJS.aF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Ua,k)))}else a=null;return a};W2=function(a){if($CLJS.n(V2(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};X2=function(a){return $CLJS.n(V2(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
Y2=function(a,b){var c=$CLJS.U2;if($CLJS.Ta(V2(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
cra=function(a,b,c){return $CLJS.pd($CLJS.nK.h(a))?a:$CLJS.n(b)?ara(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.nK],null),function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.de(m,$CLJS.aF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.zc===t.mf||(t.C?0:$CLJS.Ya($CLJS.ub,t)):$CLJS.Ya($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.nO,$CLJS.vU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.U2.g?$CLJS.U2.g(k,c):$CLJS.U2.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.QV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.ak.j(a,$CLJS.nK,function(d){return $CLJS.Zj.g(function(e){return Y2(e,$CLJS.H([c]))},d)})};
$CLJS.U2=function(a,b){var c=$CLJS.OA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.xD(a,$CLJS.vD,$CLJS.H([$CLJS.nO,b]));case "metadata/column":return $CLJS.vD(a,$CLJS.SY,b);case "mbql/join":return c=$CLJS.U0(a),cra(bra($CLJS.vD(a,$CLJS.MD,b),b),c,b);default:return b=$CLJS.Cz($CLJS.ux),$CLJS.n($CLJS.Bz("metabase.lib.join",b))&&$CLJS.Az("metabase.lib.join",b,$CLJS.pv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.Z2=function(a,b,c){var d=$CLJS.T.l,e=$CLJS.pz($CLJS.lY,$CLJS.U)(c);a=$CLJS.V0($CLJS.MD.h(a),$CLJS.pz($CLJS.lY,$CLJS.U)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.T,c,$CLJS.lY,e,$CLJS.H([$CLJS.AY,b]))};dra=function(a,b){return $CLJS.Ta(b)?a:$CLJS.Zj.g(function(c){var d=X2(c);d=$CLJS.n(d)?$CLJS.n($CLJS.U0(d))?null:Y2(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.$2=function(a,b){b=dra($CLJS.Zj.g($CLJS.jV,b),$CLJS.U0(a));return $CLJS.vD(a,$CLJS.nK,$CLJS.Ce(b))};
$CLJS.a3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.Vy:$CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.cf.h($CLJS.iV),function(){var c=$CLJS.U0(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.U2(d,c)}):$CLJS.Qd}()),b));return $CLJS.vD(a,$CLJS.ID,b)};
era=function(a,b){b=$CLJS.vl(function(d){return $CLJS.F1.g(d,a)},b);var c=$CLJS.jG($CLJS.pz($CLJS.wZ,$CLJS.xZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.jG($CLJS.wZ,a);if($CLJS.n(b))return b;b=$CLJS.jG($CLJS.xZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};fra=function(a){return"string"===typeof a?(0,$CLJS.na)($CLJS.wz(a,/ id$/i,"")):null};
gra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.a2(b)});var d=new $CLJS.uh(function(){return $CLJS.a2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
hra=function(a,b,c){var d=$CLJS.WZ.g($CLJS.E.g($CLJS.Os.h(b),$CLJS.$U)?b:a,b),e=$CLJS.n(c)?fra($CLJS.WZ.g(a,c)):null,f=gra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ta($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
ira=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.s1(l,$CLJS.aF)&&$CLJS.Ad($CLJS.F1.v(a,b,l,e)))return $CLJS.U2(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.QV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
jra=function(a,b,c,d,e,f){c=ira(a,b,c,d,f);return function t(l,m){try{if($CLJS.vd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.s1(u,$CLJS.aF))try{var v=$CLJS.F(m,3);if($CLJS.s1(v,$CLJS.aF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.U0(A),S=$CLJS.U0(x);if($CLJS.Ta($CLJS.n(K)?K:S))return $CLJS.n($CLJS.F1.v(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[C,G,$CLJS.U2(A,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[C,G,A,$CLJS.U2(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var Y=
$CLJS.xD(A,$CLJS.Qj,$CLJS.H([$CLJS.nO])),ba=$CLJS.xD(x,$CLJS.Qj,$CLJS.H([$CLJS.nO])),ha=null==$CLJS.F1.v(a,b,Y,e);var ra=ha?$CLJS.F1.v(a,b,ba,e):ha;return $CLJS.n(ra)?new $CLJS.Q(null,4,5,$CLJS.R,[C,G,A,ba],null):new $CLJS.Q(null,4,5,$CLJS.R,[C,G,Y,x],null)}return m}throw $CLJS.Z;}catch(Ab){if(Ab instanceof Error){var Ra=Ab;if(Ra===$CLJS.Z)throw $CLJS.Z;throw Ra;}throw Ab;}else throw $CLJS.Z;}catch(Ab){if(Ab instanceof Error){Ra=Ab;if(Ra===$CLJS.Z)throw $CLJS.Z;throw Ra;}throw Ab;}else throw $CLJS.Z;
}catch(Ab){if(Ab instanceof Error){Ra=Ab;if(Ra===$CLJS.Z)return $CLJS.QV(t,l,m);throw Ra;}throw Ab;}}($CLJS.xf,c)};kra=function(a,b){var c=$CLJS.AV();$CLJS.P2(c,b);return c(a)};
$CLJS.b3=function(a,b,c){if($CLJS.Dd(c,$CLJS.MD))return c;var d=$CLJS.xV(a,b),e=$CLJS.I_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Ua,function v(t,u){try{if($CLJS.vd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.ek.j(u,0,1);if($CLJS.vd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.de(A,$CLJS.aF))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.sU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.nK.h(c))));f=era(e,f);var k=kra(hra(a,c,f),$CLJS.vl($CLJS.MD,$CLJS.vM.h(d))),l=$CLJS.G_.h($CLJS.N0.g(a,$CLJS.kK.h(c)));return $CLJS.U2($CLJS.ak.j(c,$CLJS.nK,function(t){return $CLJS.Zj.g(function(u){return jra(a,b,u,k,e,l)},t)}),k)};$CLJS.c3=function(a){return $CLJS.nK.h(a)};
$CLJS.d3=function(a){return $CLJS.pk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Os,$CLJS.oK,$CLJS.tD,a],null),$CLJS.E.g(a,$CLJS.RD)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.e3=function(a,b){b=$CLJS.z($CLJS.kK.h(b));return $CLJS.n($CLJS.GU.h(b))?$CLJS.SZ(a,$CLJS.GU.h(b)):$CLJS.n($CLJS.ZN.h(b))?$CLJS.QZ(a,$CLJS.ZN.h(b)):null};
h3=function(a){a=$CLJS.iG(function(d){return $CLJS.xZ(d)?f3:$CLJS.wZ(d)?g3:$CLJS.Ks},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,f3);var c=$CLJS.J.g(b,g3);b=$CLJS.J.g(b,$CLJS.Ks);return $CLJS.bf.l(a,c,$CLJS.H([b]))};i3=function(a,b,c,d){return $CLJS.Ta(c)?d:$CLJS.Zj.g(function(e){return $CLJS.n($CLJS.BY.h(e))?$CLJS.R2(e,$CLJS.Z1(c)):e},$CLJS.S2(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};
lra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.I_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.l_,!1,$CLJS.u_,!1],null))});return $CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.pl(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.QJ);return $CLJS.n(function(){var m=$CLJS.wZ(k);return m?l:m}())?(f=$CLJS.jG(function(m){return $CLJS.E.g(l,$CLJS.Di.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.T.j(k,j3,f):null):null}),$CLJS.I_.j(a,b,c)))};mra={};
nra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);g3=new $CLJS.M(null,"fk","fk",398442651);f3=new $CLJS.M(null,"pk","pk",-771936732);ora=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);j3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.x_.m(null,$CLJS.fK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.kK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.ZN);b=$CLJS.J.g(b,$CLJS.GU);c=$CLJS.n(c)?$CLJS.cD.h($CLJS.QZ(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.SZ(a,b),a=$CLJS.n(c)?$CLJS.WZ.j(a,0,c):$CLJS.Z0(b)):a=null;return $CLJS.n(a)?a:$CLJS.AD("Native Query")});$CLJS.E_.m(null,$CLJS.fK,function(a,b,c){a=$CLJS.WZ.j(a,b,c);c=$CLJS.MD.h(c);return new $CLJS.h(null,2,[$CLJS.U,$CLJS.n(c)?c:a,$CLJS.cD,a],null)});
$CLJS.D_.m(null,$CLJS.fK,function(){throw $CLJS.Th("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.x_.m(null,$CLJS.oK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.tD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.AD("Left outer join");case "right-join":return $CLJS.AD("Right outer join");case "inner-join":return $CLJS.AD("Inner join");case "full-join":return $CLJS.AD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.E_.m(null,$CLJS.oK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.tD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.v_,$CLJS.Qz(e),$CLJS.cD,$CLJS.WZ.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.T.j(a,$CLJS.Oh,!0):a});
$CLJS.F_.m(null,$CLJS.fK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.MD),k=$CLJS.J.j(e,$CLJS.ID,$CLJS.Vy),l=$CLJS.J.g(e,$CLJS.kK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.s_);if($CLJS.E.g(k,$CLJS.Vy))return null;var t="undefined"!==typeof $CLJS.YK&&"undefined"!==typeof mra&&"undefined"!==typeof $CLJS.pra&&"undefined"!==typeof $CLJS.k3?new $CLJS.Dc(function(){return $CLJS.k3},$CLJS.kd(ora,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),null):null,u=function(){var v=$CLJS.T.j(a,$CLJS.kK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.ox)?$CLJS.G_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.je(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.wd(C)){var G=$CLJS.jc(C),K=$CLJS.D(G),S=$CLJS.me(K);a:for(var Y=0;;)if(Y<K){var ba=$CLJS.hd(G,Y);ba=$CLJS.xD(ba,$CLJS.Qj,$CLJS.H([$CLJS.nO]));ba=$CLJS.UZ.j(u,-1,ba);S.add(ba);Y+=1}else{G=!0;break a}return G?$CLJS.pe($CLJS.re(S),A($CLJS.kc(C))):$CLJS.pe($CLJS.re(S),null)}S=$CLJS.z(C);S=$CLJS.xD(S,$CLJS.Qj,
$CLJS.H([$CLJS.nO]));return $CLJS.be($CLJS.UZ.j(u,-1,S),A($CLJS.Ic(C)))}return null}},null,null)}(k)}();return $CLJS.Zj.g(function(v){v=$CLJS.T.j(v,$CLJS.OY,f);v=$CLJS.U2($CLJS.T.l(v,$CLJS.cD,$CLJS.WZ.j(a,b,v),$CLJS.H([$CLJS.EJ,$CLJS.xY])),f);return $CLJS.Z2(e,m,v)},c)});$CLJS.H_.m(null,$CLJS.fK,function(a,b,c,d){return $CLJS.G_.v(a,b,$CLJS.T.j(c,$CLJS.ID,$CLJS.ox),d)});
var l3,qra=$CLJS.Se($CLJS.N),rra=$CLJS.Se($CLJS.N),sra=$CLJS.Se($CLJS.N),tra=$CLJS.Se($CLJS.N),ura=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));l3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.OA,ura,qra,rra,sra,tra);l3.m(null,$CLJS.fK,function(a){return a});l3.m(null,$CLJS.$U,function(a){return $CLJS.hV(new $CLJS.h(null,2,[$CLJS.Os,$CLJS.fK,$CLJS.kK,$CLJS.kK.h($CLJS.vV(a))],null))});
l3.m(null,$CLJS.NU,function(a){return $CLJS.hV(new $CLJS.h(null,2,[$CLJS.Os,$CLJS.fK,$CLJS.kK,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});l3.m(null,$CLJS.bK,function(a){return $CLJS.hV(new $CLJS.h(null,2,[$CLJS.Os,$CLJS.fK,$CLJS.kK,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.GU,$CLJS.Di.h(a),$CLJS.Os,$CLJS.NU],null)],null)],null))});
l3.m(null,$CLJS.KJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.SY);a=$CLJS.J.g(b,nra);b=l3.h(new $CLJS.h(null,3,[$CLJS.Os,$CLJS.NU,$CLJS.kA,new $CLJS.h(null,1,[$CLJS.pD,$CLJS.p.h($CLJS.zD())],null),$CLJS.ZN,$CLJS.Di.h(b)],null));c=$CLJS.n(c)?$CLJS.U2(b,c):b;return $CLJS.n(a)?$CLJS.a3.g?$CLJS.a3.g(c,a):$CLJS.a3.call(null,c,a):c});
$CLJS.m3=function(){function a(d,e){return $CLJS.$2(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.q1(l3.h(d),$CLJS.ID,$CLJS.ox)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vra=function(){function a(d,e,f){var k=$CLJS.m3.h(f);f=$CLJS.pd($CLJS.c3.h?$CLJS.c3.h(k):$CLJS.c3.call(null,k))?function(){var m=$CLJS.e3.g?$CLJS.e3.g(d,k):$CLJS.e3.call(null,d,k);return $CLJS.n3.j?$CLJS.n3.j(d,e,m):$CLJS.n3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.$2(k,f):k;var l=$CLJS.b3(d,e,f);return $CLJS.JV.l(d,e,$CLJS.ak,$CLJS.H([$CLJS.vM,function(m){return $CLJS.Zd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.o3=function(){function a(d,e){return $CLJS.Ce($CLJS.J.g($CLJS.xV(d,e),$CLJS.vM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wra=function(){function a(d){d=$CLJS.J0(d);d=$CLJS.Wy.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.ef($CLJS.Qe($CLJS.Dd,d)),$CLJS.cf.h($CLJS.d3)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.RD,$CLJS.HD,$CLJS.VD,$CLJS.YD],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xra=function(){function a(d,e,f,k){var l=$CLJS.T2(f)?$CLJS.U0(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.Xj.g($CLJS.cf.h($CLJS.U0),Zqa(function(t){return $CLJS.Wj.g(t,l)})),$CLJS.o3.g(d,e));f=$CLJS.n(k)?k:$CLJS.T2(f)?W2($CLJS.z($CLJS.c3(f))):null;return h3(i3(d,e,f,$CLJS.Yj.g(function(t){t=$CLJS.U0(t);return $CLJS.n(t)?$CLJS.Dd(m,t):null},$CLJS.I_.v(d,e,$CLJS.xV(d,e),new $CLJS.h(null,1,[$CLJS.l_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.yra=function(){function a(d,e,f,k,l){k=$CLJS.T2(f)?$CLJS.e3(d,f):f;var m=$CLJS.T2(f)?$CLJS.U0(f):null;f=$CLJS.n(l)?l:$CLJS.T2(f)?X2($CLJS.z($CLJS.c3(f))):null;f=$CLJS.n(f)?$CLJS.Ta(m)?$CLJS.xD(f,$CLJS.Qj,$CLJS.H([$CLJS.nO])):f:null;return h3(i3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.T.j(t,$CLJS.EJ,$CLJS.xY);return $CLJS.n(m)?$CLJS.U2(t,m):t},$CLJS.I_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.l_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.zra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.K1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.K1};return b}();
$CLJS.n3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Qj.g(u,$CLJS.a1);v=$CLJS.Qj.g(v,$CLJS.a1);return $CLJS.O2.l($CLJS.I1.h($CLJS.Cj),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Ce($qa(function(x){return $CLJS.Di.h(j3.h(x))},lra(d,e,u,v)))}var m=$CLJS.xV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Zj.g(function(v){return k(v,j3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Zj.g(function(u){return k(j3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Ara=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.T2(k)?W2($CLJS.z($CLJS.c3(k))):null;$CLJS.n(l)?(l=$CLJS.ZZ.j(e,f,l),l=$CLJS.rz(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GV,$CLJS.cD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.wV(e,f)){l=$CLJS.o3.g(e,f);var m=$CLJS.pd(l);k=m?m:$CLJS.T2(k)?$CLJS.E.g($CLJS.MD.h(k),$CLJS.MD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.LZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.LZ(e),k=$CLJS.QZ(e,k),e=$CLJS.WZ.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.AD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Bra=function(){function a(d,e,f,k){f=$CLJS.jV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.uk.h(k);e=$CLJS.wV(d,e);l=$CLJS.h0.j(d,e,l);d=$CLJS.h0.j(d,e,m);m=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.uk,l)),k);d=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.uk,d)),k);f=m?$CLJS.ak.v(f,2,$CLJS.R2,k):f;return d?$CLJS.ak.v(f,3,$CLJS.R2,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.A_.m(null,$CLJS.vM,function(a,b){var c=$CLJS.Ce($CLJS.o3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.WZ.j(a,b,d)},c);return null==c?null:$CLJS.as(" + ",c)});
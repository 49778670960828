var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var osa=function(a,b){return new $CLJS.je(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Ic(d);else break a}return d},null,null)},psa=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jt(a,b),osa(a,b)],null)},u4=function(a){var b=$CLJS.pk.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.n4);c=$CLJS.J.g(c,$CLJS.o4);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.i4.h;d=$CLJS.mh.h(d);var e=$CLJS.q($CLJS.f4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.i4,d);c=new $CLJS.h(null,2,[$CLJS.m4,c,$CLJS.p4,c],null)}else c=new $CLJS.h(null,1,[$CLJS.p4,2],null);b=b.call($CLJS.pk,$CLJS.H([c,a]));return $CLJS.n($CLJS.q4.h(a))?$CLJS.T.l($CLJS.Qj.g(b,$CLJS.q4),$CLJS.p4,$CLJS.q4.h(a),$CLJS.H([$CLJS.m4,$CLJS.q4.h(a)])):b},v4=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=v4[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=v4._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("NumberFormatter.format-number-basic",
a);}return a},qsa=function(a,b){var c=$CLJS.Dy(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.fi,!0],null)]));a=$CLJS.n($CLJS.Ne(function(d){return $CLJS.E.g($CLJS.Ni.h(d),"exponentMinusSign")},c))?c:function(){var d=psa(function(f){return $CLJS.Wj.g($CLJS.Ni.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.bf.l(e,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.Ni,"exponentPlusSign",$CLJS.aj,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.P.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.Ni);e=$CLJS.J.g(e,$CLJS.aj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},rsa=function(a,b){switch($CLJS.o4.h(b)){case "scientific":return function(c){return qsa(a,c)};default:return function(c){return a.format(c)}}},w4=function(a,b,c,d,e){this.options=a;this.Bg=b;this.Eg=c;this.de=d;this.li=e;this.C=393216;this.I=0},ssa=function(a,b){return $CLJS.P.g($CLJS.p,function(){return function e(d){return new $CLJS.je(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.hd(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.Ni);A=$CLJS.J.g(A,$CLJS.aj);var C=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.Ni);t=$CLJS.J.g(t,$CLJS.aj);if($CLJS.n(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.be($CLJS.E.g(u,"integer")?b:t,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null},null,null)}($CLJS.Dy(a.Bg.formatToParts(1),$CLJS.H([$CLJS.fi,!0])))}())},x4=function(a){var b=$CLJS.E.g($CLJS.o4.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.rk($CLJS.zz($CLJS.Ua,$CLJS.zg([$CLJS.lsa,
$CLJS.dsa,$CLJS.gsa,$CLJS.isa,$CLJS.nsa,$CLJS.n4,$CLJS.hsa,$CLJS.jsa,$CLJS.esa,$CLJS.fsa],[$CLJS.p4.g(a,b),$CLJS.s4.h(a),$CLJS.r4.h(a),$CLJS.ksa.h(a),$CLJS.E.g($CLJS.o4.h(a),"scientific")?null:$CLJS.o4.g(a,"decimal"),$CLJS.n4.h(a),!0,$CLJS.m4.g(a,b),$CLJS.E.g($CLJS.o4.h(a),"scientific")?"scientific":null,$CLJS.t4.h(a)]))));var c=$CLJS.s4.h(a);var d=$CLJS.n4.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=rsa(b,a);return new w4(a,b,c,d,$CLJS.N)},y4=function(a,b){return x4(u4(b)).Oe(null,a)},A4=
function(a,b){var c=$CLJS.Qj.l(b,$CLJS.qA,$CLJS.H([$CLJS.o4])),d=$CLJS.oz(a);if(0===a)return"0";if(1E3>d)return $CLJS.z4.g?$CLJS.z4.g(a,c):$CLJS.z4.call(null,a,c);b=$CLJS.z($CLJS.ff(function(f){return d>=$CLJS.z(f)},tsa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.pk.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.m4,1,$CLJS.p4,1],null)]));return $CLJS.z4.g?$CLJS.z4.g(f,k):$CLJS.z4.call(null,f,k)}()),$CLJS.p.h(b)].join("")},usa=function(a,b){var c=u4(b);if($CLJS.n(B4.h(c)))b=
B4.h(c);else if($CLJS.Ta($CLJS.q4.h(c))&&$CLJS.Ta($CLJS.m4.h(c))&&$CLJS.Wj.g($CLJS.o4.h(c),"currency")&&$CLJS.oz(a)<($CLJS.E.g($CLJS.o4.h(c),"percent")?.01:1)){b=$CLJS.T.j;var d=$CLJS.Qj.g(c,$CLJS.p4);c=$CLJS.t4.g(c,0);b=x4(b.call($CLJS.T,d,$CLJS.r4,2>c?2:c))}else b=x4(c);return v4(b,a)};w4.prototype.P=function(a,b){return new w4(this.options,this.Bg,this.Eg,this.de,b)};w4.prototype.O=function(){return this.li};
w4.prototype.Oe=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.msa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Wj.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.ed(c),a=$CLJS.wz(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Eg)&&(b=$CLJS.n4.h(this.options),c=$CLJS.g4(b),a=$CLJS.wz($CLJS.wz(a,[$CLJS.Xg(b)," "].join(""),c),$CLJS.Xg(b),c));return a};
var B4=new $CLJS.M(null,"number-formatter","number-formatter",929788393),C4=new $CLJS.M(null,"scale","scale",-230427353),D4=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),vsa=new $CLJS.M(null,"scientific","scientific",316285837);var E4=new $CLJS.h(null,1,[$CLJS.s4,"symbol"],null),wsa=$CLJS.rk(E4),tsa=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[1E12,"T"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E9,"B"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E6,"M"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E3,"k"],null)],null),F4=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.o4)},e,a,b,c,d)}();F4.m(null,$CLJS.Oh,function(a,b){return A4(a,b)});F4.m(null,"percent",function(a,b){return[$CLJS.p.h(A4(100*a,b)),"%"].join("")});F4.m(null,"currency",function(a,b){b=$CLJS.pk.l($CLJS.H([b,E4]));var c=x4(b);return 1E3>$CLJS.oz(a)?c.Oe(null,a):ssa(c,A4(a,b))});F4.m(null,"scientific",function(a,b){return y4(a,$CLJS.pk.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.p4,1,$CLJS.m4,1],null)])))});
$CLJS.z4=function z4(a,b){b=$CLJS.Wz(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.qA);var e=$CLJS.J.g(d,D4),f=$CLJS.J.g(d,$CLJS.o4),k=$CLJS.J.g(d,C4);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Qj.g(d,C4),z4.g?z4.g(b,f):z4.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.T.j(d,D4,!1);return z4.g?z4.g(l,m):z4.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?F4.g(a,u4($CLJS.Qj.g(d,$CLJS.qA))):$CLJS.E.g($CLJS.mh.h(f),vsa)?y4(a,d):usa(a,d)};
module.exports={compact_currency_options_js:wsa,format_number:$CLJS.z4};
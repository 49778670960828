var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var iva,jva,lva,mva,nva,ova;iva=function(a,b){return $CLJS.yz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};jva=function(a,b){return $CLJS.ec($CLJS.cb(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.bc($CLJS.N),b))};lva=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.Xj.g($CLJS.XC(function(b){return $CLJS.LV(b,a)}),$CLJS.cf.h($CLJS.ed)),kva)};
mva=function(a){return new $CLJS.h(null,3,[$CLJS.Ni,$CLJS.nA,$CLJS.U,a,$CLJS.Di,$CLJS.p.h($CLJS.zD())],null)};
nva=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.U);return $CLJS.pk.l($CLJS.H([a,function(){var c=$CLJS.dh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.lV(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.nM,$CLJS.WP,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.na)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.yL,$CLJS.kL,c],null):null}(),$CLJS.n($CLJS.cD.h(a))?null:new $CLJS.h(null,
1,[$CLJS.cD,$CLJS.S0.g($CLJS.R0,b)],null)]))};$CLJS.A8=function(a){a=$CLJS.J0(a);return $CLJS.n($CLJS.rz(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wy,ova],null)))?$CLJS.Zd.g($CLJS.Sg,$CLJS.dN):$CLJS.Sg};$CLJS.B8=function(a,b){var c=$CLJS.A8(a);return(0,$CLJS.JV)(a,0,function(d){var e=$CLJS.Vs.g(pva,c);d=$CLJS.P.j($CLJS.Qj,d,e);d=$CLJS.pk.l($CLJS.H([d,$CLJS.xl(b,c)]));$CLJS.Vs.g(c,$CLJS.Wg($CLJS.Ng(b)));$CLJS.xV(a,0);return d})};$CLJS.C8=function(a){return $CLJS.Ce($CLJS.xl($CLJS.xV(a,0),$CLJS.A8(a)))};
$CLJS.D8=function(a){return $CLJS.cP.h($CLJS.xV(a,0))};ova=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);var kva,pva;kva=new $CLJS.Q(null,3,5,$CLJS.R,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.E8=function(){function a(d,e){d=$CLJS.Ce(lva(d));var f=$CLJS.Ce($CLJS.Wg($CLJS.Ng(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.Vs.g(d,f);d=$CLJS.Vs.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.cD.h(f),$CLJS.S0.g($CLJS.R0,d))?$CLJS.S0.g($CLJS.R0,k):$CLJS.cD.h(f);f=$CLJS.T.l($CLJS.Qj.l(f,$CLJS.kL,$CLJS.H([$CLJS.WP,$CLJS.NO])),$CLJS.cD,l,$CLJS.H([$CLJS.U,k]));e=$CLJS.T.j($CLJS.Qj.g(e,d),k,f)}else e=$CLJS.pk.l($CLJS.H([iva($CLJS.Oe(d),
e),jva($CLJS.U,$CLJS.cf.g(mva,k))]));e=$CLJS.$r(e,nva)}else e=$CLJS.N;return e}function b(d){return $CLJS.E8.g?$CLJS.E8.g(d,null):$CLJS.E8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();pva=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.dN,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.hD],null)],null));
$CLJS.F8=function(){function a(d,e,f,k){var l=$CLJS.E8.h(e);return $CLJS.B8($CLJS.N0.g(d,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.Os,$CLJS.UU,$CLJS.gU,f,$CLJS.zP,l,$CLJS.cP,e],null)],null)),k)}function b(d,e){return $CLJS.F8.v?$CLJS.F8.v(d,e,null,null):$CLJS.F8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.G8=function(){function a(d,e,f){$CLJS.xV(d,0);return $CLJS.B8($CLJS.N0.g(e,$CLJS.kK.h(d)),f)}function b(d,e){return $CLJS.G8.j?$CLJS.G8.j(d,e,null):$CLJS.G8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.M0.m(null,$CLJS.UU,function(a){return $CLJS.E0($CLJS.A8(a),$CLJS.Wg($CLJS.Ng($CLJS.C8(a))))&&!$CLJS.xz($CLJS.D8(a))});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var dpa,epa,fpa,T_,U_,V_,gpa,hpa,ipa,X_,Y_,jpa;$CLJS.S_=function(a){var b=$CLJS.ed($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.eH($CLJS.H([$CLJS.kH])),"$"].join("")),a));return $CLJS.n(b)?b:a};dpa=function(a,b){return a.isSame(b,"day")};epa=function(a,b){return a.isSame(b,"month")};fpa=function(a,b){return a.isSame(b,"year")};
T_=function(a){return $CLJS.Ne(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.Bk);d=$CLJS.J.g(d,$CLJS.P_);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.FH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null):null):null},$CLJS.cpa)};U_=function(a){return a instanceof Date?$CLJS.FH.utc(a):a};V_=function(a,b,c){b="string"===typeof b?$CLJS.z(T_(b)):U_(b);return("string"===typeof c?$CLJS.z(T_(c)):U_(c)).diff(b,$CLJS.Xg(a))};
gpa=function(a,b){return V_.j?V_.j($CLJS.Fk,a,b):V_.call(null,$CLJS.Fk,a,b)};hpa=function(a,b){if("string"===typeof a)return a;a=U_(a);var c=function(){if($CLJS.tz(b,$CLJS.Ck))return $CLJS.M_;if($CLJS.tz(b,$CLJS.Gk))return $CLJS.L_;if($CLJS.tz(b,$CLJS.xk))return $CLJS.Q_;if($CLJS.tz(b,$CLJS.Ak))return $CLJS.N_;if($CLJS.tz(b,$CLJS.wk))return $CLJS.O_;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.R_.h(new $CLJS.Q(null,2,5,$CLJS.R,[a,c],null))};
$CLJS.W_=function(a){if($CLJS.n($CLJS.FH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.FH)($CLJS.S_(a),ipa);throw $CLJS.Th("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.aj,a],null));};ipa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];X_=function X_(a,b){if("string"===typeof a){var d=T_(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=X_.g?X_.g(a,b):X_.call(null,a,b);return $CLJS.R_.h(new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null))}a=U_(a);return a.startOf($CLJS.Xg(b))};
Y_=function Y_(a,b,c){if("string"===typeof a){var e=T_(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=Y_.j?Y_.j(a,b,c):Y_.call(null,a,b,c);return $CLJS.R_.h(new $CLJS.Q(null,2,5,$CLJS.R,[b,e],null))}a=U_(a);return a.add(c,$CLJS.Xg(b))};jpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.Z_=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g($CLJS.fH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.$_=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g($CLJS.K_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(fpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(gpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.opa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(V_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.a0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(X_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ppa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(Y_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.qpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.P.g(hpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.b0=function b0(a){switch(arguments.length){case 1:return b0.h(arguments[0]);case 2:return b0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.ya("metabase.shared.util.time.coerce_to_timestamp",$CLJS.b0);$CLJS.b0.h=function(a){return $CLJS.b0.g(a,$CLJS.N)};
$CLJS.b0.g=function(a,b){b=$CLJS.pk.l($CLJS.H([$CLJS.N,$CLJS.Wz(b)]));if(!$CLJS.n($CLJS.J_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.FH.parseZone(a):"string"===typeof a?$CLJS.gH.g(a,b):$CLJS.hH.g(a,b)}return $CLJS.n(jpa.h(b))?a.local():a};$CLJS.b0.A=2;module.exports={coerce_to_timestamp:$CLJS.b0,coerce_to_time:$CLJS.W_};
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Toa,KZ,Uoa,MZ,NZ,OZ,i_,Xoa,Woa,Yoa,Voa,p_,$Z,r_,VZ,Zoa,b_;Toa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};KZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Dd($CLJS.Zfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Uoa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.lA,!0],null)),c=$CLJS.J.g(b,$CLJS.Yfa),d=$CLJS.J.g(b,$CLJS.lA);return $CLJS.y(a)?(b=$CLJS.oU(function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=KZ(x,$CLJS.Ta(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);return $CLJS.be(KZ(u,
$CLJS.Ta(d)),k($CLJS.Ic(l)))}return null}},null,null)}(Toa($CLJS.Rz(a)))}()),$CLJS.n(c)?$CLJS.oU($CLJS.We(c,b)):b):null};$CLJS.LZ=function(a){return $CLJS.ZN.h($CLJS.z($CLJS.kK.h(a)))};MZ=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=MZ[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=MZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.table",a);}return a};
NZ=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=NZ[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=NZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.card",a);}return a};OZ=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=OZ[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=OZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.fields",a);}return a};
$CLJS.PZ=function(a){return $CLJS.kY(a)?a:$CLJS.ZC.h(a)};$CLJS.QZ=function(a,b){return MZ($CLJS.PZ(a),b)};$CLJS.RZ=function(a,b){return OZ($CLJS.PZ(a),b)};$CLJS.SZ=function(a,b){return NZ($CLJS.PZ(a),b)};$CLJS.TZ=function(a,b,c){if($CLJS.Va(b)||$CLJS.td(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Se($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.zh.v(d,$CLJS.T,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
i_=function(a,b,c){var d=$CLJS.UZ.j(a,b,c);return $CLJS.pk.l($CLJS.H([$CLJS.xl(d,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,$CLJS.cD,$CLJS.mj],null)),function(){var e=$CLJS.IV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.cD,e,VZ,!0],null):null}(),function(){var e=$CLJS.WZ.v(a,b,c,$CLJS.XZ);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.YZ,e],null):null}(),function(){var e=$CLJS.pz($CLJS.ki,$CLJS.jA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ki,e],null):null}(),function(){var e=$CLJS.TJ.h(d);return $CLJS.n(e)?
(e=$CLJS.Bd(e)?$CLJS.QZ(a,e):"string"===typeof e?$CLJS.SZ(a,$CLJS.yV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.GV,$CLJS.ZZ.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.EJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[$Z,$CLJS.E.g(e,$CLJS.mY),$CLJS.a_,$CLJS.E.g(e,$CLJS.xY),b_,$CLJS.E.g(e,$CLJS.uY),$CLJS.c_,$CLJS.E.g(e,$CLJS.wY),Voa,$CLJS.E.g(e,$CLJS.dK),Woa,$CLJS.E.g(e,$CLJS.VJ)],null):null}(),function(){var e=$CLJS.BY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.d_,e],null)}(),function(){var e=
$CLJS.pz($CLJS.BV,$CLJS.gF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.e_,$CLJS.Dd($CLJS.gG,e)],null):null}(),$CLJS.xl(d,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.f_,$CLJS.g_,$CLJS.h_],null))]))};$CLJS.j_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.h_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.k_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.c_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.f_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.l_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.d_=new $CLJS.M(null,"selected","selected",574897764);Xoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Woa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Yoa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.m_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.n_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Voa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
p_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.q_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);$Z=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);r_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);VZ=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.s_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.a_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.e_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.t_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.g_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Zoa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.YZ=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.u_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.XZ=new $CLJS.M(null,"long","long",-171452093);$CLJS.v_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.w_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);b_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.x_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();
$CLJS.y_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();
$CLJS.WZ=function(){function a(f,k,l,m){var t=$CLJS.pz($CLJS.cD,$CLJS.gD)($CLJS.wD(l));if($CLJS.n(t))return t;try{return $CLJS.x_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Sh($CLJS.YC("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.lU(k)])),new $CLJS.h(null,2,[$CLJS.wE,f,$CLJS.qD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.z_=function(){function a(d,e,f){var k=$CLJS.U.h($CLJS.wD(f));if($CLJS.n(k))return k;try{return $CLJS.y_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Sh($CLJS.YC("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.lU(k)])),new $CLJS.h(null,3,[$CLJS.qD,f,$CLJS.wE,d,$CLJS.xK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.x_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Cz($CLJS.wx);$CLJS.n($CLJS.Bz("metabase.lib.metadata.calculation",a))&&$CLJS.Az("metabase.lib.metadata.calculation",a,$CLJS.mV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Yoa,$CLJS.OA(c)])),null);return $CLJS.vd(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.y_.m(null,$CLJS.Oh,function(a,b,c){return Uoa($CLJS.wz($CLJS.WZ.j(a,b,c),/[\(\)]/,""))});$CLJS.A_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.$oa=function(){function a(d,e,f){return $CLJS.A_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.B_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();
$CLJS.C_=function(){function a(d,e,f){var k=$CLJS.wD(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.gF),m=$CLJS.ki.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Dd($CLJS.gG,l):l)?$CLJS.Vi:null;if($CLJS.n(l))return l;k=$CLJS.jA.h(k);if($CLJS.n(k))return k;d=$CLJS.B_.j(d,e,f);return $CLJS.tz(d,$CLJS.Zi)?d:$CLJS.Zi}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.B_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.DD(c)});$CLJS.B_.m(null,$CLJS.TD,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.C_.j(a,b,c)});$CLJS.B_.m(null,$CLJS.PH,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.DD(c):$CLJS.C_.j(a,b,d)});
$CLJS.D_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();
$CLJS.D_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Os,$CLJS.CJ,$CLJS.jA,$CLJS.C_.j(a,b,c),$CLJS.U,$CLJS.z_.j(a,b,c),$CLJS.cD,$CLJS.WZ.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Sh($CLJS.YC("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.OA(c)])),$CLJS.lU(d)])),new $CLJS.h(null,3,[$CLJS.wE,a,$CLJS.xK,b,$CLJS.qD,c],null),d);}throw e;}});
$CLJS.UZ=function(){function a(e,f,k){return $CLJS.D_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.E_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();
$CLJS.X(p_,new $CLJS.Q(null,20,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[VZ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GV,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,p_],null)],null)],null),
new $CLJS.Q(null,3,5,$CLJS.R,[$Z,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.a_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[b_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.c_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[r_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Zoa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Xoa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.v_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.t_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.k_,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,$CLJS.YE,$CLJS.RE],null)],null)],null));
$CLJS.ZZ=function(){function a(d,e,f){return $CLJS.TZ($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.E_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Sh($CLJS.YC("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.OA(k),$CLJS.lU(l)])),new $CLJS.h(null,3,[$CLJS.wE,d,$CLJS.xK,e,$CLJS.qD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E_.m(null,$CLJS.Oh,function(a,b,c){return i_(a,b,c)});$CLJS.E_.m(null,$CLJS.KJ,function(a,b,c){return $CLJS.pk.l($CLJS.H([i_(a,b,c),new $CLJS.h(null,1,[r_,$CLJS.E.g($CLJS.LZ(a),$CLJS.Di.h(c))],null)]))});
$CLJS.F_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();$CLJS.F_.m(null,$CLJS.mA,function(){return $CLJS.xf});
$CLJS.G_=function(){function a(f,k,l,m){m=$CLJS.pk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.s_,$CLJS.AV()],null),m]));return $CLJS.F_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.xV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.H_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();$CLJS.H_.m(null,$CLJS.mA,function(){return $CLJS.xf});
$CLJS.H_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.F_.v(a,b,c,d)});
$CLJS.I_=function(){function a(f,k,l,m){m=$CLJS.pk.l($CLJS.H([$CLJS.pk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.s_,$CLJS.AV()],null),new $CLJS.h(null,4,[$CLJS.q_,!0,$CLJS.w_,!0,$CLJS.l_,!0,$CLJS.u_,!0],null)])),m]));return $CLJS.H_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.xV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.WZ};
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uK,wK,yK,BK,EK;$CLJS.sK=function(a){return $CLJS.Hh($CLJS.q($CLJS.YA),a,$CLJS.eF)};$CLJS.x5=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.C5=new $CLJS.M(null,"row","row",-570139521);$CLJS.tK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.E5=new $CLJS.M(null,"object-id","object-id",-754527291);uK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.vK=new $CLJS.M(null,"operators","operators",-2064102509);wK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.xK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);yK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.zK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.AK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
BK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.CK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.DK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);EK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.A6=new $CLJS.M(null,"null","null",-180137709);
$CLJS.FK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(wK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,$CLJS.nD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gF,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.VC],null)],null)],null));$CLJS.X(EK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,wK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.rD],null)],null)],null));$CLJS.X(yK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.aF],null),EK,$CLJS.hD],null));
$CLJS.X(BK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.aF],null),wK,$CLJS.aK],null));
$CLJS.zF.g($CLJS.aF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.aF],null),wK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.aK,$CLJS.hD],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.si,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.OA(a)):null},$CLJS.ms,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.Q(null,2,
5,$CLJS.R,[$CLJS.bA,BK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aA,yK],null)],null)],null));$CLJS.gE($CLJS.aF,$CLJS.eF);$CLJS.CD.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ki,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.PD});$CLJS.nE($CLJS.gA,$CLJS.H([$CLJS.hD]));$CLJS.CD.m(null,$CLJS.gA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ki,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.PD});
$CLJS.gE($CLJS.gA,$CLJS.eF);$CLJS.X(uK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,$CLJS.nD,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tK,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.hD],null)],null)],null));
$CLJS.zF.g($CLJS.QE,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.QE],null),uK,$CLJS.pj],null));$CLJS.CD.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.pz($CLJS.ki,$CLJS.jA)(b);return $CLJS.n(a)?a:$CLJS.PD});$CLJS.gE($CLJS.QE,$CLJS.eF);$CLJS.nE($CLJS.yE,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.FJ],null)],null)]));$CLJS.gE($CLJS.yE,$CLJS.eF);
$CLJS.nE($CLJS.WE,$CLJS.H([$CLJS.Sr,$CLJS.PD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.DJ],null)],null)]));$CLJS.gE($CLJS.WE,$CLJS.eF);
$CLJS.X($CLJS.eF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.ED,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.xs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.as(", ",$CLJS.sz($CLJS.Ed,$CLJS.sk.g($CLJS.q($CLJS.YA),$CLJS.eF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.sK(a)}],null)],null));
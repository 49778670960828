var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k0,zpa,Apa,l0,m0;$CLJS.i0=function(a,b){return $CLJS.ec($CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.dc(c,d):c},$CLJS.bc($CLJS.xf),b))};k0=function(a){return $CLJS.n(j0)?["(",$CLJS.p.h(a),")"].join(""):a};zpa=function(a,b,c){var d=$CLJS.xV(a,b);a=$CLJS.I_.j(a,b,d);var e=$CLJS.Rz(c);return $CLJS.Ne(function(f){return $CLJS.E.g($CLJS.Rz($CLJS.U.h(f)),e)},a)};
Apa=function(a,b){var c=$CLJS.ak.v(a,$CLJS.TO,$CLJS.GC($CLJS.Zd,$CLJS.xf),b);return $CLJS.vd($CLJS.ID.h(a))?$CLJS.ak.v(c,$CLJS.ID,$CLJS.Zd,$CLJS.hV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,$CLJS.N,$CLJS.qV(b)],null))):c};l0=function(a,b,c){var d=$CLJS.qV(c);return $CLJS.T.l($CLJS.UZ.j(a,b,c),$CLJS.EJ,$CLJS.uY,$CLJS.H([$CLJS.U,d,$CLJS.cD,d]))};m0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.n0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var o0=function(){function a(d,e,f){var k=$CLJS.xV(d,e);k=$CLJS.jG($CLJS.Xj.g($CLJS.Tg([f]),$CLJS.qV),$CLJS.TO.h(k));if($CLJS.n(k))return k;throw $CLJS.Th($CLJS.YC("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.TQ,f,$CLJS.wE,d,$CLJS.xK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.B_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=o0.j(a,b,c);return $CLJS.C_.j(a,b,c)});$CLJS.D_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Os,$CLJS.CJ,$CLJS.n0,$CLJS.pD.h(d),$CLJS.U,e,$CLJS.gD,e,$CLJS.cD,$CLJS.WZ.j(a,b,c),$CLJS.jA,$CLJS.C_.j(a,b,c),$CLJS.EJ,$CLJS.uY],null)});$CLJS.x_.m(null,$CLJS.bA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.x_.m(null,$CLJS.$z,function(a,b,c){return $CLJS.p.h(c)});$CLJS.x_.m(null,$CLJS.aA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.x_.m(null,$CLJS.pA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.x_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.y_.m(null,$CLJS.gA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var j0=!1,Bpa=new $CLJS.h(null,4,[$CLJS.Dq,"+",$CLJS.Sr,"-",$CLJS.Eq,"×",$CLJS.uF,"÷"],null),p0=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Dq,$CLJS.Sr,$CLJS.uF,$CLJS.Eq],null)),q0=null,r0=0,s0=0;;)if(s0<r0){var Cpa=q0.X(null,s0);$CLJS.gE(Cpa,m0);s0+=1}else{var t0=$CLJS.y(p0);if(t0){var u0=t0;if($CLJS.wd(u0)){var v0=$CLJS.jc(u0),Dpa=$CLJS.kc(u0),Epa=v0,Fpa=$CLJS.D(v0);p0=Dpa;q0=Epa;r0=Fpa}else{var Gpa=$CLJS.z(u0);$CLJS.gE(Gpa,m0);p0=$CLJS.B(u0);q0=null;r0=0}s0=0}else break}
$CLJS.x_.m(null,m0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(Bpa,c);a:{var e=j0;j0=!0;try{var f=$CLJS.as([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Qs($CLJS.WZ,a,b),d));break a}finally{j0=e}f=void 0}return k0(f)});$CLJS.y_.m(null,m0,function(){return"expression"});
$CLJS.B_.m(null,$CLJS.qG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Nd($CLJS.iE,function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.C_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);return $CLJS.be($CLJS.C_.j(a,
b,u),k($CLJS.Ic(l)))}return null}},null,null)}(d)}())});$CLJS.x_.m(null,$CLJS.TA,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.WZ.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.c0.g(f,c).toLowerCase();0<f?f=$CLJS.ia.j?$CLJS.ia.j("+ %d %s",f,c):$CLJS.ia.call(null,"+ %d %s",f,c):(f=$CLJS.oz(f),f=$CLJS.ia.j?$CLJS.ia.j("- %d %s",f,c):$CLJS.ia.call(null,"- %d %s",f,c));f=k0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.y_.m(null,$CLJS.TA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.z_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.c0.g(e,c).toLowerCase();0<e?e=$CLJS.ia.j?$CLJS.ia.j("plus_%s_%s",e,c):$CLJS.ia.call(null,"plus_%s_%s",e,c):(e=$CLJS.oz(e),e=$CLJS.ia.j?$CLJS.ia.j("minus_%d_%s",e,c):$CLJS.ia.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.x_.m(null,$CLJS.dF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.WZ.v(a,b,e,d)});$CLJS.y_.m(null,$CLJS.dF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.z_.j(a,b,d)});
$CLJS.Hpa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(zpa(d,e,f)))throw $CLJS.Th("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.TQ,f],null));return $CLJS.JV.l(d,e,Apa,$CLJS.H([$CLJS.rV($CLJS.jV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Ipa=function(){function a(d,e){var f=$CLJS.Ce($CLJS.TO.h($CLJS.xV(d,e)));return null==f?null:$CLJS.Zj.g($CLJS.Qs(l0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.w0=function(){function a(d,e){return $CLJS.Ce($CLJS.TO.h($CLJS.xV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.gV.m(null,$CLJS.gA,function(a){return a});
$CLJS.Jpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.wl(function(m,t){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qV(t),m],null)},$CLJS.w0.g(d,e))),l=$CLJS.xV(d,e);d=$CLJS.I_.j(d,e,l);return $CLJS.Ce($CLJS.i0(function(m){return $CLJS.Ta(f)||$CLJS.Wj.g($CLJS.EJ.h(m),$CLJS.uY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Kpa=function(){function a(d,e,f){return $CLJS.iV(l0(d,e,o0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.x0=$CLJS.dE.h($CLJS.OD);
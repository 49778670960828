var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var e9,d9,Vxa,Wxa,Xxa,l9,Yxa,Zxa,$xa,n9,aya,bya,f9,q9,cya,s9,dya,eya,fya;$CLJS.c9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ba(a,b):null};e9=function(a,b){return d9(a,b,$CLJS.N)};
d9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,f9,!0);if($CLJS.td(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.c9(b,"_plainObject");d=null==d?null:$CLJS.Ey(d);return null==d?null:$CLJS.Ce(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.Xj.j($CLJS.cf.h(function(d){return new $CLJS.Q(null,2,5,$CLJS.R,[d,$CLJS.c9(b,d)],null)}),$CLJS.Yj.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Aa(b))};
Vxa=function(a){var b=g9.h(a),c=h9.h(a),d=i9.h(a);return $CLJS.Xj.j($CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.R;f=$CLJS.mh.h(j9(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.Q(null,2,5,k,[f,e],null)}),$CLJS.pd(b)?$CLJS.Qd:$CLJS.Yj.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Dd(b,f)}),$CLJS.Ta(c)?$CLJS.Qd:$CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.Q(null,2,5,
$CLJS.R,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};Wxa=function(a){return e9($CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.mh.h(j9(c));c=$CLJS.E.g(c,$CLJS.uU)?$CLJS.tD:c;b=$CLJS.E.g(c,$CLJS.tD)?$CLJS.mh.h(b):b;return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)}),a)};
Xxa=function(a){var b=$CLJS.jG(function(d){d=$CLJS.c9(d,"type");var e=new $CLJS.Rg(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.mh.h($CLJS.c9(b,"type"));return $CLJS.pk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Di,$CLJS.c9(b,"id"),$CLJS.U,$CLJS.c9(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.S:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.Os,$CLJS.cK,$CLJS.NJ,$CLJS.c9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.Os,$CLJS.XJ],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};l9=function(a){return $CLJS.Zj.g(k9.g($CLJS.aF,$CLJS.N),a)};
Yxa=function(a,b){var c=k9.g($CLJS.nM,new $CLJS.h(null,1,[f9,!1],null)),d=k9.g($CLJS.nM,$CLJS.N);return $CLJS.pk.l($CLJS.H([$CLJS.T.j(function(){var e=$CLJS.c9($CLJS.c9(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.Di,b),function(){var e=$CLJS.c9($CLJS.c9(a,"questions"),$CLJS.p.h(b));var f=$CLJS.c9(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};Zxa=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.m9=new $CLJS.M(null,"cards","cards",169174038);$xa=new $CLJS.M(null,"binning-info","binning-info",-2117036653);n9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);aya=new $CLJS.M(null,"db","db",993250759);$CLJS.o9=new $CLJS.M(null,"metrics","metrics",394093469);bya=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.p9=new $CLJS.M(null,"segments","segments",1937535949);f9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
q9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);cya=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.r9=new $CLJS.M(null,"tables","tables",1334623052);s9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);dya=new $CLJS.M(null,"schema-name","schema-name",1666725119);eya=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);fya=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.t9=new $CLJS.M(null,"databases","databases",2040134125);var j9=$CLJS.Ah($CLJS.Vz),g9,gya=$CLJS.Se($CLJS.N),hya=$CLJS.Se($CLJS.N),iya=$CLJS.Se($CLJS.N),jya=$CLJS.Se($CLJS.N),kya=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null)),w9,Aya,Bya,Cya,Dya,Eya;g9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.mh,kya,gya,hya,iya,jya);g9.m(null,$CLJS.Oh,function(){return null});
var h9,lya=$CLJS.Se($CLJS.N),mya=$CLJS.Se($CLJS.N),nya=$CLJS.Se($CLJS.N),oya=$CLJS.Se($CLJS.N),pya=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));h9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.mh,pya,lya,mya,nya,oya);h9.m(null,$CLJS.Oh,function(){return null});var u9,qya=$CLJS.Se($CLJS.N),rya=$CLJS.Se($CLJS.N),sya=$CLJS.Se($CLJS.N),tya=$CLJS.Se($CLJS.N),uya=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
u9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","lib-type"),$CLJS.mh,uya,qya,rya,sya,tya);var i9,vya=$CLJS.Se($CLJS.N),wya=$CLJS.Se($CLJS.N),xya=$CLJS.Se($CLJS.N),yya=$CLJS.Se($CLJS.N),zya=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));i9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Qd,zya,vya,wya,xya,yya);i9.m(null,$CLJS.Oh,function(){return null});
var k9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
k9.m(null,$CLJS.Oh,function(a,b){var c=Vxa(a),d=u9.h(a);return function(e){try{var f=$CLJS.T.j(d9(c,e,b),$CLJS.Os,d),k=$CLJS.Cz($CLJS.tx);$CLJS.n($CLJS.Bz("metabase.lib.js.metadata",k))&&$CLJS.Az("metabase.lib.js.metadata",k,$CLJS.mV("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Di.h(f),$CLJS.nV(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.Cz($CLJS.ux),$CLJS.n($CLJS.Bz("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.Az("metabase.lib.js.metadata",k,$CLJS.mV("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.xh.l($CLJS.H([e])),$CLJS.lU(f)])),f):$CLJS.Az("metabase.lib.js.metadata",k,$CLJS.mV(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.xh.l($CLJS.H([e])),$CLJS.lU(f)])),null)),null;throw l;}}});$CLJS.v9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.mh.h(f)},e,a,b,c,d)}();
Aya=$CLJS.Se($CLJS.N);Bya=$CLJS.Se($CLJS.N);Cya=$CLJS.Se($CLJS.N);Dya=$CLJS.Se($CLJS.N);Eya=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));w9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.mh,Eya,Aya,Bya,Cya,Dya);
$CLJS.v9.m(null,$CLJS.Oh,function(a,b){var c=k9.g(a,$CLJS.N);return e9($CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.c9(b,w9.h(a)))});u9.m(null,$CLJS.OO,function(){return $CLJS.ZJ});g9.m(null,$CLJS.OO,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ID,null,$CLJS.r9,null],null),null)});
h9.m(null,$CLJS.OO,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "dbms-version":return $CLJS.Dy(b,$CLJS.H([$CLJS.fi,!0]));case "features":return $CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.mh),b);case "native-permissions":return $CLJS.mh.h(b);default:return b}}});w9.m(null,$CLJS.OO,function(){return"databases"});u9.m(null,$CLJS.GV,function(){return $CLJS.KJ});
g9.m(null,$CLJS.GV,function(){return new $CLJS.Rg(null,new $CLJS.h(null,5,[s9,null,$CLJS.ID,null,$CLJS.p9,null,$CLJS.OO,null,$CLJS.o9,null],null),null)});h9.m(null,$CLJS.GV,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "entity-type":return $CLJS.mh.h(b);case "field-order":return $CLJS.mh.h(b);case "initial-sync-status":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);default:return b}}});
$CLJS.v9.m(null,$CLJS.GV,function(a,b){var c=k9.g(a,$CLJS.N);return e9($CLJS.Xj.g($CLJS.Yj.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.c9(b,"tables"))});u9.m(null,$CLJS.aF,function(){return $CLJS.CJ});
g9.m(null,$CLJS.aF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,6,[s9,null,$CLJS.GV,null,fya,null,$CLJS.OO,null,eya,null,$CLJS.o9,null],null),null)});i9.m(null,$CLJS.aF,function(){return new $CLJS.h(null,6,[$CLJS.Qy,$CLJS.EJ,$CLJS.uk,$CLJS.BV,$CLJS.TQ,$CLJS.gD,$xa,$CLJS.HV,$CLJS.AK,q9,$CLJS.Lr,n9],null)});
h9.m(null,$CLJS.aF,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "base-type":return $CLJS.mh.h(b);case "coercion-strategy":return $CLJS.mh.h(b);case "effective-type":return $CLJS.mh.h(b);case "fingerprint":return $CLJS.td(b)?$CLJS.nU(b):$CLJS.Dy(b,$CLJS.H([$CLJS.fi,!0]));case "has-field-values":return $CLJS.mh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.dK;case "breakout":return $CLJS.VJ;default:return $CLJS.mh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.mh.h(b);
case "semantic-type":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);case "id":return $CLJS.vd(b)?$CLJS.ed(b):b;case "metabase.lib.field/binning":return Wxa(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.c9(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.Lr,$CLJS.Ey($CLJS.c9(b,"values")),$CLJS.JJ,$CLJS.Ey($CLJS.c9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return Xxa(b);default:return b}}});
k9.m(null,$CLJS.aF,function(a,b){var c=function(){var d=$CLJS.cH(k9,$CLJS.Oh);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.J.g(e,q9);var f=$CLJS.O(d),k=$CLJS.J.g(f,$CLJS.Os),l=$CLJS.J.g(e,n9);return $CLJS.Qj.l(function(){switch(k instanceof $CLJS.M?k.S:null){case "metadata.column.remapping/external":return $CLJS.T.j(e,$CLJS.RJ,f);case "metadata.column.remapping/internal":return $CLJS.T.j(e,$CLJS.YJ,$CLJS.pk.l($CLJS.H([f,l])));default:return e}}(),
q9,$CLJS.H([n9]))}});
$CLJS.v9.m(null,$CLJS.aF,function(a,b){var c=k9.g(a,$CLJS.N),d=$CLJS.c9(b,"fields");return e9($CLJS.pl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.lV(f);if($CLJS.n(l))return l;l=$CLJS.dh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.c9(d,l);return m?$CLJS.lV(l):m}return null}();return $CLJS.n(e)?new $CLJS.Q(null,2,5,$CLJS.R,[e,new $CLJS.uh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
u9.m(null,$CLJS.nM,function(){return $CLJS.bK});g9.m(null,$CLJS.nM,function(){return new $CLJS.Rg(null,new $CLJS.h(null,11,[s9,null,$CLJS.Kk,null,bya,null,$CLJS.GV,null,aya,null,Zxa,null,$CLJS.p9,null,$CLJS.OO,null,cya,null,$CLJS.o9,null,dya,null],null),null)});
h9.m(null,$CLJS.nM,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "result-metadata":return $CLJS.n($CLJS.pz($CLJS.sd,$CLJS.Sa)(b))?l9(b):$CLJS.Dy(b,$CLJS.H([$CLJS.fi,!0]));case "fields":return l9(b);case "visibility-type":return $CLJS.mh.h(b);case "dataset-query":return $CLJS.Dy(b,$CLJS.H([$CLJS.fi,!0]));case "dataset":return b;default:return b}}});
$CLJS.v9.m(null,$CLJS.nM,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.uh(function(){return Yxa(b,c)})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Sg,$CLJS.pl($CLJS.yV),$CLJS.Aa($CLJS.c9(b,"tables"))),$CLJS.cf.h($CLJS.lV),$CLJS.Aa($CLJS.c9(b,"questions"))))});u9.m(null,$CLJS.WE,function(){return $CLJS.IJ});g9.m(null,$CLJS.WE,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.GV,null,$CLJS.OO,null],null),null)});
h9.m(null,$CLJS.WE,function(){return function(a,b){return b}});w9.m(null,$CLJS.WE,function(){return"metrics"});u9.m(null,$CLJS.yE,function(){return $CLJS.GJ});g9.m(null,$CLJS.yE,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.GV,null,$CLJS.OO,null],null),null)});h9.m(null,$CLJS.yE,function(){return function(a,b){return b}});w9.m(null,$CLJS.yE,function(){return"segments"});$CLJS.x9=k9.g($CLJS.aF,$CLJS.N);
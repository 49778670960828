var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.binning.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.malli.js");
'use strict';var $sa,ata;
$sa=function(a,b){return function f(d,e){return new $CLJS.je(null,function(){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){for(var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m),u=0;;)if(u<m){var v=function(){var x=d+u,A=$CLJS.hd(l,u);return a.g?a.g(x,A):a.call(null,x,A)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.pe($CLJS.re(t),f(d+m,$CLJS.kc(k)))}m=function(){var x=$CLJS.z(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Ic(k)):$CLJS.be(m,f(d+1,$CLJS.Ic(k)))}return null},null,null)}(0,
b)};$CLJS.k5=function(a,b){return $CLJS.r1($CLJS.zq,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))};$CLJS.l5=function(a,b){return $CLJS.r1($CLJS.xq,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))};$CLJS.m5=function(a,b){return $CLJS.M4.g(a,$CLJS.Dd(b,$CLJS.bD)?$CLJS.bD.h(b):b)};ata=new $CLJS.M(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.A_.m(null,$CLJS.PE,function(a,b){var c=$CLJS.Ce($CLJS.PE.h($CLJS.xV(a,b)));return $CLJS.n(c)?$CLJS.YC("Grouped by {0}",$CLJS.H([$CLJS.as([" ",$CLJS.p.h($CLJS.AD("and"))," "].join(""),function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.WZ.v(a,b,v,$CLJS.XZ);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}t=$CLJS.z(k);return $CLJS.be($CLJS.WZ.v(a,b,t,$CLJS.XZ),f($CLJS.Ic(k)))}return null}},null,null)}(c)}())])):null});$CLJS.n5=function(){function a(d,e,f){f=$CLJS.iV($CLJS.id(f)?f.g?f.g(d,e):f.call(null,d,e):f);return $CLJS.w1(d,e,$CLJS.PE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.o5=function(){function a(d,e){return $CLJS.Ce($CLJS.PE.h($CLJS.xV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.p5=function(){function a(d,e){var f=$CLJS.o5.g(d,e);return null==f?null:$CLJS.Zj.g(function(k){return $CLJS.T.j($CLJS.UZ.j(d,e,k),$CLJS.EJ,$CLJS.VJ)},f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.q5=function(){function a(d,e){var f=function(){var l=$CLJS.xV(d,e);return $CLJS.I_.v(d,e,l,new $CLJS.h(null,1,[$CLJS.u_,!1],null))}();if($CLJS.y(f)){var k=$CLJS.Wf.g($CLJS.N,$sa(function(l,m){var t=$CLJS.F1.M(d,e,m,f,new $CLJS.h(null,1,[$CLJS.C1,!0],null));return $CLJS.n(t)?new $CLJS.Q(null,2,5,$CLJS.R,[t,new $CLJS.Q(null,2,5,$CLJS.R,[l,m],null)],null):null},function(){var l=$CLJS.o5.g(d,e);return $CLJS.n(l)?l:$CLJS.xf}()));return $CLJS.Zj.g(function(l){var m=k.h?k.h(l):k.call(null,l),t=$CLJS.I(m,
0,null),u=$CLJS.I(m,1,null);m=$CLJS.H4(u);u=$CLJS.Z1(u);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.uk);l=$CLJS.n(m)?$CLJS.m5(l,m):l;l=$CLJS.n(u)?$CLJS.R2(l,u):l;return $CLJS.n(t)?$CLJS.T.j(l,$CLJS.f_,t):l},f)}return null}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r5=function(){function a(d,e,f,k){k=$CLJS.O(k);var l=$CLJS.J.g(k,ata);return $CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.ef(function(m){var t=$CLJS.F1.M(d,e,m,new $CLJS.Q(null,1,5,$CLJS.R,[f],null),new $CLJS.h(null,1,[$CLJS.C1,!0],null));return $CLJS.n(t)?$CLJS.n(l)?$CLJS.E.g($CLJS.Z1(m),$CLJS.Z1(f)):!0:t}),$CLJS.o5.g(d,e)))}function b(d,e,f){return c.v?c.v(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.s5=function(){function a(d,e,f){return $CLJS.cb(function(k,l){return $CLJS.d5.j(k,e,l)},d,$CLJS.r5.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
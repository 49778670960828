var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var HC,IC,JC,Nga,KC,MC,NC;
$CLJS.GC=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.M(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Ic(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};HC=function(a,b){this.db=a;this.Ch=b;this.C=393216;this.I=0};IC=function(a){for(var b=null;;){var c;a=$CLJS.wn.g(a,b);a=c=$CLJS.Vm(a)?$CLJS.Pm(a):a;if(!$CLJS.Vm(c))return a}};JC=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.td(a)&&!0===$CLJS.tr.h(a))};
Nga=function(a,b,c){var d=IC($CLJS.wn.g(a,c));return $CLJS.sp($CLJS.Gm(d),$CLJS.Dm(d),function(){var e=$CLJS.Fm(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.Em($CLJS.wn.g(d,null));return $CLJS.n(e)?e:c}())};KC=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.z(e);e=$CLJS.B(e);c=$CLJS.Zj.g(function(f){return $CLJS.wn.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.Zj.g($CLJS.Nr,c),$CLJS.cb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.LC=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,C,G,K,S,Y){this.form=a;this.options=b;this.Ti=c;this.re=d;this.forms=e;this.Ne=f;this.U=k;this.Xd=l;this.Ve=m;this.za=t;this.children=u;this.min=v;this.Eb=x;this.Rb=A;this.parent=C;this.type=G;this.cache=K;this.max=S;this.ji=Y;this.C=393216;this.I=0};MC=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
NC=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ni),d=$CLJS.J.g(b,$CLJS.bn),e=$CLJS.J.g(b,$CLJS.vj),f=$CLJS.J.g(b,$CLJS.os),k=$CLJS.J.g(b,$CLJS.To),l=$CLJS.J.g(b,$CLJS.vi);return new MC(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.yo],null))};$CLJS.X=function(a,b){$CLJS.zh.v(OC,$CLJS.T,a,b);$CLJS.Te($CLJS.PC,$CLJS.N);return null};HC.prototype.P=function(a,b){return new HC(this.db,b)};HC.prototype.O=function(){return this.Ch};HC.prototype.cd=$CLJS.zc;
HC.prototype.Pc=function(a,b){return $CLJS.pm($CLJS.rm($CLJS.q(this.db)),b)};var QC=function QC(a){switch(arguments.length){case 2:return QC.g(arguments[0],arguments[1]);case 3:return QC.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};QC.g=function(a,b){return QC.j(a,b,null)};
QC.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.uj,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.bf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.wp.h(x)],null),$CLJS.xp.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.pk.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?IC($CLJS.wn.g(a,c)):null;b=$CLJS.n(b)?IC($CLJS.wn.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.hn.h(a):null,k=$CLJS.n(b)?$CLJS.hn.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.ks,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.ns,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Ta(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.er,null,$CLJS.uj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.er,null,$CLJS.uj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.uj)&&$CLJS.E.g(k,$CLJS.uj)){l=e($CLJS.Dm(a),$CLJS.Dm(b));var u=$CLJS.Se($CLJS.Sg);a=$CLJS.cb(function(v,x){var A=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(A):C.call(null,A)}()))return $CLJS.cb(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.Zd.g;if($CLJS.E.g(K,A)){K=$CLJS.I(G,0,null);var Y=$CLJS.I(G,1,null),ba=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var ha=$CLJS.I(x,1,null),ra=$CLJS.I(x,2,null);G=JC(G);var Ra=JC(x);G=t.g?t.g(G,Ra):t.call(null,G,Ra);Y=$CLJS.pk.l($CLJS.H([Y,ha]));ra=new $CLJS.Q(null,3,5,$CLJS.R,[K,$CLJS.T.j(Y,$CLJS.tr,$CLJS.Ta(G)),QC.j?QC.j(ba,ra,c):QC.call(null,ba,ra,
c)],null);K=$CLJS.I(ra,0,null);ba=$CLJS.I(ra,1,null);ra=$CLJS.I(ra,2,null);K=$CLJS.Ta(ra)?new $CLJS.Q(null,2,5,$CLJS.R,[K,ba],null):$CLJS.n($CLJS.n(ba)?!1===$CLJS.tr.h(ba)&&$CLJS.E.g(1,$CLJS.D(ba)):ba)?new $CLJS.Q(null,2,5,$CLJS.R,[K,ra],null):$CLJS.Ta($CLJS.y(ba))?new $CLJS.Q(null,2,5,$CLJS.R,[K,ra],null):!1===$CLJS.tr.h(ba)?new $CLJS.Q(null,3,5,$CLJS.R,[K,$CLJS.Qj.g(ba,$CLJS.tr),ra],null):new $CLJS.Q(null,3,5,$CLJS.R,[K,ba,ra],null)}else K=G;return S.call($CLJS.Zd,C,K)},$CLJS.xf,v);$CLJS.zh.j(u,
$CLJS.Zd,A);return $CLJS.Zd.g(v,x)},$CLJS.xf,$CLJS.Wf.g($CLJS.Fm(a),$CLJS.Fm(b)));return $CLJS.sp($CLJS.uj,l,a,c)}return function(v,x){var A=$CLJS.y(v);v=$CLJS.z(A);var C=$CLJS.B(A);A=$CLJS.z(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.z(G);var K=$CLJS.B(G);G=$CLJS.z(K);K=$CLJS.B(K);return $CLJS.sp($CLJS.er,e(v,x),$CLJS.bf.l(new $CLJS.Q(null,1,5,$CLJS.R,[QC.j(A,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};QC.A=3;
var RC=function RC(a){switch(arguments.length){case 2:return RC.g(arguments[0],arguments[1]);case 3:return RC.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};RC.g=function(a,b){return RC.j(a,b,null)};
RC.j=function(a,b,c){return QC.j(a,b,$CLJS.ak.j($CLJS.ak.j(c,$CLJS.ks,$CLJS.GC($CLJS.Qd,function(d,e,f){var k=$CLJS.E.g($CLJS.Nr.g(d,null),$CLJS.Nr.g(e,null));return $CLJS.n(k)?d:$CLJS.wn.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,d,e],null),f)})),$CLJS.ns,$CLJS.GC($CLJS.Qd,function(d,e){return $CLJS.n(d)?e:d})))};RC.A=3;
var SC=function SC(a){switch(arguments.length){case 2:return SC.g(arguments[0],arguments[1]);case 3:return SC.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};SC.g=function(a,b){return SC.j(a,b,null)};SC.j=function(a,b,c){var d=$CLJS.Wg(b);return Nga(a,function(e){return $CLJS.ff(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};SC.A=3;$CLJS.g=$CLJS.LC.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.LC(this.form,this.options,this.Ti,this.re,this.forms,this.Ne,this.U,this.Xd,this.Ve,this.za,this.children,this.min,this.Eb,this.Rb,this.parent,this.type,this.cache,this.max,b)};$CLJS.g.O=function(){return this.ji};$CLJS.g.Ya=$CLJS.zc;$CLJS.g.ya=function(){return $CLJS.Am(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.Xn(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.va=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.Bm(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.zc;$CLJS.g.ia=$CLJS.ua(5);$CLJS.g.je=$CLJS.zc;$CLJS.g.Kd=function(){return this.za};$CLJS.g=MC.prototype;$CLJS.g.P=function(a,b){return new MC(this.Ve,this.re,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.zc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.cn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.yf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.LC(new $CLJS.uh(function(){return $CLJS.Bn(e.type,b,k,d)}),d,a,e.re,k,e.Ne,b,e.Xd,e.Ve,l,f,e.min,e.Eb,e.Rb,this,e.type,$CLJS.An(),e.max,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.Xo],null))};
$CLJS.TC=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.UC=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.VC=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var OC;$CLJS.PC=$CLJS.Se($CLJS.N);
OC=$CLJS.Se($CLJS.pk.l($CLJS.H([$CLJS.rr(),new $CLJS.h(null,3,[$CLJS.ls,NC(new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.ls,$CLJS.vi,KC(QC)],null)),$CLJS.js,NC(new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.js,$CLJS.vi,KC(RC)],null)),$CLJS.ps,NC(new $CLJS.h(null,5,[$CLJS.Ni,$CLJS.ps,$CLJS.os,1,$CLJS.bn,2,$CLJS.vj,2,$CLJS.vi,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ak.j(b,0,function(d){return $CLJS.wn.g(d,c)}),$CLJS.ak.j(b,0,function(d){return $CLJS.Nr.g(d,c)}),$CLJS.P.g(SC,$CLJS.Zd.g(b,c))],null)}],null))],
null)])));$CLJS.Te($CLJS.Pk,$CLJS.rm(new HC(OC,$CLJS.N)));
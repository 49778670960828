var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var uy=function(){},vy=function(a){return $CLJS.J.g($CLJS.kx,a).value},wfa=function(a){var b=vy(a);return $CLJS.n(function(){var c=vy($CLJS.Bx);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.fy:$CLJS.n(function(){var c=vy($CLJS.Ax);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.py:$CLJS.n(function(){var c=vy($CLJS.yx);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.iy:$CLJS.n(function(){var c=vy($CLJS.sx);return $CLJS.Tj.g?
$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.oy:$CLJS.n(function(){var c=vy($CLJS.zx);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.qy:$CLJS.n(function(){var c=vy($CLJS.vx);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.ly:$CLJS.n(function(){var c=vy($CLJS.px);return $CLJS.Tj.g?$CLJS.Tj.g(c,b):$CLJS.Tj.call(null,c,b)}())?$CLJS.my:$CLJS.ny},wy=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},xy=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Zd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.ty,c))].join(""),$CLJS.H(["color:black"]))],null)},yy=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.Zd.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.ty,$CLJS.ry)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.ty,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},xfa=function(a){function b(c,d){return d>=c}a=vy(a);if(b(vy($CLJS.Bx),a))return"error";if(b(vy($CLJS.Ax),a))return"warn";if(b(vy($CLJS.yx),a))return"info";b(vy($CLJS.sx),a);return"log"},zy=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.qx),e=$CLJS.J.g(c,$CLJS.hs);b=$CLJS.J.g(c,$CLJS.Gj);e=xfa(e);e=$CLJS.Ba(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.P.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Ay=function Ay(a,b){for(;;){if($CLJS.E.g($CLJS.ey,b))return xy(a,", ",$CLJS.ny);if($CLJS.E.g($CLJS.gy,b))return wy(a);if(b instanceof $CLJS.M)return xy(a,b,$CLJS.iy);if(b instanceof $CLJS.r)return xy(a,b,$CLJS.oy);if("string"===typeof b)return xy(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.hy);if($CLJS.uf(b)){var d=wy(function(){var f=a,k=$CLJS.Cb(b);return Ay.g?Ay.g(f,k):Ay.call(null,f,k)}()),e=$CLJS.Db(b);
return Ay.g?Ay.g(d,e):Ay.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.yg)return d=a,d=xy(d,"{",$CLJS.sy),d=$CLJS.cb(Ay,d,$CLJS.af($CLJS.ey,b)),xy(d,"}",$CLJS.sy);if($CLJS.td(b))return d=a,d=xy(d,["#",$CLJS.p.h(function(){var f=$CLJS.Za(b),k=f.name;return $CLJS.pd(k)?$CLJS.xh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.jy),d=xy(d,"{",$CLJS.sy),d=$CLJS.cb(Ay,d,$CLJS.af($CLJS.ey,b)),xy(d,"}",$CLJS.sy);if($CLJS.rd(b))return d=a,d=xy(d,"#{",$CLJS.sy),d=$CLJS.cb(Ay,d,$CLJS.af($CLJS.gy,b)),xy(d,
"}",$CLJS.sy);if($CLJS.vd(b))return d=a,d=xy(d,"[",$CLJS.sy),d=$CLJS.cb(Ay,d,$CLJS.af($CLJS.gy,b)),xy(d,"]",$CLJS.sy);if(b instanceof $CLJS.Lf)d=xy(a,"#queue ",$CLJS.jy),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else{if($CLJS.zd(b))return d=a,d=xy(d,"(",$CLJS.jy),d=$CLJS.cb(Ay,d,$CLJS.af($CLJS.gy,b)),xy(d,")",$CLJS.jy);if(null!=b?b.I&16384||$CLJS.zc===b.bj||(b.I?0:$CLJS.Ya(uy,b)):$CLJS.Ya(uy,b))d=xy(a,"#atom ",$CLJS.jy),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Qh(b))d=xy(a,"#uuid ",$CLJS.jy),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Va(b))d=xy(a,"#js ",$CLJS.jy),e=$CLJS.cb(function(f,k){return function(l,m){return $CLJS.T.j(l,$CLJS.mh.h(m),$CLJS.Ba(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Sa(b))d=xy(a,"#js ",$CLJS.jy),e=$CLJS.Wf.g($CLJS.xf,b),a=d,b=e;else return xy(a,$CLJS.xh.l($CLJS.H([b])),$CLJS.ky)}}};$CLJS.yfa=zy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.hs);var b=$CLJS.J.g(a,$CLJS.qx),c=$CLJS.J.g(a,$CLJS.ui);$CLJS.J.g(a,$CLJS.Gj);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.zfa=zy(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.hs);a=$CLJS.J.g(b,$CLJS.qx);var d=$CLJS.J.g(b,$CLJS.ui);$CLJS.J.g(b,$CLJS.Gj);b=wfa(c);d=Ay(wy(yy(yy(yy(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.xf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.be(a,d)});
$CLJS.Afa=zy(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.hs);var b=$CLJS.J.g(a,$CLJS.qx),c=$CLJS.J.g(a,$CLJS.ui);$CLJS.J.g(a,$CLJS.Gj);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.xh.l($CLJS.H([c]))],null)});
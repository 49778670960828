var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var mha,nha,oha,pha,qha,rha,sha;$CLJS.MF=new $CLJS.M(null,"second","second",-444702010);$CLJS.NF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.OF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.PF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.QF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.RF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.SF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.TF=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);
$CLJS.UF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);sha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var VF=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.li,$CLJS.cj,$CLJS.bj,$CLJS.ai,$CLJS.xj,$CLJS.gi,$CLJS.Vh,$CLJS.QF],null),WF=$CLJS.Wg(VF),tha,uha,vha;$CLJS.X(qha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid date extraction unit"],null)],null),WF));var XF=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Fk,$CLJS.Qi,$CLJS.Wi,$CLJS.$h,$CLJS.Vh],null),YF=$CLJS.Wg(XF);
$CLJS.X(pha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid date truncation unit"],null)],null),YF));$CLJS.ZF=$CLJS.Wf.j($CLJS.xf,$CLJS.qk.o(),$CLJS.bf.g(XF,VF));tha=$CLJS.Wg($CLJS.ZF);$CLJS.X($CLJS.SF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid date bucketing unit"],null)],null),tha));var $F=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NF,$CLJS.Li,$CLJS.vk],null),aG=$CLJS.Wg($F);
$CLJS.X(oha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid time extraction unit"],null)],null),aG));var bG=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PF,$CLJS.MF,$CLJS.yk,$CLJS.Ek],null),cG=$CLJS.Wg(bG);$CLJS.X(sha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid time truncation unit"],null)],null),cG));$CLJS.dG=$CLJS.Wf.j($CLJS.xf,$CLJS.qk.o(),$CLJS.bf.g(bG,$F));uha=$CLJS.Wg($CLJS.dG);
$CLJS.X(nha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid time bucketing unit"],null)],null),uha));$CLJS.eG=$CLJS.Wf.j($CLJS.xf,$CLJS.qk.o(),$CLJS.bf.l(bG,XF,$CLJS.H([$F,VF])));vha=$CLJS.Wg($CLJS.eG);$CLJS.X($CLJS.OF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid datetime bucketing unit"],null)],null),$CLJS.eG));var wha=$CLJS.Zd.g(vha,$CLJS.Oh);
$CLJS.X($CLJS.VC,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid temporal bucketing unit"],null)],null),wha));$CLJS.fG=$CLJS.Ps.g(YF,cG);$CLJS.X($CLJS.RF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid datetime truncation unit"],null)],null),$CLJS.fG));$CLJS.gG=$CLJS.Ps.g(WF,aG);$CLJS.X(mha,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid datetime extraction unit"],null)],null),$CLJS.gG));
var hG=$CLJS.Zd.g(YF,$CLJS.Vh);$CLJS.X($CLJS.UF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid date interval unit"],null)],null),hG));$CLJS.X($CLJS.TC,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid time interval unit"],null)],null),cG));var xha=$CLJS.Ps.g(hG,cG);$CLJS.X($CLJS.UC,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid datetime interval unit"],null)],null),xha));
$CLJS.X(rha,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.TF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uk,$CLJS.VC],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null));
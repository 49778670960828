var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var jY,Coa,Doa,Eoa,Foa,qY,Goa,Hoa,rY,Ioa,tY,Joa,Koa,Loa,yY,zY,Moa,Noa,HY,Ooa,Poa,Qoa,MY,Roa;jY=function(){};$CLJS.kY=function(a){return null!=a?$CLJS.zc===a.ni?!0:a.Wc?!1:$CLJS.Ya(jY,a):$CLJS.Ya(jY,a)};Coa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.lY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Doa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Eoa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.mY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.nY=new $CLJS.M(null,"database-id","database-id",1883826326);Foa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.oY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.pY=new $CLJS.M(null,"search","search",1564939822);qY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Goa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Hoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
rY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Ioa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.LJ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.sY=new $CLJS.M(null,"dataset","dataset",1159262238);tY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);
Joa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.uY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.vY=new $CLJS.M(null,"definition","definition",-1198729982);Koa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.wY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Loa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.xY=new $CLJS.M("source","joins","source/joins",1225821486);
yY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);zY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.AY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.QJ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.BY=new $CLJS.M(null,"selected?","selected?",-742502788);
Moa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Noa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.CY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.DY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.EY=new $CLJS.M(null,"active","active",1895962068);$CLJS.FY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.GY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);HY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Ooa=new $CLJS.M(null,"details","details",1956795411);$CLJS.IY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.JY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);
Poa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.KY=new $CLJS.M("source","native","source/native",-1444604147);Qoa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.LY=new $CLJS.M(null,"auto-list","auto-list",314197731);MY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.NY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Roa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.OY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.PY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(yY,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wq,$CLJS.DY,$CLJS.KY,$CLJS.mY,$CLJS.oY,$CLJS.IY,$CLJS.dK,$CLJS.VJ,$CLJS.xY,$CLJS.uY,$CLJS.wY],null));$CLJS.X(rY,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wq],null),$CLJS.sz($CLJS.Ed,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.LY,null,$CLJS.pY,null,$CLJS.$i,null,$CLJS.Vy,null],null),null))));
$CLJS.X(HY,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.cK],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.MJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NJ,$CLJS.aK],null)],null));
$CLJS.X(qY,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.XJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.MJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Lr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,$CLJS.pr],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,$CLJS.pr],null)],null)],null));
$CLJS.X($CLJS.LJ,new $CLJS.Q(null,20,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.CJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.rD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.aK],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Yq,$CLJS.pj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ki,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.rD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.aK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.aK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OY,
new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,yY],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.OJ],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,new $CLJS.h(null,2,[$CLJS.bn,1,$CLJS.vj,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.JY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,rY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,qY],null)],null)],
null)],null));$CLJS.X(MY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CY,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[Joa,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[Ioa,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,$CLJS.rD],null)],null)],null)],null)],null)],null));
$CLJS.X(tY,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EY,$CLJS.or],null),new $CLJS.Q(null,2,5,$CLJS.R,[Koa,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CY,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,MY],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,
$CLJS.hD],null)],null)],null));
$CLJS.X(Poa,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.bK],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.OJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nY,$CLJS.PJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.uj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GY,
new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,$CLJS.uj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.HJ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Eoa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,tY],null)],null)],null)],null));
$CLJS.X(Moa,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.GJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.FJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TJ,$CLJS.HJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.uj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.As,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null)],null));
$CLJS.X(Noa,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.IJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.DJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TJ,$CLJS.HJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.uj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.As,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null)],null));
$CLJS.X(Qoa,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.KJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.HJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kk,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.hD],null)],null)],null));
$CLJS.X(Doa,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.ZJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,$CLJS.PJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hoa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.uj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ooa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.uj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.PY,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.Bi],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wy,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ei,$CLJS.Bi],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Loa,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.uj],null)],null)],null));
$CLJS.X(zY,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Valid MetadataProvider"],null),new $CLJS.Dc(function(){return $CLJS.kY},Foa,$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,$CLJS.bi,$CLJS.wj,$CLJS.zj],[Coa,Roa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Ap],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.kY)?$CLJS.kY.H:null]))],null));
$CLJS.X(Goa,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zY],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zY],null)],null)],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,HF,dha,eha,fha,gha,hha,iha,jha,kha,JF,lha;$CLJS.AF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.EF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.FF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.GF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);HF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.IF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);JF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.KF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.pE($CLJS.Jw,$CLJS.H([$CLJS.Sr,$CLJS.Vi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OD],null)],null)],null)],null)]));$CLJS.pE($CLJS.bF,$CLJS.H([$CLJS.Sr,$CLJS.Vi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OD],null)],null)],null)],null)]));
$CLJS.nE($CLJS.VE,$CLJS.H([$CLJS.Sr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.nE($CLJS.FE,$CLJS.H([$CLJS.Sr,$CLJS.Vi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OD],null)],null)]));$CLJS.nE($CLJS.jF,$CLJS.H([$CLJS.Sr,$CLJS.Vi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)]));
$CLJS.nE($CLJS.vj,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.gE($CLJS.vj,$CLJS.TD);$CLJS.nE($CLJS.nF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.gE($CLJS.nF,$CLJS.TD);$CLJS.nE($CLJS.bn,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)],null)]));$CLJS.gE($CLJS.bn,$CLJS.TD);
$CLJS.X(HF,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ms,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.nE($CLJS.ME,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HF],null)]));$CLJS.gE($CLJS.ME,$CLJS.TD);
$CLJS.nE($CLJS.hF,$CLJS.H([$CLJS.Sr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)]));$CLJS.nE($CLJS.oF,$CLJS.H([$CLJS.Sr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.nE($CLJS.mF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));
$CLJS.nE($CLJS.vF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.gE($CLJS.mF,$CLJS.TD);$CLJS.gE($CLJS.vF,$CLJS.TD);$CLJS.nE($CLJS.tF,$CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)]));$CLJS.gE($CLJS.tF,$CLJS.TD);
$CLJS.nE($CLJS.GE,$CLJS.H([$CLJS.Sr,$CLJS.BC,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.X(JF,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.Zq,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.Wk],null));$CLJS.X($CLJS.EF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JF],null)],null));
$CLJS.LF=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.UE,$CLJS.Jw,$CLJS.FF,!1,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Count of rows"),$CLJS.LE,$CLJS.AD("Count"),$CLJS.As,$CLJS.AD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.mF,$CLJS.AF,$CLJS.sj,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Sum of ..."),$CLJS.LE,$CLJS.AD("Sum"),$CLJS.As,$CLJS.AD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.VE,$CLJS.AF,$CLJS.sj,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Average of ..."),$CLJS.LE,$CLJS.AD("Average"),$CLJS.As,$CLJS.AD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.nF,$CLJS.AF,$CLJS.sj,$CLJS.FF,!0,$CLJS.IF,$CLJS.XE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Median of ..."),$CLJS.LE,$CLJS.AD("Median"),$CLJS.As,$CLJS.AD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.FE,$CLJS.AF,$CLJS.pr,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Number of distinct values of ..."),$CLJS.LE,$CLJS.AD("Distinct values"),$CLJS.As,$CLJS.AD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.vF,$CLJS.AF,$CLJS.sj,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Cumulative sum of ..."),
$CLJS.LE,$CLJS.AD("Sum"),$CLJS.As,$CLJS.AD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.UE,$CLJS.bF,$CLJS.FF,!1,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Cumulative count of rows"),$CLJS.LE,$CLJS.AD("Count"),$CLJS.As,$CLJS.AD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.oF,$CLJS.AF,$CLJS.sj,$CLJS.FF,
!0,$CLJS.IF,$CLJS.kF,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Standard deviation of ..."),$CLJS.LE,$CLJS.AD("SD"),$CLJS.As,$CLJS.AD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.bn,$CLJS.AF,$CLJS.Ci,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Minimum of ..."),$CLJS.LE,$CLJS.AD("Min"),$CLJS.As,$CLJS.AD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.UE,$CLJS.vj,$CLJS.AF,$CLJS.Ci,$CLJS.FF,!0,$CLJS.IF,$CLJS.uE,$CLJS.GF,function(){return new $CLJS.h(null,3,[$CLJS.cD,$CLJS.AD("Maximum of ..."),$CLJS.LE,$CLJS.AD("Max"),$CLJS.As,$CLJS.AD("Maximum value of a column")],null)}],null)],null);
$CLJS.X($ga,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.KF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UE,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wq],null),$CLJS.cf.h($CLJS.UE),$CLJS.LF)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AF,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.Bi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FF,$CLJS.or],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.IF,$CLJS.Bi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GF,$CLJS.id],null)],null));
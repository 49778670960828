var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var kV,Mma,Nma,sV,Oma,Pma,Rma,oV,Sma,Qma;kV=function(a){return a+1};$CLJS.lV=function(a){if("string"===typeof a){var b=$CLJS.dh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Cl(a));};
Mma=function(a){var b=new $CLJS.Da,c=$CLJS.mt;$CLJS.mt=new $CLJS.qc(b);try{var d=$CLJS.mt,e=$CLJS.Ta($CLJS.uu(d)),f=$CLJS.mt;$CLJS.mt=e?$CLJS.lu(d):d;try{d=$CLJS.wu;$CLJS.wu=!0;try{$CLJS.yu(a)}finally{$CLJS.wu=d}$CLJS.E.g(0,$CLJS.tt($CLJS.mt,$CLJS.vt))||$CLJS.Zb($CLJS.mt,"\n");$CLJS.st()}finally{$CLJS.mt=f}$CLJS.kh($CLJS.p.h(b))}finally{$CLJS.mt=c}};$CLJS.mV=function(a,b){return $CLJS.P.j($CLJS.ia,a,b)};
$CLJS.nV=function(a){var b=$CLJS.iu;$CLJS.iu=120;try{var c=new $CLJS.Da,d=$CLJS.Ma,e=$CLJS.Oa;$CLJS.Ma=!0;$CLJS.Oa=function(f){return c.append(f)};try{Mma(a)}finally{$CLJS.Oa=e,$CLJS.Ma=d}return $CLJS.p.h(c)}finally{$CLJS.iu=b}};
Nma=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.wU,$CLJS.Qd),c=$CLJS.J.j(a,$CLJS.tU,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Se($CLJS.N),e=$CLJS.Se($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.zh.v(e,$CLJS.ak,u,$CLJS.GC(kV,0)),u);if($CLJS.E.g(x,1))return $CLJS.zh.v(d,$CLJS.T,
new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==oV&&(oV=$CLJS.Se(0));var v=$CLJS.qh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.zh.g(oV,kV))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.pV=function(a){var b=$CLJS.vd(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.td(a)&&$CLJS.Dd(a,$CLJS.pD)):b:b};$CLJS.qV=function(a){return $CLJS.pV(a)?$CLJS.gD.h($CLJS.wD(a)):null};$CLJS.rV=function(a,b){return $CLJS.yD($CLJS.pV(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,new $CLJS.h(null,2,[$CLJS.pD,$CLJS.p.h($CLJS.zD()),$CLJS.ki,$CLJS.DD(a)],null),a],null),function(c){return $CLJS.Qj.l($CLJS.T.j(c,$CLJS.gD,b),$CLJS.U,$CLJS.H([$CLJS.cD]))})};
sV=function(a,b,c){var d=$CLJS.Dd(a,b)?$CLJS.ak.j(a,b,function(e){return $CLJS.vd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.er)?$CLJS.yf($CLJS.Xe(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Dd(a,b)?$CLJS.rU(d,$CLJS.Be([b,c])):d};Oma=function(a){var b=$CLJS.xl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZN,$CLJS.BP],null));b=tV.h?tV.h(b):tV.call(null,b);return $CLJS.hV($CLJS.T.l(sV($CLJS.Qj.l(a,$CLJS.ZN,$CLJS.H([$CLJS.BP])),$CLJS.JQ,$CLJS.nK),$CLJS.Os,$CLJS.fK,$CLJS.H([$CLJS.kK,b])))};
Pma=function(a){return $CLJS.Zj.g(Oma,a)};Rma=function(a){return $CLJS.n(a)?$CLJS.T.j($CLJS.ak.j($CLJS.el(a)?new $CLJS.h(null,1,[$CLJS.uV,a],null):a,$CLJS.uV,function(b){return $CLJS.Zj.g(function(c){return $CLJS.T.j($CLJS.uz(c,$CLJS.Vz),$CLJS.Os,$CLJS.CJ)},b)}),$CLJS.Os,Qma):null};
$CLJS.vV=function(a){if($CLJS.E.g($CLJS.Os.h(a),$CLJS.$U))return a;var b=$CLJS.Ni.h(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "native":return $CLJS.pk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Os,$CLJS.$U,$CLJS.kK,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.pk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Os,$CLJS.UU],null),$CLJS.rU($CLJS.cP.h(a),new $CLJS.h(null,1,[$CLJS.wE,$CLJS.cP],null))]))],null)],null),$CLJS.Qj.l(a,$CLJS.Ni,$CLJS.H([$CLJS.cP]))]));case "query":return $CLJS.pk.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Os,$CLJS.$U,$CLJS.kK,tV($CLJS.wE.h(a))],null),$CLJS.Qj.l(a,$CLJS.Ni,$CLJS.H([$CLJS.wE]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.wV=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.kK);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.Th($CLJS.YC("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Sma,$CLJS.D(a)],null));return c};
$CLJS.xV=function(a,b){a=$CLJS.vV(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.kK);return $CLJS.J.g($CLJS.yf(c),$CLJS.wV(a,b))};$CLJS.yV=function(a){return"string"===typeof a&&(a=$CLJS.eh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.lV(a)):null};$CLJS.AV=function(){return $CLJS.Xj.g(zV,Nma($CLJS.H([$CLJS.wU,$CLJS.Rz,$CLJS.tU,function(a,b){return zV.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};oV=null;
$CLJS.BV=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.CV=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.DV=new $CLJS.M(null,"join","join",-758861890);$CLJS.EV=new $CLJS.M(null,"stage","stage",1843544772);Sma=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.uV=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.FV=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.GV=new $CLJS.M(null,"table","table",-564943036);Qma=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.HV=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var Tma,Uma,Vma,Wma,Xma,tV,zV;Tma=$CLJS.Se($CLJS.N);Uma=$CLJS.Se($CLJS.N);Vma=$CLJS.Se($CLJS.N);Wma=$CLJS.Se($CLJS.N);Xma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.IV=new $CLJS.Ph($CLJS.qh.g("metabase.lib.util","custom-name-method"),$CLJS.OA,Xma,Tma,Uma,Vma,Wma);$CLJS.IV.m(null,$CLJS.Oh,function(a){return $CLJS.pV(a)?$CLJS.pz($CLJS.cD,$CLJS.gD)($CLJS.wD(a)):null});
tV=function tV(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.BP),d=$CLJS.J.g(a,$CLJS.LN);c=$CLJS.n(c)?tV.h?tV.h(c):tV.call(null,c):$CLJS.xf;d=Rma(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.OK(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.gU],null),d):c;d=$CLJS.n($CLJS.cP.h(a))?$CLJS.UU:$CLJS.NU;a=$CLJS.pk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Os,d],null),$CLJS.Qj.l(a,$CLJS.BP,$CLJS.H([$CLJS.LN]))]));a=$CLJS.y($CLJS.vM.h(a))?$CLJS.ak.j(a,$CLJS.vM,Pma):a;a=sV(a,$CLJS.EE,$CLJS.aV);return $CLJS.Zd.g(c,
a)};
$CLJS.JV=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.vV(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.kK);d=$CLJS.wV(c,d);e=$CLJS.P.M($CLJS.ak,$CLJS.yf(k),d,e,f);return $CLJS.T.j(c,$CLJS.kK,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Ic(c);return b(d,e,f,c)};a.l=b;
return a}();
zV=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.fV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.Yma=RegExp(" id$","i");
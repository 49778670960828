var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var AU,CU,DU,zma,HU,IU,JU,Bma,KU,LU,MU,OU,PU,QU,RU,SU,TU,VU,WU,XU,YU,ZU,bV,cV,Ama;AU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Yj.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.ie(c)}),a)};
$CLJS.BU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.P.g($CLJS.oB,function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.qe(t,$CLJS.yD(x,function(){return function(A){return $CLJS.Qj.l(AU(A),$CLJS.jA,$CLJS.H([$CLJS.ki]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.pe($CLJS.re(t),e($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k);return $CLJS.be($CLJS.yD(u,function(){return function(v){return $CLJS.Qj.l(AU(v),$CLJS.jA,$CLJS.H([$CLJS.ki]))}}(u,k,f,b)),e($CLJS.Ic(k)))}return null}},null,null)}(a)}())};
CU=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(Y,ba,ha,ra,Ra){return function(Ab){var Pa=$CLJS.I(Ab,0,null);Ab=$CLJS.I(Ab,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zd.g(Ra,Pa),Ab],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Zd.g(c,l)):a=$CLJS.td(k)?a(k):$CLJS.sd(k)?a($CLJS.wl($CLJS.sr,k)):m}else return c}};DU=function(a,b,c){return $CLJS.vd(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Dd(b,$CLJS.J.g(c,2))};$CLJS.EU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.Xj.g($CLJS.gD,$CLJS.ed)),$CLJS.TO.h(a));return CU($CLJS.Qj.l(a,$CLJS.vM,$CLJS.H([$CLJS.gU])),function(c){return DU($CLJS.gA,b,c)})};
$CLJS.FU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.Xj.g($CLJS.pD,$CLJS.ed)),$CLJS.QE.h(a));return CU($CLJS.Qj.l(a,$CLJS.vM,$CLJS.H([$CLJS.gU])),function(c){return DU($CLJS.QE,b,c)})};zma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.vM.h(d)]))}function c(d){return $CLJS.be($CLJS.MD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.kK.h(d)])))}return $CLJS.n($CLJS.GU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
HU=function(a){if($CLJS.sd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.pz(m,zma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function(S,Y,ba,ha,ra,Ra){return function ib(Pa,Wa){try{if($CLJS.vd(Wa)&&3===$CLJS.D(Wa))try{var Na=$CLJS.F(Wa,0);if($CLJS.de(Na,$CLJS.aF))try{var nb=$CLJS.F(Wa,1);if($CLJS.n($CLJS.nO.h(nb)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.zc===Ja.mf||(Ja.C?0:$CLJS.Ya($CLJS.ub,
Ja)):$CLJS.Ya($CLJS.ub,Ja))try{var cc=$CLJS.J.j(Ja,$CLJS.nO,$CLJS.vU);if($CLJS.n($CLJS.Oe(ha)(cc))){var Md=$CLJS.J.g(Ja,$CLJS.nO);$CLJS.F(Wa,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.h(Ra),": no join named ",$CLJS.xh.l($CLJS.H([Md]))].join("")],null)}throw $CLJS.Z;}catch(od){if(od instanceof Error){var Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;
}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)throw $CLJS.Z;throw Id;}throw od;}else throw $CLJS.Z;}catch(od){if(od instanceof Error){Id=od;if(Id===$CLJS.Z)return $CLJS.sU(ib,Pa,Wa);throw Id;}throw od;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.Qj.l(C,$CLJS.vM,$CLJS.H([Ama]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};IU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);JU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Bma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);KU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
LU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);MU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.NU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);OU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
PU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);QU=new $CLJS.r(null,"refs","refs",80480079,null);RU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);SU=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);TU=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.UU=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);VU=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);WU=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);XU=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);YU=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
ZU=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.$U=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.GU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.aV=new $CLJS.M(null,"filters","filters",974726919);bV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);cV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.dV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Ama=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(RU,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.UU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cP,$CLJS.Ua],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dr,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,$CLJS.Wk],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zP,new $CLJS.h(null,1,[$CLJS.tr,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ZT],null)],null)],null));$CLJS.X(WU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.eF],null));
$CLJS.X(IU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),WU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Breakouts must be distinct"],null),new $CLJS.Dc(function(){return $CLJS.BU},JU,$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kU,KU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.Q(null,1,5,$CLJS.R,[QU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.BU)?$CLJS.BU.H:null]))],null)],null));
$CLJS.X(XU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.eF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,":fields must be distinct"],null),new $CLJS.Dc(function(){return $CLJS.BU},JU,$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kU,KU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.Q(null,
1,5,$CLJS.R,[QU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.BU)?$CLJS.BU.H:null]))],null)],null));$CLJS.X(YU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.yE],null),$CLJS.uj,$CLJS.pj],null)],null));
$CLJS.X(VU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),YU],null));
var eV=$CLJS.pz(function(a){a=$CLJS.z($CLJS.EU(a));if($CLJS.n(a)){var b=$CLJS.rz(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.FU(a));if($CLJS.n(a)){var b=$CLJS.rz(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(ZU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,2,[$CLJS.ms,"Valid references for a single query stage",$CLJS.xs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.aj);return eV(a)}],null),$CLJS.Oe(eV)],null));
$CLJS.X($CLJS.dV,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.NU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.iK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.KD],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),IU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.tr,!0],null),XU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aV,new $CLJS.h(null,1,[$CLJS.tr,!0],null),VU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.qK],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HJ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GU,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OJ],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Dd(a,$CLJS.BP)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,
new $CLJS.h(null,1,[$CLJS.ms,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.WC($CLJS.ZN,$CLJS.GU))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZU],null)],null));$CLJS.X(LU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HJ],null)],null)],null)],null));
$CLJS.X(MU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OJ],null)],null)],null)],null));$CLJS.X(PU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,LU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,MU],null)],null));
$CLJS.X(SU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.pz($CLJS.ZN,$CLJS.GU))],null)],null));$CLJS.X(TU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,$CLJS.UU,$CLJS.NU],null));
$CLJS.X(Bma,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,TU],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Os],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dV],null)],null)],null)],null));
$CLJS.X(cV,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,TU],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Os],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,PU],null)],null)],null)],null));$CLJS.X(OU,SU);
$CLJS.X(bV,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,2,[$CLJS.ms,"Valid references for all query stages",$CLJS.xs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.aj);return HU.h?HU.h(a):HU.call(null,a)}],null),$CLJS.Oe(HU)],null));
$CLJS.X($CLJS.gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Nq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cV],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Eq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,OU],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bV],null)],null));
$CLJS.X($CLJS.DK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.$U],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.PJ,$CLJS.WJ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.gK],null)],null)],null),$CLJS.rma],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var OV,PV,RV,SV,Zma,UV,VV,NV,WV,$ma,ana,bna,cna,$V,aW,cW,dW,eW,fW,gW,hW,ena,fna,jW,gna,kW,hna,mW,ina,jna,kna,lna,mna,KV;$CLJS.LV=function(a,b){if("string"===typeof b)return KV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.MV=function(a,b,c){var d=$CLJS.yl(a,b);if($CLJS.n(d)){var e=$CLJS.T.j;d=$CLJS.Db(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.T,a,b,c)}return a};
OV=function(a){var b=NV;return $CLJS.yz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};PV=function(a){if($CLJS.id(a))return a;throw $CLJS.Th("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.mi,a],null));};
$CLJS.QV=function(a,b,c){return $CLJS.td(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.R,Y=G;G=$CLJS.Zd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.Q(null,2,5,S,[Y,G],null));A+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.Zd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Ic(k)))}return null}},null,null)}(c)}()):$CLJS.sd(c)?$CLJS.Zj.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Zd.g(b,$CLJS.z(c)):b),c):c};RV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ee(a):b;return $CLJS.n(b)?[$CLJS.ee(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
SV=function(a,b){var c=$CLJS.sd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Zma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.vd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.ek.j(c,0,1);if($CLJS.vd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.ek.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Ua,l)))},$CLJS.H([b]))};
UV=function(a,b){for(;;)switch(b=$CLJS.qk.h($CLJS.ff($CLJS.Ua,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),TV.h?TV.h(a):TV.call(null,a);default:if($CLJS.n($CLJS.Ne($CLJS.Qe(SV,a),b))){var c=a;b=Zma(a,b);a=c}else{c=$CLJS.cf.g(TV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};VV=function(a){var b=$CLJS.sd(a);return b?(b=!$CLJS.uf(a))?$CLJS.pz($CLJS.ce,$CLJS.Xa)($CLJS.z(a)):b:b};
NV=function(a){return $CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a))?$CLJS.mh.h($CLJS.wz(RV(a).toLowerCase(),/_/,"-")):a};WV=function(a,b){var c=VV(b);return $CLJS.n(c)?(b=NV($CLJS.z(b)),$CLJS.qd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};$ma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Qd,$CLJS.Ni,NV,$CLJS.IM,NV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Be([a,b]);b=XV.g?XV.g(b,YV):XV.call(null,b,YV);return d.call(c,b,a)})};
ana=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=NV(c);var d=$ma(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Ni.h(a),$CLJS.tE)&&$CLJS.Ta($CLJS.IM.h(a))?$CLJS.T.j(a,$CLJS.IM,$CLJS.sF):a};bna=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=RV(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.T.j(ana(b),$CLJS.U,c)],null)}),a)};
cna=function(a){a=XV.h?XV.h(a):XV.call(null,a);return ZV.h?ZV.h(a):ZV.call(null,a)};$V=function(a){return $CLJS.MV($CLJS.MV($CLJS.cb(function(b,c){return $CLJS.MV(b,c,$CLJS.mh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.UB,$CLJS.wga,$CLJS.xN,dna,$CLJS.Qy,$CLJS.uk],null)),$CLJS.XA,cna),$CLJS.KM,$CLJS.nU)};aW=function(a){return $CLJS.Bd(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,a,null],null):a};cW=function(a){return bW.h(aW(a))};
dW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return TV($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.cf.g(bW,b)))};eW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,cW(b)],null),$CLJS.cf.g(bW,c))};fW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,cW(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
gW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,cW(a)],null)};hW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,bW.h(a)],null)};ena=function(a){return $CLJS.yf($CLJS.vl(ZV,iW(a)))};fna=function(a){for(;;)if($CLJS.n(VV(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.Ce($CLJS.Zj.g(aW,a))};
jW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tE);return $CLJS.n(b)?$CLJS.ak.j(a,$CLJS.tE,bW):a};
gna=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,jW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,
2,5,$CLJS.R,[l,jW(f)],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())};kW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.zP);return $CLJS.n(b)?$CLJS.ak.j(a,$CLJS.zP,gna):a};hna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.cP);b=$CLJS.Ta(a)?lW.h?lW.h(b):lW.call(null,b):b;return $CLJS.n(a)?kW(b):b};mW=function(a){return $CLJS.qd(a)?$CLJS.y(a):null!=a};
ina=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.wE);var c=$CLJS.J.g(b,$CLJS.mM),d=$CLJS.J.g(b,$CLJS.LN),e=$CLJS.J.g(b,$CLJS.cP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.LN);var l=$CLJS.OK($CLJS.Qj.g(f,$CLJS.LN),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wE,$CLJS.LN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.ak.j(l,$CLJS.wE,lW):l,t=$CLJS.n(c)?$CLJS.ak.j(m,$CLJS.mM,$CLJS.Qe($CLJS.Zj,ZV)):m,u=$CLJS.n(e)?$CLJS.ak.j(t,$CLJS.cP,kW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.cP);return $CLJS.n(x)?$CLJS.T.j(ZV($CLJS.Qj.g(v,
$CLJS.cP)),$CLJS.cP,x):ZV(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Sh($CLJS.YC("Error canonicalizing query: {0}",$CLJS.H([$CLJS.lU(m)])),new $CLJS.h(null,1,[$CLJS.wE,a],null),m);throw A;}};
jna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.wE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.PE);c=$CLJS.J.g(c,$CLJS.ID);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.iB,$CLJS.y($CLJS.ff($CLJS.Ua,function k(e,f){try{if($CLJS.vd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.de(l,$CLJS.aF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,m,$CLJS.Qj.g(t,$CLJS.gF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.sU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.nW.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wE,$CLJS.ID],null),$CLJS.Xj.g($CLJS.yf,$CLJS.Qe($CLJS.Yj,b)))):a};
kna=function(a){try{return jna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Sh($CLJS.AD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.wE,a],null),b);}throw c;}};
lna=function(a,b){var c=$CLJS.Wf.g($CLJS.gd(a),function(){return function f(e){return new $CLJS.je(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Zd.g(b,G);C=oW.g?oW.g(C,K):oW.call(null,C,K);null!=C&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[G,C],null));A+=1}else return!0}()?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Zd.g(b,v);return oW.g?oW.g(A,C):oW.call(null,A,C)}();if(null!=u)return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Ic(k)));k=$CLJS.Ic(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};mna=function(a,b){a=$CLJS.Zj.g(function(c){var d=$CLJS.Zd.g(b,pW);return oW.g?oW.g(c,d):oW.call(null,c,d)},a);return $CLJS.n($CLJS.Ne($CLJS.Ua,a))?a:null};
KV=function KV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.be(f,new $CLJS.je(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),KV.g?KV.g(a,k):KV.call(null,a,k)):null},null,null))};
$CLJS.nW=function nW(a){switch(arguments.length){case 3:return nW.j(arguments[0],arguments[1],arguments[2]);case 4:return nW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return nW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return nW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.nW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.T.j(a,b,$CLJS.nW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.T.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.T,a,b,c)}return a};
$CLJS.nW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.T.j(a,b,$CLJS.nW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.T.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.T,a,b,c)}return a};$CLJS.nW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.T.j(a,b,$CLJS.nW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.T.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.T,a,b,c)}return a};
$CLJS.nW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.T.j(a,b,$CLJS.nW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.T.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.T,a,b,c)}return a};$CLJS.nW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.T.j(a,b,$CLJS.P.l($CLJS.nW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.T.j(a,b,$CLJS.P.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.nW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.nW.A=6;
var TV=function TV(a){for(;;){if($CLJS.td(a))return $CLJS.$r(a,TV);if($CLJS.zd(a))a=$CLJS.yf(a);else if($CLJS.vd(a)){if($CLJS.Ta($CLJS.Ne($CLJS.Ua,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.el(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.ed(m);continue;case "and":return UV($CLJS.Zq,$CLJS.cf.g(function(){return function(C){return new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ar,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Ic(m)));case "or":return UV($CLJS.er,$CLJS.cf.g(function(){return function(C){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ar,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Ic(m)));default:return a}}else return a;case "and":return UV($CLJS.er,t);case "or":return UV($CLJS.Zq,t);default:return $CLJS.Zj.g(TV,a)}}else return a}},nna=new $CLJS.M(null,"value_field","value_field",-980977878),qW=new $CLJS.M(null,"ascending","ascending",-988350486),
rW=new $CLJS.M(null,"named","named",-422393479),sW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),tW=new $CLJS.M(null,"descending","descending",-24766135),uW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),ona=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),vW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),wW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),pna=new $CLJS.M(null,"rows","rows",850049680),qna=
new $CLJS.M(null,"special-fn","special-fn",1290649344),pW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),YV=new $CLJS.M(null,"ignore-path","ignore-path",944069061),rna=new $CLJS.M(null,"label_field","label_field",-1573182765),sna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),tna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),dna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var xW,una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.Se($CLJS.N),xna=$CLJS.Se($CLJS.N),yna=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));xW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Xj.g(NV,$CLJS.z),yna,una,vna,wna,xna);xW.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,b],null);return null!=a?$CLJS.Zd.g(b,a):b});
xW.m(null,$CLJS.gA,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gA,a instanceof $CLJS.M?RV(a):a],null)});xW.m(null,$CLJS.uU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Zd.g(xW.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uU,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uU,XV.g?XV.g(b,YV):XV.call(null,b,YV),NV(c)],null)});
xW.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=XV.g?XV.g(a,YV):XV.call(null,a,YV);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,b,function(){var d=$CLJS.n($CLJS.jA.h(c))?$CLJS.ak.j(c,$CLJS.jA,$CLJS.mh):c;d=$CLJS.n($CLJS.gF.h(c))?$CLJS.ak.j(d,$CLJS.gF,$CLJS.mh):d;return $CLJS.n($CLJS.nQ.h(c))?$CLJS.ak.j(d,$CLJS.nQ,function(e){return $CLJS.n($CLJS.tD.h(e))?$CLJS.ak.j(e,$CLJS.tD,$CLJS.mh):e}):d}()],null)});
xW.m(null,vW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[vW,b instanceof $CLJS.M?RV(b):b,$CLJS.mh.h(a)],null)});xW.m(null,uW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[uW,XV.g?XV.g(b,YV):XV.call(null,b,YV),$CLJS.Qr,NV(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[uW,XV.g?XV.g(b,YV):XV.call(null,b,YV),NV(c)],null)});
xW.m(null,$CLJS.VA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Zd.g(xW.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.VA,b,c,d],null)),XV.g?XV.g(a,YV):XV.call(null,a,YV)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.VA,XV.g?XV.g(b,YV):XV.call(null,b,YV),$CLJS.Bd(c)?c:NV(c),NV(d)],null)});
xW.m(null,$CLJS.RA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RA,b,NV(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RA,$CLJS.Iu],null)});xW.m(null,$CLJS.QA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QA,b,NV(a)],null)});
xW.m(null,$CLJS.TA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.TA,XV.g?XV.g(b,YV):XV.call(null,b,YV),c,NV(a)],null)});xW.m(null,$CLJS.WA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.WA,XV.g?XV.g(b,YV):XV.call(null,b,YV),c,NV(a)],null)});
xW.m(null,$CLJS.SA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SA,XV.g?XV.g(b,YV):XV.call(null,b,YV),NV(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SA,XV.g?XV.g(b,YV):XV.call(null,b,YV)],null)});
xW.m(null,$CLJS.UA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.UA,XV.g?XV.g(b,YV):XV.call(null,b,YV),NV(c),NV(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UA,XV.g?XV.g(b,YV):XV.call(null,b,YV),NV(c)],null)});
xW.m(null,$CLJS.PA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PA,XV.g?XV.g(b,YV):XV.call(null,b,YV),XV.g?XV.g(c,YV):XV.call(null,c,YV),NV(a)],null)});xW.m(null,$CLJS.aj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,b,a],null)});
xW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[NV(a)],null),$CLJS.cf.h(function(c){return XV.g?XV.g(c,YV):XV.call(null,c,YV)}),b)});
var yW=function yW(a){if($CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a))){var c=NV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.bn,null,$CLJS.Eq,null,$CLJS.oF,null,$CLJS.jF,null,$CLJS.Sr,null,$CLJS.bF,null,$CLJS.tF,null,$CLJS.uF,null,$CLJS.FE,null,$CLJS.ME,null,$CLJS.Dq,null,$CLJS.GE,null,$CLJS.nF,null,$CLJS.hF,null,$CLJS.vj,null,$CLJS.Jw,null,$CLJS.VE,null,$CLJS.mF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(VV(a))?(a=$CLJS.z(a),yW.h?yW.h(a):yW.call(null,
a)):null},zna=new $CLJS.h(null,8,[$CLJS.Ni,NV,$CLJS.cP,function(a){a=OV(a);return $CLJS.y($CLJS.zP.h(a))?$CLJS.ak.j(a,$CLJS.zP,bna):a},$CLJS.wE,new $CLJS.h(null,6,[$CLJS.QE,function zW(a){if($CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(a)))return NV(a);if($CLJS.n(WV(rW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[rW,zW.h?zW.h(a):zW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(VV(a))?yW($CLJS.ed(a)):null)?$CLJS.Zj.g(zW,a):XV.g?XV.g(a,
YV):XV.call(null,a,YV)},$CLJS.TO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[RV(u),XV.g?XV.g(t,YV):XV.call(null,t,YV)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[RV(l),XV.g?XV.g(f,YV):XV.call(null,f,YV)],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())},$CLJS.HQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(VV(t))?xW.h(t):$CLJS.$d(xW.h($CLJS.$d(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}l=$CLJS.z(e);return $CLJS.be($CLJS.n(VV(l))?xW.h(l):$CLJS.$d(xW.h($CLJS.$d(l))),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())},$CLJS.BP,function(a){a=OV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.cP);if($CLJS.n(b))return a=$CLJS.rU(a,new $CLJS.h(null,1,[$CLJS.cP,$CLJS.wE],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.cP],null),a=XV.g?XV.g(a,b):XV.call(null,a,b),$CLJS.rU(a,new $CLJS.h(null,1,[$CLJS.wE,$CLJS.cP],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.wE],null);return XV.g?XV.g(a,b):XV.call(null,a,b)},$CLJS.LN,new $CLJS.h(null,1,[pW,$V],null),$CLJS.vM,new $CLJS.h(null,1,[pW,function(a){a=XV.g?XV.g(a,$CLJS.wE):XV.call(null,a,$CLJS.wE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.tD),d=$CLJS.J.g(b,$CLJS.ID);a=$CLJS.J.g(b,$CLJS.MD);b=$CLJS.n(c)?$CLJS.ak.j(b,$CLJS.tD,NV):b;d=$CLJS.n($CLJS.pz($CLJS.ce,$CLJS.Xa)(d))?$CLJS.ak.j(b,$CLJS.ID,NV):b;return $CLJS.n(a)?$CLJS.ak.j(d,$CLJS.MD,RV):d}],null)],null),$CLJS.yx,
new $CLJS.h(null,1,[$CLJS.gN,$CLJS.Qd],null),$CLJS.mM,new $CLJS.h(null,1,[pW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ni),d=$CLJS.J.g(b,$CLJS.fI),e=$CLJS.J.g(b,$CLJS.Di);a=$CLJS.J.g(b,sW);b=$CLJS.n(e)?$CLJS.ak.j(b,$CLJS.Di,RV):b;c=$CLJS.n(c)?$CLJS.ak.j(b,$CLJS.Ni,NV):b;d=$CLJS.n(d)?$CLJS.ak.j(c,$CLJS.fI,function(f){return XV.g?XV.g(f,YV):XV.call(null,f,YV)}):c;d=$CLJS.n(a)?$CLJS.nW.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[sW,rna],null),function(f){return XV.g?XV.g(f,YV):XV.call(null,f,YV)}):d;return $CLJS.n(a)?
$CLJS.nW.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[sW,nna],null),function(f){return XV.g?XV.g(f,YV):XV.call(null,f,YV)}):d}],null),$CLJS.hM,function(a){return null==a?null:NV(a)},$CLJS.LN,new $CLJS.h(null,1,[pW,$V],null),wW,NV],null),XV=function XV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return XV.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
XV.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.rz(zna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.je(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=NV(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,XV.l(x,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=NV(u);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[u,XV.l(m,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),u)]))],null),k($CLJS.Ic(l)))}return null}},null,null)}(a)}()):$CLJS.n(VV(a))?xW.h(a):$CLJS.sd(a)?$CLJS.Zj.g(function(e){return XV.l(e,$CLJS.H([$CLJS.Zd.g($CLJS.yf(c),pW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Sh($CLJS.YC("Error normalizing form: {0}",$CLJS.H([$CLJS.lU(d)])),new $CLJS.h(null,3,[$CLJS.yi,a,$CLJS.Jk,c,qna,b],null),d);}throw e;}};XV.A=1;XV.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var bW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(VV(f))?$CLJS.z(f):null},e,a,b,c,d)}();bW.m(null,$CLJS.Oh,function(a){return a});
bW.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(WV($CLJS.aF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return bW.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,c,$CLJS.Ce($CLJS.pk.l($CLJS.H([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,b,$CLJS.Ce(a)],null)});
bW.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.pd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QE,b,a],null)});bW.m(null,$CLJS.NJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(VV(a))?bW.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,a,null],null)});
bW.m(null,vW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,b,new $CLJS.h(null,1,[$CLJS.jA,a],null)],null)});bW.m(null,sna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=cW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=cW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,a,$CLJS.T.j(c,$CLJS.wM,b)],null)});
bW.m(null,tna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=cW(a);return $CLJS.P.v($CLJS.zU,a,$CLJS.T,$CLJS.H([$CLJS.nO,b]))});
bW.m(null,uW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=cW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.jA);$CLJS.Ta(d)||$CLJS.DR.g(d,b)?a=$CLJS.P.v($CLJS.zU,a,$CLJS.T,$CLJS.H([$CLJS.gF,b])):(c=$CLJS.Cz($CLJS.wx),$CLJS.n($CLJS.Bz("metabase.mbql.util",c))&&(b=$CLJS.YC("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),b instanceof
Error?$CLJS.Az("metabase.mbql.util",c,$CLJS.pv(),b):$CLJS.Az("metabase.mbql.util",c,$CLJS.pv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),bW.h(new $CLJS.Q(null,3,5,$CLJS.R,[uW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
bW.m(null,$CLJS.uU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=cW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,b,$CLJS.T.j(e,$CLJS.nQ,$CLJS.pk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.tD,c],null),$CLJS.n(d)?$CLJS.Be([c,d]):null,a])))],null)});
for(var AW=$CLJS.y(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.Zq,$CLJS.ar],null)),BW=null,CW=0,DW=0;;)if(DW<CW){var EW=BW.X(null,DW);bW.m(null,EW,function(){return function(a){return dW(a)}}(AW,BW,CW,DW,EW));DW+=1}else{var FW=$CLJS.y(AW);if(FW){var GW=FW;if($CLJS.wd(GW)){var HW=$CLJS.jc(GW),Ana=$CLJS.kc(GW),Bna=HW,Cna=$CLJS.D(HW);AW=Ana;BW=Bna;CW=Cna}else{var IW=$CLJS.z(GW);bW.m(null,IW,function(){return function(a){return dW(a)}}(AW,BW,CW,DW,IW,GW,FW));AW=$CLJS.B(GW);BW=null;CW=0}DW=0}else break}
bW.m(null,$CLJS.IE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IE,cW(a),cW(b)],null),c)});bW.m(null,$CLJS.VA,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=cW(a);a=$CLJS.n(SV($CLJS.aF,a))?$CLJS.zU.l(c,$CLJS.Qj,$CLJS.H([$CLJS.gF])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VA,a],null),b)});
for(var JW=$CLJS.y(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.SE,$CLJS.lF,$CLJS.$E,$CLJS.TE,$CLJS.Cj,$CLJS.qF,$CLJS.zq,$CLJS.Bq,$CLJS.vq,$CLJS.xq,$CLJS.DE,$CLJS.KE,$CLJS.BE,$CLJS.NE,$CLJS.CE],null)),KW=null,LW=0,MW=0;;)if(MW<LW){var NW=KW.X(null,MW);bW.m(null,NW,function(){return function(a){return eW(a)}}(JW,KW,LW,MW,NW));MW+=1}else{var OW=$CLJS.y(JW);if(OW){var PW=OW;if($CLJS.wd(PW)){var QW=$CLJS.jc(PW),Dna=$CLJS.kc(PW),Ena=QW,Fna=$CLJS.D(QW);JW=Dna;KW=Ena;LW=Fna}else{var RW=$CLJS.z(PW);bW.m(null,RW,
function(){return function(a){return eW(a)}}(JW,KW,LW,MW,RW,PW,OW));JW=$CLJS.B(PW);KW=null;LW=0}MW=0}else break}bW.m(null,pna,function(){return null});bW.m(null,$CLJS.mL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mL,bW.h(b),a],null)});
bW.m(null,rW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=bW.h;var e=$CLJS.R;b=bW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,ona)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.cD,a],null);return c.call(bW,new $CLJS.Q(null,3,5,e,[$CLJS.mL,b,a],null))});
for(var SW=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jw,$CLJS.bF],null)),TW=null,UW=0,VW=0;;)if(VW<UW){var WW=TW.X(null,VW);bW.m(null,WW,function(){return function(a){return fW(a)}}(SW,TW,UW,VW,WW));VW+=1}else{var XW=$CLJS.y(SW);if(XW){var YW=XW;if($CLJS.wd(YW)){var ZW=$CLJS.jc(YW),Gna=$CLJS.kc(YW),Hna=ZW,Ina=$CLJS.D(ZW);SW=Gna;TW=Hna;UW=Ina}else{var $W=$CLJS.z(YW);bW.m(null,$W,function(){return function(a){return fW(a)}}(SW,TW,UW,VW,$W,YW,XW));SW=$CLJS.B(YW);TW=null;UW=0}VW=0}else break}
for(var aX=$CLJS.y(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.VE,$CLJS.vF,$CLJS.FE,$CLJS.oF,$CLJS.mF,$CLJS.bn,$CLJS.vj,$CLJS.nF,$CLJS.GE],null)),bX=null,cX=0,dX=0;;)if(dX<cX){var eX=bX.X(null,dX);bW.m(null,eX,function(){return function(a){return gW(a)}}(aX,bX,cX,dX,eX));dX+=1}else{var fX=$CLJS.y(aX);if(fX){var gX=fX;if($CLJS.wd(gX)){var hX=$CLJS.jc(gX),Jna=$CLJS.kc(gX),Kna=hX,Lna=$CLJS.D(hX);aX=Jna;bX=Kna;cX=Lna}else{var iX=$CLJS.z(gX);bW.m(null,iX,function(){return function(a){return gW(a)}}(aX,bX,cX,dX,
iX,gX,fX));aX=$CLJS.B(gX);bX=null;cX=0}dX=0}else break}bW.m(null,$CLJS.ME,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ME,cW(b),a],null)});
for(var jX=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hF,$CLJS.jF],null)),kX=null,lX=0,mX=0;;)if(mX<lX){var nX=kX.X(null,mX);bW.m(null,nX,function(){return function(a){return hW(a)}}(jX,kX,lX,mX,nX));mX+=1}else{var oX=$CLJS.y(jX);if(oX){var pX=oX;if($CLJS.wd(pX)){var qX=$CLJS.jc(pX),Mna=$CLJS.kc(pX),Nna=qX,Ona=$CLJS.D(qX);jX=Mna;kX=Nna;lX=Ona}else{var rX=$CLJS.z(pX);bW.m(null,rX,function(){return function(a){return hW(a)}}(jX,kX,lX,mX,rX,pX,oX));jX=$CLJS.B(pX);kX=null;lX=0}mX=0}else break}
bW.m(null,$CLJS.tF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tF,bW.h(b),bW.h(a)],null)});
bW.m(null,$CLJS.JE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Zd.g(bW.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JE,b],null)),XV.l(a,$CLJS.H([YV]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JE,$CLJS.yf(function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[bW.h(v),bW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[bW.h(m),bW.h(k)],null),e($CLJS.Ic(f)))}return null}},null,null)}(b)}())],null)});
bW.m(null,$CLJS.sE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sE,bW.h(a),$CLJS.E.g(0,b)?1:bW.h(b)],null),$CLJS.cf.g(bW,c))});
var ZV=function ZV(a){if($CLJS.ud(a))return $CLJS.Pd(function(d,e,f){return $CLJS.T.j(d,e,ZV.h?ZV.h(f):ZV.call(null,f))},a,a);if($CLJS.td(a))return $CLJS.$r(a,ZV);if($CLJS.n(VV(a))){var c=function(){try{return bW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Cz($CLJS.ux);if($CLJS.n($CLJS.Bz("metabase.mbql.normalize",e))){var f=$CLJS.AD("Invalid clause:");f instanceof Error?$CLJS.Az("metabase.mbql.normalize",e,$CLJS.pv.l($CLJS.H([a])),f):$CLJS.Az("metabase.mbql.normalize",e,$CLJS.pv.l($CLJS.H([f,
a])),null)}throw $CLJS.Sh($CLJS.YC("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.lU(d)])),new $CLJS.h(null,1,[$CLJS.SQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Zd.g($CLJS.gd(c),$CLJS.z(c)),$CLJS.cf.h(ZV),$CLJS.Ic(c)):c}return $CLJS.zd(a)?$CLJS.Zj.g(ZV,a):$CLJS.qd(a)?$CLJS.Wf.j($CLJS.gd(a),$CLJS.cf.h(ZV),a):a},iW=function iW(a){return function f(d,e){try{if($CLJS.n(function(){var x=PV($CLJS.zd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=PV($CLJS.ce);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.vd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.ek.j(e,0,2);if($CLJS.vd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.WC($CLJS.ce,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Eq,null,$CLJS.Sr,null,$CLJS.uF,null,$CLJS.Dq,null,
rW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(yW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.XC(iW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.vd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.ek.j(e,0,1);if($CLJS.vd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},sX=function sX(a){return function f(d,e){try{var k=PV($CLJS.zd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.vd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.de(m,$CLJS.YE)){var t=$CLJS.F(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YE,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,
$CLJS.RE))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RE,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,qW))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.de(m,tW))return t=$CLJS.F(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RE,t],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(m=Y,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.de(v,qW))return t=$CLJS.F(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(ba){if(ba instanceof Error)if(m=ba,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,tW))return t=$CLJS.F(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RE,t],null));throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,$CLJS.YE))return t=$CLJS.F(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YE,cW(t)],null);throw $CLJS.Z;
}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.de(v,$CLJS.RE))return t=$CLJS.F(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RE,cW(t)],null);throw $CLJS.Z;}catch(Ra){if(Ra instanceof Error){t=Ra;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ra;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw ba;}else throw m;else throw Y;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof
Error)if(l=G,l===$CLJS.Z)try{if($CLJS.vd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.ek.j(e,0,0);if($CLJS.vd(x)&&0===$CLJS.D(x))try{var A=$CLJS.ek.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.qk.h($CLJS.cf.g(sX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.QV(f,d,e);
throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.xf,a)},lW=$CLJS.Xj.g(ZV,function(a){var b=mW($CLJS.QE.h(a))?$CLJS.ak.j(a,$CLJS.QE,ena):a;b=mW($CLJS.PE.h(a))?$CLJS.ak.j(b,$CLJS.PE,fna):b;b=mW($CLJS.ID.h(a))?$CLJS.ak.j(b,$CLJS.ID,$CLJS.Qe($CLJS.Zj,aW)):b;b=mW($CLJS.HQ.h(a))?$CLJS.ak.j(b,$CLJS.HQ,sX):b;return mW($CLJS.BP.h(a))?$CLJS.ak.j(b,$CLJS.BP,hna):b}),Pna=new $CLJS.h(null,3,[$CLJS.cP,$CLJS.Qd,$CLJS.wE,new $CLJS.h(null,2,[$CLJS.BP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,
$CLJS.cP);if($CLJS.n(b))return a=$CLJS.rU(a,new $CLJS.h(null,1,[$CLJS.cP,$CLJS.wE],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.cP],null),a=oW.g?oW.g(a,b):oW.call(null,a,b),$CLJS.rU(a,new $CLJS.h(null,1,[$CLJS.wE,$CLJS.cP],null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.wE],null);return oW.g?oW.g(a,b):oW.call(null,a,b)},$CLJS.vM,new $CLJS.h(null,1,[pW,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.wE],null);return oW.g?oW.g(a,b):oW.call(null,a,b)}],null)],null),wW,$CLJS.Qd],null),oW=function oW(a){switch(arguments.length){case 1:return oW.h(arguments[0]);
case 2:return oW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};oW.h=function(a){return oW.g(a,$CLJS.xf)};oW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.rz(Pna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?lna(a,b):$CLJS.sd(a)?mna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Sh("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.yi,a,$CLJS.Jk,b],null),d);throw d;}};oW.A=2;
$CLJS.tX=function(){var a=$CLJS.Xj.l(oW,kna,ina,$CLJS.H([XV]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Sh($CLJS.YC("Error normalizing query: {0}",$CLJS.H([$CLJS.lU(c)])),new $CLJS.h(null,1,[$CLJS.wE,b],null),c);}throw d;}}}();$CLJS.uX=function uX(a,b){if($CLJS.Ta($CLJS.y(a)))a=(0,$CLJS.tX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Yr(a);b=$CLJS.Be([$CLJS.fd(a),b]);f=uX.g?uX.g(f,b):uX.call(null,f,b);a=e.call(d,f,$CLJS.fd(a))}return a};
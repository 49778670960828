var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var FI;$CLJS.DI=function(a){return $CLJS.n($CLJS.hB($CLJS.sd,$CLJS.rd,$CLJS.Tk)(a))?a:new $CLJS.Q(null,1,5,$CLJS.R,[a],null)};
$CLJS.EI=function(a,b){return $CLJS.Ne($CLJS.Ad,function(){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.je(null,function(K,S,Y,ba){return function(){for(var ha=C;;){var ra=$CLJS.y(ha);if(ra){var Ra=ra,Ab=$CLJS.z(Ra);if(ra=$CLJS.y(function(Pa,Wa,ib,Na,nb,Ja,cc,Md){return function Pi(od){return new $CLJS.je(null,function(jN,Ln,nr,Av,xza,BF){return function(){for(;;){var Bv=
$CLJS.y(od);if(Bv){if($CLJS.wd(Bv)){var CF=$CLJS.jc(Bv),o_=$CLJS.D(CF),Cv=$CLJS.me(o_);a:for(var kN=0;;)if(kN<o_){var DF=$CLJS.hd(CF,kN);DF=$CLJS.E.g(BF,$CLJS.PD)||$CLJS.E.g(nr,$CLJS.PD)||$CLJS.qE(BF,DF)&&$CLJS.qE(nr,DF);Cv.add(DF);kN+=1}else{CF=!0;break a}return CF?$CLJS.pe($CLJS.re(Cv),Pi($CLJS.kc(Bv))):$CLJS.pe($CLJS.re(Cv),null)}Cv=$CLJS.z(Bv);return $CLJS.be($CLJS.E.g(BF,$CLJS.PD)||$CLJS.E.g(nr,$CLJS.PD)||$CLJS.qE(BF,Cv)&&$CLJS.qE(nr,Cv),Pi($CLJS.Ic(Bv)))}return null}}}(Pa,Wa,ib,Na,nb,Ja,cc,
Md),null,null)}}(ha,K,Ab,Ra,ra,S,Y,ba)($CLJS.aE)))return $CLJS.bf.g(ra,G($CLJS.Ic(ha)));ha=$CLJS.Ic(ha)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.DI($CLJS.DD(b)))))return $CLJS.bf.g(k,e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null}},null,null)}($CLJS.DI($CLJS.DD(a)))}())};
FI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.P.j($CLJS.mE,d,e),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Me($CLJS.dl,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.EI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Ic(d);return c(e,d)};b.l=c;return b}()};$CLJS.HI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.II=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var JI=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.er,$CLJS.Zq],null)),KI=null,LI=0,MI=0;;)if(MI<LI){var Lia=KI.X(null,MI);$CLJS.pE(Lia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)],null)],null)]));MI+=1}else{var NI=$CLJS.y(JI);if(NI){var OI=NI;if($CLJS.wd(OI)){var PI=$CLJS.jc(OI),Mia=$CLJS.kc(OI),
Nia=PI,Oia=$CLJS.D(PI);JI=Mia;KI=Nia;LI=Oia}else{var Pia=$CLJS.z(OI);$CLJS.pE(Pia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)],null)],null)],null)]));JI=$CLJS.B(OI);KI=null;LI=0}MI=0}else break}$CLJS.nE($CLJS.ar,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ND],null)]));
for(var QI=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.qF],null)),RI=null,SI=0,TI=0;;)if(TI<SI){var Qia=RI.X(null,TI);$CLJS.pE(Qia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.SD],null)],null)],null)],null)]));TI+=1}else{var UI=$CLJS.y(QI);if(UI){var VI=UI;if($CLJS.wd(VI)){var WI=$CLJS.jc(VI),Ria=$CLJS.kc(VI),
Sia=WI,Tia=$CLJS.D(WI);QI=Ria;RI=Sia;SI=Tia}else{var Uia=$CLJS.z(VI);$CLJS.pE(Uia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lq,new $CLJS.h(null,1,[$CLJS.bn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.SD],null)],null)],null)],null)]));QI=$CLJS.B(VI);RI=null;SI=0}TI=0}else break}
for(var XI=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.zq,$CLJS.Bq,$CLJS.vq,$CLJS.xq],null)),YI=null,ZI=0,$I=0;;)if($I<ZI){var Via=YI.X(null,$I);$CLJS.lE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),Via,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)]));$I+=1}else{var aJ=$CLJS.y(XI);if(aJ){var bJ=aJ;if($CLJS.wd(bJ)){var cJ=$CLJS.jc(bJ),Wia=$CLJS.kc(bJ),Xia=cJ,Yia=$CLJS.D(cJ);XI=Wia;YI=Xia;ZI=Yia}else{var Zia=
$CLJS.z(bJ);$CLJS.lE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),Zia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)]));XI=$CLJS.B(bJ);YI=null;ZI=0}$I=0}else break}
$CLJS.lE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[0,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,1],null)])),$CLJS.CE,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)]));
$CLJS.lE.l(FI($CLJS.Tg([new $CLJS.Q(null,2,5,$CLJS.R,[1,3],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,5],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[0,4],null)])),$CLJS.IE,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.UD],null)]));
for(var dJ=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BE,$CLJS.NE],null)),eJ=null,fJ=0,gJ=0;;)if(gJ<fJ){var $ia=eJ.X(null,gJ);$CLJS.nE($ia,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OD],null)]));gJ+=1}else{var hJ=$CLJS.y(dJ);if(hJ){var iJ=hJ;if($CLJS.wd(iJ)){var jJ=$CLJS.jc(iJ),aja=$CLJS.kc(iJ),bja=jJ,cja=$CLJS.D(jJ);dJ=aja;eJ=bja;fJ=cja}else{var dja=$CLJS.z(iJ);$CLJS.nE(dja,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OD],null)]));dJ=$CLJS.B(iJ);
eJ=null;fJ=0}gJ=0}else break}
for(var kJ=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DE,$CLJS.KE],null)),lJ=null,mJ=0,nJ=0;;)if(nJ<mJ){var eja=lJ.X(null,nJ);$CLJS.nE(eja,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null)]));nJ+=1}else{var oJ=$CLJS.y(kJ);if(oJ){var pJ=oJ;if($CLJS.wd(pJ)){var qJ=$CLJS.jc(pJ),fja=$CLJS.kc(pJ),gja=qJ,hja=$CLJS.D(qJ);kJ=fja;lJ=gja;mJ=hja}else{var ija=$CLJS.z(pJ);$CLJS.nE(ija,$CLJS.H([$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WD],null)]));kJ=$CLJS.B(pJ);
lJ=null;mJ=0}nJ=0}else break}
for(var rJ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iF,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null),sJ=$CLJS.y(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.SE,$CLJS.lF,$CLJS.$E,$CLJS.TE],null)),tJ=null,uJ=0,vJ=0;;)if(vJ<uJ){var wJ=tJ.X(null,vJ);$CLJS.zF.v(wJ,$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,wJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,$CLJS.nD,rJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,
$CLJS.XD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.XD],null)],null));vJ+=1}else{var xJ=$CLJS.y(sJ);if(xJ){var yJ=xJ;if($CLJS.wd(yJ)){var zJ=$CLJS.jc(yJ),jja=$CLJS.kc(yJ),kja=zJ,lja=$CLJS.D(zJ);sJ=jja;tJ=kja;uJ=lja}else{var AJ=$CLJS.z(yJ);$CLJS.zF.v(AJ,$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,AJ],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,$CLJS.nD,rJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.XD],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.W,$CLJS.XD],null)],null));sJ=$CLJS.B(yJ);tJ=null;uJ=0}vJ=0}else break}
$CLJS.zF.v($CLJS.VA,$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.VA],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,$CLJS.nD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.LD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wq,$CLJS.Iu,$CLJS.Nw,$CLJS.HE],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.$D],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.UC],null)],null));$CLJS.zF.v($CLJS.yE,$CLJS.Sr,$CLJS.gj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.yE],null),$CLJS.nD,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.$C,$CLJS.hD],null)],null));
$CLJS.X($CLJS.GI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.HI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UE,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.Wq,$CLJS.Cj,$CLJS.qF,$CLJS.IE,$CLJS.CE,$CLJS.zq,$CLJS.vq,$CLJS.Bq,$CLJS.xq,$CLJS.BE,$CLJS.NE,$CLJS.DE,$CLJS.KE,$CLJS.$E,$CLJS.TE,$CLJS.SE,$CLJS.lF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.II,$CLJS.Bi],null)],null));
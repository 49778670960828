var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var WT,XT,YT,$T,aU,bU,cU,dU,eU,fU;WT=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);XT=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);YT=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.ZT=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);$T=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);aU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);bU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
cU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);dU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);eU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);fU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(WT,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"Valid template tag :widget-type"],null),$CLJS.Vy],null),$CLJS.Ng($CLJS.RT)));$CLJS.X($T,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wq,$CLJS.yL,$CLJS.nM,$CLJS.tE,$CLJS.Is,$CLJS.nA,$CLJS.vO],null));
$CLJS.X(cU,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,$CLJS.hD],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cD,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.hD,$CLJS.mr],null)],null)],null));
$CLJS.X(dU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.Wk],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null)],null));
$CLJS.X(aU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dU],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.tE],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.QD],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ji,new $CLJS.h(null,1,[$CLJS.tr,!0],
null),$CLJS.uj],null)],null)],null));
$CLJS.X(bU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cU],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.yL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kL,$CLJS.hD],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.$J],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.PJ],null)],null)],null));
$CLJS.X(fU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cU],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.nM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WP,$CLJS.OJ],null)],null)],null));$CLJS.X(eU,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wq],null),$CLJS.FT));
$CLJS.X(XT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eU],null)],null)],null)],null));
$CLJS.X(YT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$T],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Ni],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,aU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nM,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fU],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rn,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XT],null)],null)],null)],null));
$CLJS.X($CLJS.ZT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,$CLJS.hD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,YT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));
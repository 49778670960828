var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.T0=function(a,b){var c=$CLJS.yV(b);return $CLJS.n(c)?$CLJS.SZ(a,c):$CLJS.QZ(a,b)};$CLJS.U0=function(a){var b=$CLJS.OA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.nO.h($CLJS.wD(a));case "metadata/column":return $CLJS.SY.h(a);case "mbql/join":return $CLJS.MD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.V0=function(a,b){return $CLJS.ia.j?$CLJS.ia.j("%s__%s",a,b):$CLJS.ia.call(null,"%s__%s",a,b)};$CLJS.W0=function(a,b){var c=$CLJS.U0(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.SJ);d=$CLJS.J.g(d,$CLJS.TJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.T0(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.K0(a,c));a=$CLJS.ia.j?$CLJS.ia.j("%s__via__%s",d,a):$CLJS.ia.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.V0(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.X0=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.TJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.Xj.l($CLJS.ef($CLJS.QJ),$CLJS.ef($CLJS.Di),$CLJS.ef($CLJS.Xj.g($CLJS.Uk,$CLJS.Di)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Di),l=$CLJS.J.g(f,$CLJS.QJ);return $CLJS.T.l($CLJS.K0(a,l),$CLJS.m_,k,$CLJS.H([$CLJS.n_,$CLJS.SY.h(f)]))}),$CLJS.Yj.h(function(f){return $CLJS.Dd(e,$CLJS.TJ.h(f))}),$CLJS.XC(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.TJ),l=$CLJS.J.g(f,
$CLJS.m_),m=$CLJS.J.g(f,$CLJS.n_);f=$CLJS.QZ(a,k);return function v(u){return new $CLJS.je(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.jc(x),C=$CLJS.D(A),G=$CLJS.me(C);return function(){for(var Y=0;;)if(Y<C){var ba=$CLJS.hd(A,Y),ha=$CLJS.T.l(ba,$CLJS.SJ,l,$CLJS.H([$CLJS.j_,m,$CLJS.EJ,$CLJS.wY,$CLJS.lY,$CLJS.U.h(ba)]));ba=G;var ra=$CLJS.T,Ra=ra.j,Ab=ha;ha=$CLJS.W0(a,ha);ha=d.h?d.h(ha):d.call(null,ha);ra=Ra.call(ra,Ab,$CLJS.AY,ha);ba.add(ra);Y+=1}else return!0}()?$CLJS.pe($CLJS.re(G),
v($CLJS.kc(x))):$CLJS.pe($CLJS.re(G),null)}var K=$CLJS.z(x),S=$CLJS.T.l(K,$CLJS.SJ,l,$CLJS.H([$CLJS.j_,m,$CLJS.EJ,$CLJS.wY,$CLJS.lY,$CLJS.U.h(K)]));return $CLJS.be($CLJS.T.j(S,$CLJS.AY,function(){var Y=$CLJS.W0(a,S);return d.h?d.h(Y):d.call(null,Y)}()),v($CLJS.Ic(x)))}return null}},null,null)}($CLJS.H_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.s_,d,$CLJS.l_,!1],null)))})])),c)};$CLJS.Y0=function(a,b){return $CLJS.O0.g(a,b)};$CLJS.Z0=function(a){return $CLJS.YC("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.$0=function(a,b){var c=$CLJS.ID.h(b);$CLJS.n(c)||(c=$CLJS.GY.h(b),$CLJS.n(c)||(c=$CLJS.FY.h(b),c=null!=c?$CLJS.G_.h($CLJS.Y0(a,$CLJS.NX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Ce($CLJS.td(c)?$CLJS.uV.h(c):$CLJS.sd(c)?c:null),$CLJS.n(c)?$CLJS.Zj.g($CLJS.Qs(Vpa,a,b),c):null):null};$CLJS.a1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.b1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.x_.m(null,$CLJS.bK,function(a,b,c){return $CLJS.pz($CLJS.cD,$CLJS.U)(c)});$CLJS.D_.m(null,$CLJS.bK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.cD);return $CLJS.Ta(c)?$CLJS.T.j(a,$CLJS.cD,$CLJS.S0.g($CLJS.R0,b)):a});
$CLJS.H_.m(null,$CLJS.bK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.ID),f=$CLJS.J.g(c,$CLJS.GY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.l_),l=$CLJS.J.g(d,$CLJS.s_);return $CLJS.bf.g($CLJS.G_.v(a,b,c,d),$CLJS.n(k)?$CLJS.X0(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.A_.m(null,$CLJS.GU,function(a,b){var c=$CLJS.xV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.GU);if($CLJS.n(c)){var d=$CLJS.SZ(a,c);a=$CLJS.n(d)?$CLJS.WZ.v(a,b,d,$CLJS.XZ):null;return $CLJS.n(a)?a:$CLJS.Z0(c)}return null});
var Vpa=function(){function a(d,e,f){var k=$CLJS.Qj.g($CLJS.uz(f,$CLJS.Vz),$CLJS.b1);f=$CLJS.pk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.jA,$CLJS.Zi,$CLJS.Os,$CLJS.CJ],null),function(){var l=$CLJS.Di.h(k);if($CLJS.n(l))try{return $CLJS.K0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Os,$CLJS.CJ,$CLJS.EJ,$CLJS.DY,$CLJS.lY,$CLJS.pz($CLJS.lY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.T.j(f,$CLJS.NY,$CLJS.G0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ta(e)||$CLJS.Ta($CLJS.sY.h($CLJS.SZ(d,
$CLJS.G0(e)))):!0)?$CLJS.T.j(f,$CLJS.a1,!0):f;return $CLJS.Wj.g($CLJS.mj.h(k),$CLJS.Wh)?$CLJS.T.j(e,$CLJS.QJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F_.m(null,$CLJS.bK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.s_);return $CLJS.Zj.g(function(f){var k=$CLJS.hB($CLJS.AY,$CLJS.lY,$CLJS.U)(f);return $CLJS.T.j(f,$CLJS.AY,e.h?e.h(k):e.call(null,k))},$CLJS.$0(a,c))});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var y3,A3,C3,D3,E3,Hra;y3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=y3[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=y3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.z3=function(a,b){return y3($CLJS.PZ(a),b)};
A3=function(a,b,c){var d=$CLJS.xV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.QE);d=$CLJS.jG($CLJS.Xj.j($CLJS.Tg([c]),$CLJS.pD,$CLJS.ed),d);if(!$CLJS.n(d))throw $CLJS.Th($CLJS.YC("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.mr,c,$CLJS.wE,a,$CLJS.xK,b],null));return d};$CLJS.B3=function(a){return $CLJS.r1($CLJS.mF,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};C3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
D3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);E3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Hra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.A_.m(null,$CLJS.QE,function(a,b){var c=$CLJS.Ce($CLJS.QE.h($CLJS.xV(a,b)));return $CLJS.n(c)?$CLJS.u1($CLJS.AD("and"),function(){return function f(e){return new $CLJS.je(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.jc(k),m=$CLJS.D(l),t=$CLJS.me(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.WZ.v(a,b,v,$CLJS.XZ);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.pe($CLJS.re(t),f($CLJS.kc(k))):$CLJS.pe($CLJS.re(t),null)}t=$CLJS.z(k);return $CLJS.be($CLJS.WZ.v(a,
b,t,$CLJS.XZ),f($CLJS.Ic(k)))}return null}},null,null)}(c)}()):null});$CLJS.D_.m(null,$CLJS.QE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.jA);e=$CLJS.J.g(e,$CLJS.ki);c=$CLJS.I(c,2,null);c=A3(a,b,c);return $CLJS.pk.l($CLJS.H([$CLJS.UZ.j(a,b,c),new $CLJS.h(null,2,[$CLJS.EJ,$CLJS.dK,$CLJS.n0,$CLJS.pD.h($CLJS.ed(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.jA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ki,e],null):null]))});
$CLJS.x_.m(null,$CLJS.QE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.WZ.v(a,b,A3(a,b,c),d)});$CLJS.gE(D3,E3);
for(var F3=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Jw,$CLJS.bF],null)),G3=null,H3=0,I3=0;;)if(I3<H3){var Ira=G3.X(null,I3);$CLJS.gE(Ira,D3);I3+=1}else{var J3=$CLJS.y(F3);if(J3){var K3=J3;if($CLJS.wd(K3)){var L3=$CLJS.jc(K3),Jra=$CLJS.kc(K3),Kra=L3,Lra=$CLJS.D(L3);F3=Jra;G3=Kra;H3=Lra}else{var Mra=$CLJS.z(K3);$CLJS.gE(Mra,D3);F3=$CLJS.B(K3);G3=null;H3=0}I3=0}else break}
$CLJS.x_.m(null,D3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.WZ.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.YC("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.YC("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.AD("Count");case "cum-count":return $CLJS.AD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.y_.m(null,D3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.D_.m(null,D3,function(a,b,c){var d=$CLJS.T.j,e=$CLJS.cH($CLJS.D_,E3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.T,a,$CLJS.mj,$CLJS.JB)});$CLJS.x_.m(null,$CLJS.JE,function(){return $CLJS.AD("Case")});
$CLJS.y_.m(null,$CLJS.JE,function(){return"case"});$CLJS.gE(C3,E3);for(var M3=$CLJS.y(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.VE,$CLJS.vF,$CLJS.FE,$CLJS.vj,$CLJS.nF,$CLJS.bn,$CLJS.oF,$CLJS.mF,$CLJS.GE],null)),N3=null,O3=0,P3=0;;)if(P3<O3){var Nra=N3.X(null,P3);$CLJS.gE(Nra,C3);P3+=1}else{var Q3=$CLJS.y(M3);if(Q3){var R3=Q3;if($CLJS.wd(R3)){var S3=$CLJS.jc(R3),Ora=$CLJS.kc(R3),Pra=S3,Qra=$CLJS.D(S3);M3=Ora;N3=Pra;O3=Qra}else{var Rra=$CLJS.z(R3);$CLJS.gE(Rra,C3);M3=$CLJS.B(R3);N3=null;O3=0}P3=0}else break}
$CLJS.y_.m(null,C3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.x_.m(null,C3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.WZ.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.YC("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.YC("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.YC("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.YC("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.YC("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.YC("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.YC("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.YC("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.YC("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.x_.m(null,$CLJS.ME,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.YC("{0}th percentile of {1}",$CLJS.H([c,$CLJS.WZ.v(a,b,e,d)]))});$CLJS.y_.m(null,$CLJS.ME,function(){return"percentile"});
$CLJS.gE($CLJS.ME,E3);$CLJS.x_.m(null,$CLJS.tF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.YC("Sum of {0} matching condition",$CLJS.H([$CLJS.WZ.v(a,b,e,d)]))});$CLJS.y_.m(null,$CLJS.tF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.z_.j(a,b,d))].join("")});$CLJS.gE($CLJS.tF,E3);$CLJS.x_.m(null,$CLJS.hF,function(){return $CLJS.AD("Share of rows matching condition")});
$CLJS.y_.m(null,$CLJS.hF,function(){return"share"});$CLJS.gE($CLJS.hF,E3);$CLJS.x_.m(null,$CLJS.jF,function(){return $CLJS.AD("Count of rows matching condition")});$CLJS.y_.m(null,$CLJS.jF,function(){return"count-where"});$CLJS.gE($CLJS.jF,E3);
$CLJS.D_.m(null,E3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.pk.l;d=$CLJS.n(d)?$CLJS.xl($CLJS.UZ.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.UJ],null)):null;var f=$CLJS.cH($CLJS.D_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.pk,$CLJS.H([d,a]))});
$CLJS.Sra=function(){function a(d){return $CLJS.r1($CLJS.Jw,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.r1($CLJS.Jw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.gV.m(null,$CLJS.QE,function(a){return a});
$CLJS.T3=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.OA(f),$CLJS.IJ))f=$CLJS.iV(f);else return $CLJS.w1(d,e,$CLJS.QE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U3=function(){function a(d,e){return $CLJS.Ce($CLJS.QE.h($CLJS.xV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.V3=function(){function a(d,e){var f=$CLJS.Ce($CLJS.QE.h($CLJS.xV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.UZ.j(d,e,k),m=$CLJS.T.l,t=$CLJS.jA.h(l);return m.call($CLJS.T,$CLJS.q1(l,$CLJS.ki,$CLJS.n(t)?t:$CLJS.Zi),$CLJS.EJ,$CLJS.dK,$CLJS.H([$CLJS.n0,$CLJS.pD.h($CLJS.ed(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.x_.m(null,$CLJS.KF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.GF);return $CLJS.cD.h(a.o?a.o():a.call(null))});$CLJS.E_.m(null,$CLJS.KF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.UE);b=$CLJS.J.g(d,$CLJS.GF);c=$CLJS.J.g(d,$CLJS.FF);d=$CLJS.J.g(d,$CLJS.BY);a=$CLJS.T.l(b.o?b.o():b.call(null),$CLJS.v_,$CLJS.Qz(a),$CLJS.H([$CLJS.t_,c]));return null!=d?$CLJS.T.j(a,$CLJS.d_,d):a});
$CLJS.Tra=function(){function a(d,e){var f=function(){var m=$CLJS.Wy.h($CLJS.J0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.xV(d,e),l=$CLJS.I_.j(d,e,k);return $CLJS.Ce($CLJS.Wf.j($CLJS.xf,$CLJS.Xj.j($CLJS.ef(function(m){m=$CLJS.IF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.pl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.FF),u=$CLJS.J.g(m,$CLJS.AF);if($CLJS.Ta(t))return m;if($CLJS.E.g(u,$CLJS.pr))return $CLJS.T.j(m,$CLJS.uV,l);t=$CLJS.Ce($CLJS.i0(function(v){return $CLJS.pZ(u,
v)},l));return $CLJS.n(t)?$CLJS.T.j(m,$CLJS.uV,t):null}),$CLJS.cf.h(function(m){return $CLJS.T.j(m,$CLJS.Os,$CLJS.KF)})),$CLJS.LF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ura=function(){function a(d,e){return $CLJS.hV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UE.h(d),$CLJS.N,$CLJS.jV.h(e)],null))}function b(d){if($CLJS.Ta($CLJS.FF.h(d)))return $CLJS.hV(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UE.h(d),$CLJS.N],null));var e=$CLJS.UE.h(d);e=$CLJS.ia.g?$CLJS.ia.g("aggregation operator %s requires an argument",e):$CLJS.ia.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Th(e,new $CLJS.h(null,1,[Hra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var sma,pU;$CLJS.lU=function(a){return a instanceof Error?a.message:null};
sma=function(a,b,c){if($CLJS.Xd(c)){var d=$CLJS.P.g($CLJS.V,$CLJS.cf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.uf(c)?(d=new $CLJS.Sf(function(){var e=$CLJS.Cb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Db(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.zd(c)?(d=$CLJS.ch($CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?(d=$CLJS.cb(function(e,f){return $CLJS.Zd.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.qd(c)?
(d=$CLJS.Wf.g($CLJS.gd(c),$CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.nU=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mh.h(d),c],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null)}return $CLJS.mU(function(c){return $CLJS.td(c)?$CLJS.Wf.g($CLJS.N,$CLJS.cf.g(b,c)):c},a)};
$CLJS.oU=function(a){var b=new $CLJS.Da;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.qU=function(a,b,c){a=$CLJS.wz(a,/''/,"'");var d=$CLJS.Gs(a,pU);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.Q(null,2,5,$CLJS.R,[a,""],null):d;a=$CLJS.eh(/.*\{0\}.*/,a);return $CLJS.zs.ngettext($CLJS.zs.msgid($CLJS.rk(d),$CLJS.n(a)?c:""),$CLJS.wz($CLJS.wz(b,/''/,"'"),pU,$CLJS.p.h(c)),c)};
$CLJS.rU=function(a,b){return $CLJS.cb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Dd(a,e)?$CLJS.T.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.P.j($CLJS.Qj,a,$CLJS.Ng(b)),b)};$CLJS.sU=function(a,b,c){return $CLJS.td(c)?$CLJS.df(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.Zd.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.sd(c)?$CLJS.df($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Zd.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.mU=function mU(a,b){return sma($CLJS.Qe(mU,a),a,b)};pU=/\{0\}/;$CLJS.tU=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.uU=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.vU=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.wU=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var tma=$CLJS.zg([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cI,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SA,$CLJS.RH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SA,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aI,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WH,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YH,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GH,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZH,null],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.SA,$CLJS.KH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SA,$CLJS.VH],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,null],null)],[$CLJS.gi,$CLJS.QF,$CLJS.dI,$CLJS.QH,$CLJS.vk,$CLJS.cj,$CLJS.Li,$CLJS.xj,$CLJS.li,$CLJS.QH,$CLJS.eI,$CLJS.NF]),yU;$CLJS.Wg($CLJS.cf.g($CLJS.z,$CLJS.Ng(tma)));var xU,uma=$CLJS.Se($CLJS.N),vma=$CLJS.Se($CLJS.N),wma=$CLJS.Se($CLJS.N),xma=$CLJS.Se($CLJS.N),yma=$CLJS.J.j($CLJS.N,$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
xU=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.util","negate*"),$CLJS.z,yma,uma,vma,wma,xma);xU.m(null,$CLJS.ar,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});xU.m(null,$CLJS.er,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Zq],null),$CLJS.cf.g(xU,a))});xU.m(null,$CLJS.Zq,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.er],null),$CLJS.cf.g(xU,a))});
xU.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qF,b,a],null)});xU.m(null,$CLJS.qF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Cj,b,a],null)});xU.m(null,$CLJS.vq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bq,b,a],null)});
xU.m(null,$CLJS.zq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xq,b,a],null)});xU.m(null,$CLJS.xq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zq,b,a],null)});xU.m(null,$CLJS.Bq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vq,b,a],null)});
xU.m(null,$CLJS.CE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zq,b,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vq,b,a],null)],null)});xU.m(null,$CLJS.$E,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ar,a],null)});xU.m(null,$CLJS.SE,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ar,a],null)});
xU.m(null,$CLJS.lF,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ar,a],null)});
yU=function yU(a){return $CLJS.td(a)?$CLJS.Ce($CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.je(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=yU.h?yU.h(u):yU.call(null,u);null!=u&&m.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=yU.h?yU.h(k):yU.call(null,k);if(null!=k)return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[m,k],null),e($CLJS.Ic(f)));f=$CLJS.Ic(f)}else return null},null,null)}(a)}())):$CLJS.sd(a)?$CLJS.Ce($CLJS.Wf.g($CLJS.gd(a),$CLJS.ff($CLJS.Ua,$CLJS.cf.g(yU,a)))):a};
$CLJS.zU=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Ce(yU($CLJS.P.j(d,c,e)));return $CLJS.n(d)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,d],null):$CLJS.E.g(f,$CLJS.aF)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,null],null):new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Ic(c);return b(d,e,c)};a.l=b;return a}();
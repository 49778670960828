var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var P4,Csa,Dsa,Esa,R4,T4,U4,V4,Fsa,Gsa,Y4,W4,X4,a5,b5,Hsa,Isa,c5,Jsa,Ksa,Lsa,Msa,Nsa,Osa,Psa,e5,Qsa,Rsa,Ssa,f5,Tsa,Usa,g5,Vsa,Wsa,Xsa,Ysa;P4=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=P4($CLJS.J.g(a,b),c),$CLJS.pd(c)?$CLJS.Qj.g(a,b):$CLJS.T.j(a,b,c)):$CLJS.Qj.g(a,b)}return a};
Csa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.yl(f,k);return $CLJS.n(v)?u?$CLJS.T.j(f,k,t($CLJS.Db(v),u,l,m)):$CLJS.T.j(f,k,$CLJS.P.j(l,$CLJS.Db(v),m)):f}(a,b,c,d)};Dsa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Csa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.Q4=function(a){return $CLJS.pD.h($CLJS.wD(a))};
Esa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.TO,$CLJS.z(b))?$CLJS.rV(d,function(){var f=$CLJS.IV.h(d);return $CLJS.n(f)?f:$CLJS.qV(c)}()):d;return Dsa(a,b,function(f){return $CLJS.yf(function(){return function m(l){return new $CLJS.je(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.jc(t),v=$CLJS.D(u),x=$CLJS.me(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.E.g($CLJS.Q4(C),$CLJS.Q4(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.pe($CLJS.re(x),m($CLJS.kc(t))):
$CLJS.pe($CLJS.re(x),null)}x=$CLJS.z(t);return $CLJS.be($CLJS.E.g($CLJS.Q4(x),$CLJS.Q4(c))?e:x,m($CLJS.Ic(t)))}return null}},null,null)}(f)}())})};
R4=function(a,b,c,d){var e=$CLJS.rz(a,b);if($CLJS.n(e)){var f=$CLJS.Q4(c),k=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z(b),$CLJS.fd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.Wf.j($CLJS.xf,$CLJS.Yj.h($CLJS.Xj.g($CLJS.Tg([f]),$CLJS.Q4)),e);e=$CLJS.E.g(b,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ID],null))&&$CLJS.Me(function(l){return $CLJS.s1(l,$CLJS.gA)},e)?null:e;if($CLJS.y(e))return $CLJS.OK(a,b,e);if($CLJS.E.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vM,$CLJS.nK],null),new $CLJS.Q(null,2,5,$CLJS.R,
[c,k],null)))throw $CLJS.Th($CLJS.AD("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.ux,$CLJS.CV,$CLJS.nK,$CLJS.rz(a,b),$CLJS.DV,$CLJS.rz(a,$CLJS.nd(b)),$CLJS.xK,d,$CLJS.EV,a],null));return $CLJS.E.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vM,$CLJS.ID],null),new $CLJS.Q(null,2,5,$CLJS.R,[c,k],null))?$CLJS.nW.v(a,$CLJS.nd(b),$CLJS.Qj,k):P4(a,b)}return a};
$CLJS.S4=function(a,b){a=$CLJS.JV.l(a,b,$CLJS.Qj,$CLJS.H([$CLJS.ID]));return $CLJS.Wf.j($CLJS.xf,$CLJS.Yj.h($CLJS.Xj.g(new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.xY,null,$CLJS.wY,null],null),null),$CLJS.EJ)),$CLJS.G_.j(a,b,$CLJS.xV(a,b)))};
T4=function(a,b,c,d){var e=$CLJS.B1.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.iG(function(f){return $CLJS.F1.v(a,b,f,d)},c),(e=!$CLJS.Dd(c,null))?(e=$CLJS.B1.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Me(function(f){return $CLJS.B1.g($CLJS.D(f),1)},$CLJS.Pg(c)):e):e):e};
U4=function(a,b){var c=$CLJS.o3.g(a,b),d=$CLJS.bh(0,$CLJS.D(c));return $CLJS.bf.l(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HQ],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PE],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.aV],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ID],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.QE],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TO],null)],null),function(){return function k(f){return new $CLJS.je(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.wd(l)){var m=
$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.F(c,x);$CLJS.ID.h(A)instanceof $CLJS.M||u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,x,$CLJS.ID],null));v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.ID.h(m)instanceof $CLJS.M)l=$CLJS.Ic(l);else return $CLJS.be(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,u,$CLJS.ID],null),k($CLJS.Ic(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.je(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.jc(l),t=$CLJS.D(m),u=$CLJS.me(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,x,$CLJS.nK],null));v+=1}else{m=!0;break a}return m?$CLJS.pe($CLJS.re(u),k($CLJS.kc(l))):$CLJS.pe($CLJS.re(u),null)}u=$CLJS.z(l);return $CLJS.be(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,u,$CLJS.nK],null),k($CLJS.Ic(l)))}return null}},null,null)}(d)}()]))};
V4=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.gF),k=$CLJS.J.g(e,$CLJS.nQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.jG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.gF.h($CLJS.ed(m)),f)&&$CLJS.E.g($CLJS.nQ.h($CLJS.ed(m)),k)&&$CLJS.E.g($CLJS.fd(m),l)},$CLJS.wl($CLJS.sr,$CLJS.HQ.h($CLJS.xV(a,b)))))};
Fsa=function(a,b,c,d){c=V4(a,b,c);return $CLJS.n(c)?$CLJS.JV.l(a,b,$CLJS.nW,$CLJS.H([new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.HQ,c,2,1],null),$CLJS.Xj.g(function(e){return $CLJS.F0($CLJS.Tk,e)},$CLJS.pk),d])):a};Gsa=function(a,b,c){c=V4(a,b,c);return $CLJS.n(c)?$CLJS.JV.l(a,b,R4,$CLJS.H([new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HQ],null),$CLJS.rz($CLJS.xV(a,b),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HQ,c],null)),b])):a};
Y4=function(a,b,c,d,e,f){var k=$CLJS.JV.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.Q4(e);return $CLJS.Wj.g(a,k)?$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function(m,t){try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.de(u,$CLJS.TO))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var K=$CLJS.qV(e);K=W4.W?W4.W(k,b,c,$CLJS.gA,$CLJS.N,K):W4.call(null,k,b,c,$CLJS.gA,$CLJS.N,K);return X4.v?X4.v(K,b,c,l):X4.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.de(u,$CLJS.QE))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var S=W4.W?W4.W(k,b,c,$CLJS.QE,$CLJS.N,l):W4.call(null,k,b,c,$CLJS.QE,$CLJS.N,l);return X4.v?X4.v(S,b,c,l):X4.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.vd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.de(x,$CLJS.PE))return new $CLJS.Q(null,
1,5,$CLJS.R,[X4.v?X4.v(k,b,c,l):X4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.de(x,$CLJS.ID))return new $CLJS.Q(null,1,5,$CLJS.R,[X4.v?X4.v(k,b,c,l):X4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.de(C,$CLJS.vM))try{var G=$CLJS.F(t,2);if($CLJS.de(G,$CLJS.ID))return new $CLJS.Q(null,1,5,$CLJS.R,[X4.v?X4.v(k,b,c,l):X4.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)return new $CLJS.Q(null,1,5,$CLJS.R,[k],null);throw A;}throw Y;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.xf,d)))):k};
W4=function(a,b,c,d,e,f){var k=$CLJS.xV(a,b),l=$CLJS.df(function(t){var u=$CLJS.rz(k,t);return $CLJS.n(u)?$CLJS.vl(function(v){return $CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function G(A,C){try{if($CLJS.vd(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.pd(e)||$CLJS.E0($CLJS.Wg(e),$CLJS.Wg(S)))try{var Y=$CLJS.F(C,2);if($CLJS.E.g(Y,f))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[t,v],null)],null);throw $CLJS.Z;}catch(ha){if(ha instanceof Error){var ba=
ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)throw $CLJS.Z;throw ba;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){ba=ha;if(ba===$CLJS.Z)return $CLJS.sU(G,A,C);throw ba;}throw ha;}}($CLJS.xf,v))))},u):null},$CLJS.H([U4(a,b)])),m=$CLJS.Ve($CLJS.bc($CLJS.xf));a=$CLJS.cb(function(t,u){var v=
$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);return Y4(t,b,c,v,u,function(x,A,C){try{return R4(x,A,C,b)}catch(K){if(K instanceof Error){A=K;var G=$CLJS.O($CLJS.Al(A));C=$CLJS.J.g(G,$CLJS.ux);G=$CLJS.J.g(G,$CLJS.DV);if($CLJS.E.g(C,$CLJS.CV))return m.nd(null,$CLJS.ue(m.Lb(null),G)),x;throw A;}throw K;}})},a,l);return $CLJS.cb(function(t,u){return $CLJS.Z4.j?$CLJS.Z4.j(t,b,u):$CLJS.Z4.call(null,t,b,u)},a,$CLJS.ec($CLJS.q(m)))};
X4=function(a,b,c,d){b=$CLJS.R1(c,b);if($CLJS.n(b)){var e=$CLJS.xV(c,b);c=$CLJS.Ne(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.EJ),l=$CLJS.J.g(f,$CLJS.n0);return $CLJS.E.g($CLJS.mY,k)&&$CLJS.E.g(d,l)?$CLJS.AY.h(f):null},$CLJS.I_.j(c,b,e));return $CLJS.n(c)?W4(a,b,a,$CLJS.aF,$CLJS.N,c):a}return a};
a5=function(a,b,c,d,e){var f=$CLJS.jV.h(c),k=$CLJS.xV(a,b);c=$CLJS.jG(function(t){var u=$CLJS.rz(k,t);if($CLJS.n(u)){var v=$CLJS.Q4(f);return $CLJS.n($CLJS.Ne($CLJS.Xj.j($CLJS.Tg([v]),$CLJS.pD,$CLJS.ed),u))?t:null}return null},U4(a,b));var l=(d=$CLJS.E.g($CLJS.xF,d))?$CLJS.jV.h(e):null;e=d?function(t,u,v){return Esa(t,u,v,l)}:function(t,u,v){return R4(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PE],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.fd(f),$CLJS.fd(l))?
Fsa(a,b,f,$CLJS.xl($CLJS.ed(l),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nQ,$CLJS.gF],null))):m?Gsa(a,b,f):a;return $CLJS.n(c)?(a=Y4(a,b,a,c,f,e),$CLJS.$4.h?$CLJS.$4.h(a):$CLJS.$4.call(null,a)):a};b5=function(a){return $CLJS.xD(a,$CLJS.T,$CLJS.H([$CLJS.pD,$CLJS.p.h($CLJS.zD())]))};
Hsa=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.de(m,$CLJS.gA))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return b5(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.QV(l,f,k);throw u;
}throw v;}}($CLJS.xf,d)}(a)};Isa=function(a,b,c){var d=function(){var l=$CLJS.qV(c);return $CLJS.n(l)?l:$CLJS.U.h($CLJS.wD(c))}(),e=b5($CLJS.rV(c,d));a=$CLJS.ak.j(a,$CLJS.TO,function(l){return $CLJS.Zj.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.qV(b),k=$CLJS.ki.h($CLJS.wD(c));return Hsa(a,f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,new $CLJS.h(null,1,[$CLJS.ki,k],null),d],null))};
c5=function(a,b,c){return $CLJS.mU(function(d){return $CLJS.E.g(d,$CLJS.Q4(b))?$CLJS.Q4(c):d},$CLJS.n($CLJS.qV(b))?Isa(a,b,c):$CLJS.mU(function(d){return $CLJS.E.g(d,b)?c:d},a))};Jsa=function(a,b,c){a=$CLJS.G_.j(a,b,$CLJS.xV(a,b));b=$CLJS.G_.j(c,b,$CLJS.xV(c,b));return $CLJS.ff(function(d){return $CLJS.Wj.g($CLJS.z(d),$CLJS.ed(d))},$CLJS.cf.j($CLJS.sr,a,b))};
Ksa=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.AY.h(d),f=$CLJS.iV($CLJS.T.j(c,$CLJS.EJ,$CLJS.mY));return $CLJS.cf.g(function(k){return new $CLJS.Q(null,2,5,$CLJS.R,[k,b5(f)],null)},$CLJS.y($CLJS.ff($CLJS.Ua,function t(l,m){try{if($CLJS.vd(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.de(u,$CLJS.aF))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.Q(null,1,5,$CLJS.R,[m],null);throw $CLJS.Z;}catch(A){if(A instanceof Error){var x=A;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw A;}else throw $CLJS.Z;}catch(A){if(A instanceof Error){x=A;if(x===$CLJS.Z)return $CLJS.sU(t,l,m);throw x;}throw A;}}($CLJS.xf,$CLJS.xV(a,b)))))};Lsa=function(a,b,c){var d=$CLJS.ki.h($CLJS.wD(c));$CLJS.n(d)||(d=$CLJS.x0.h?$CLJS.x0.h(c):$CLJS.x0.call(null,c),d=$CLJS.Ta(d)||!$CLJS.pV(c));if($CLJS.n(d))return c;a=$CLJS.C_.j(a,b,c);return $CLJS.xD(c,$CLJS.T,$CLJS.H([$CLJS.ki,a]))};
Msa=function(a,b){a=$CLJS.qV(a);return $CLJS.n($CLJS.n(a)?$CLJS.Ta($CLJS.qV(b))&&$CLJS.Ta($CLJS.U.h($CLJS.wD(b))):a)?$CLJS.rV(b,a):b};
Nsa=function(a,b,c,d){d=Lsa(a,b,Msa(c,d));c=$CLJS.JV.l(a,b,c5,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.R1(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.df(function(l,m,t){return function(u){return Ksa(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([Jsa(a,e,c)]));c=$CLJS.cb(function(l,m,t,u){return function(v,x){var A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.JV.l(v,u,c5,$CLJS.H([A,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Osa=function(a,b){b=$CLJS.Hk.h(b);if($CLJS.E.g($CLJS.z(b),$CLJS.kK)){var c=$CLJS.ed(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Ne(function(k){return $CLJS.Me($CLJS.dl,$CLJS.cf.j($CLJS.E,k,d))?k:null},U4(a,c)):f}();return $CLJS.n(e)?$CLJS.ek.j(b,0,$CLJS.D(e)+2+1):null}return null};
Psa=function(a,b,c,d){for(var e=Nsa(a,b,c,d);;){var f=$CLJS.wX($CLJS.DK,e),k=$CLJS.qk.h($CLJS.vl(function(l){return function(m){return Osa(l,m)}}(e,f),$CLJS.Ar.h(f)));if($CLJS.y(k))e=$CLJS.cb(function(){return function(l,m){try{return $CLJS.d5.j(l,$CLJS.ed(m),$CLJS.rz(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.Al(t)),v=$CLJS.J.g(u,$CLJS.ux);u=$CLJS.J.g(u,$CLJS.DV);if($CLJS.E.g(v,$CLJS.CV))return m=$CLJS.ed(m),$CLJS.Z4.j?$CLJS.Z4.j(l,m,u):$CLJS.Z4.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?a5(a,b,c,$CLJS.xF,d):e}};e5=function(a,b){return $CLJS.s1(a,$CLJS.aF)&&$CLJS.E.g($CLJS.U0(a),b)};Qsa=function(a,b,c){return function k(e,f){try{if(e5(f,b))return $CLJS.U2(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.QV(k,e,f);throw l;}throw m;}}($CLJS.xf,a)};
Rsa=function(a,b,c){var d=$CLJS.vM.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.rz(d,new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.MD],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Wj.g(e,c):b)){var f=$CLJS.AV();$CLJS.P2(f,$CLJS.cf.g($CLJS.MD,d));c=f(c);return Qsa($CLJS.OK(a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,b,$CLJS.MD],null),c),e,c)}return a};
Ssa=function(a,b,c){if($CLJS.Bd(c))return c;var d=function(){var e=$CLJS.Tg([c]);return"string"===typeof c?$CLJS.Xj.g(e,$CLJS.MD):e}();return $CLJS.Ne(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.wl($CLJS.sr,$CLJS.vM.h($CLJS.xV(a,b))))};
f5=function(a,b,c,d){b=$CLJS.Vs.g($CLJS.Wg($CLJS.I_.j(b,c,$CLJS.xV(b,c))),$CLJS.Wg($CLJS.I_.j(a,c,$CLJS.xV(a,c))));return $CLJS.cb(function(e,f){return $CLJS.P.M(W4,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Tsa=function(a,b,c){a=f5(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.nO,$CLJS.SY.h(d)],null),$CLJS.Di.h(d)],null)});c=$CLJS.R1(a,c);return $CLJS.n(c)?f5(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,$CLJS.N,$CLJS.AY.h(d)],null)}):a};
Usa=function(a,b,c){return $CLJS.Bd(c)?$CLJS.rz($CLJS.xV(a,b),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,c,$CLJS.MD],null)):$CLJS.td(c)?$CLJS.MD.h(c):c};
g5=function(a,b,c,d){var e=Usa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.JV)(a,b,function(k){var l=$CLJS.vM.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.vD(k,$CLJS.vM,l)});return(0,$CLJS.JV)(f,b,function(k){return $CLJS.MV(k,$CLJS.vM,function(l){return $CLJS.Zj.g(function(m){return $CLJS.b3(f,b,m)},l)})})}(),c=Tsa(c,a,b),$CLJS.$4.h?$CLJS.$4.h(c):$CLJS.$4.call(null,c)):a};
Vsa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ua,function f(d,e){try{if(e5(e,b))return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.sU(f,d,e);throw k;}throw l;}}($CLJS.xf,a))))};Wsa=function(a,b){var c=$CLJS.ID.h($CLJS.xV(a,b));if($CLJS.n(c)){var d=$CLJS.Ta($CLJS.Ne($CLJS.Xj.g($CLJS.wM,$CLJS.wD),c));return d?T4(a,b,c,$CLJS.S4(a,b)):d}return c};
Xsa=function(a,b){var c=$CLJS.xV(a,b),d=$CLJS.n(Wsa(a,b))?$CLJS.JV.l(a,b,$CLJS.Qj,$CLJS.H([$CLJS.ID])):a;return $CLJS.n($CLJS.vM.h(c))?$CLJS.JV.l(d,b,$CLJS.ak,$CLJS.H([$CLJS.vM,$CLJS.Qe($CLJS.Zj,function(e){var f=$CLJS.ID.h(e);var k=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ox,null,$CLJS.Vy,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(T4(a,b,$CLJS.ID.h(e),$CLJS.G_.j(a,b,$CLJS.T.j(e,$CLJS.ID,$CLJS.ox))))?$CLJS.T.j(e,$CLJS.ID,$CLJS.ox):e})])):d};
$CLJS.$4=function(a){return $CLJS.cb(Xsa,a,$CLJS.bh(0,$CLJS.D($CLJS.kK.h(a))))};Ysa=new $CLJS.M(null,"remove","remove",-131428414);var h5;$CLJS.d5=function(){function a(d,e,f){return $CLJS.td(f)&&$CLJS.E.g($CLJS.Os.h(f),$CLJS.fK)?$CLJS.Z4.j?$CLJS.Z4.j(d,e,f):$CLJS.Z4.call(null,d,e,f):a5(d,e,f,Ysa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();h5=$CLJS.dE.h($CLJS.OD);
$CLJS.j5=function(){function a(d,e,f,k){if($CLJS.td(f)&&$CLJS.E.g($CLJS.Os.h(f),$CLJS.fK))d=$CLJS.i5.v?$CLJS.i5.v(d,e,f,k):$CLJS.i5.call(null,d,e,f,k);else{var l=h5.h?h5.h(f):h5.call(null,f);l=$CLJS.n(l)?h5.h?h5.h(k):h5.call(null,k):l;d=$CLJS.n(l)?Psa(d,e,f,k):a5(d,e,f,$CLJS.xF,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.Zsa=function(){function a(d,e,f,k){f=Ssa(d,e,f);return $CLJS.n(f)?$CLJS.JV.l(d,e,Rsa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Z4=function(){function a(d,e,f){try{return g5(d,e,f,function(u,v){return $CLJS.Ce($CLJS.i0(function(x){return!($CLJS.E.g($CLJS.MD.h(x),v)||Vsa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.Al(k)),m=$CLJS.J.g(l,$CLJS.xK),t=$CLJS.J.g(l,$CLJS.ux);l=$CLJS.J.g(l,$CLJS.DV);if($CLJS.E.g(t,$CLJS.CV))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.i5=function(){function a(d,e,f,k){return null==k?$CLJS.Z4.j(d,e,f):g5(d,e,f,function(l,m){return $CLJS.Zj.g(function(t){return $CLJS.E.g($CLJS.MD.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var N6,O6,P6,lta,mta,nta,S6,T6,V6,W6,ota,pta,Y6,Z6,qta,rta;N6=function(a){var b=new $CLJS.h(null,3,[$CLJS.pD,$CLJS.p.h($CLJS.zD()),$CLJS.jA,$CLJS.jA.h(a),$CLJS.ki,$CLJS.pz($CLJS.ki,$CLJS.jA)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gA,b,$CLJS.pz($CLJS.gD,$CLJS.U)(a)],null)};O6=function(a){return $CLJS.T.j(a,$CLJS.Os,$CLJS.d4)};P6=function(a,b){return $CLJS.E.g($CLJS.bD.h(a),$CLJS.xl(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tD,$CLJS.aD,$CLJS.sD],null)))};
lta=function(a){return $CLJS.A0(a,new $CLJS.h(null,1,[$CLJS.tD,$CLJS.mh],null))};
mta=function(a,b){var c=$CLJS.Ne(function(e){return $CLJS.jG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.field",c))){var d=$CLJS.YC("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.Zj.g($CLJS.AY,b)]))]));d instanceof Error?$CLJS.Az("metabase.lib.field",c,$CLJS.pv(),d):$CLJS.Az("metabase.lib.field",
c,$CLJS.pv.l($CLJS.H([d])),null)}return null};
nta=function(a,b,c){if($CLJS.n(Q6))return null;var d=Q6;Q6=!0;try{var e=$CLJS.t1(a,b),f=$CLJS.n(e)?$CLJS.xV(a,e):$CLJS.xV(a,b),k=function(){var m=$CLJS.R6.h(f);if($CLJS.n(m))return m;m=$CLJS.rz(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gU,$CLJS.uV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.GU.h(f);if($CLJS.n(u))return u;u=$CLJS.ZN.h(f);if($CLJS.n(u))return u;u=$CLJS.TO.h(f);return $CLJS.n(u)?u:$CLJS.ID.h(f)}())?$CLJS.I_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.field",
m))){var t=$CLJS.YC("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Az("metabase.lib.field",m,$CLJS.pv(),t):$CLJS.Az("metabase.lib.field",m,$CLJS.pv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?mta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.T.j($CLJS.T.j($CLJS.U2($CLJS.Qj.l(l,$CLJS.Di,$CLJS.H([$CLJS.TJ,$CLJS.HV,$CLJS.BV])),null),$CLJS.U,function(){var m=$CLJS.AY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.EJ,$CLJS.mY):l:null}finally{Q6=d}};
S6=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.nO);var f=$CLJS.I(c,2,null);c=$CLJS.pk.l($CLJS.H([function(){var k=$CLJS.jA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.jA,k],null):null}(),function(){var k=$CLJS.pz($CLJS.ki,$CLJS.jA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ki,k],null):null}(),function(){var k=$CLJS.nQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.HV,k],null):null}(),function(){var k=$CLJS.gF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.BV,k],null):null}(),$CLJS.Bd(f)?function(){var k=$CLJS.z1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Os,$CLJS.CJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Os,$CLJS.CJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=nta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Os,$CLJS.CJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.U2(c,d):c};
T6=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BV);return $CLJS.n($CLJS.n(b)?$CLJS.Dd($CLJS.gG,b):b)?$CLJS.Vi:$CLJS.pz($CLJS.ki,$CLJS.jA)(a)};
$CLJS.U6=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.pD),l=$CLJS.J.g(f,$CLJS.jA),m=$CLJS.J.g(f,$CLJS.nQ),t=$CLJS.J.g(f,$CLJS.ki);e=$CLJS.J.g(f,$CLJS.nO);var u=$CLJS.J.g(f,$CLJS.wM),v=$CLJS.J.g(f,$CLJS.gF),x=$CLJS.pk.l;k=new $CLJS.h(null,2,[$CLJS.Os,$CLJS.CJ,$CLJS.n0,k],null);f=$CLJS.cD.h(f);a=$CLJS.n(f)?f:$CLJS.WZ.j(a,b,d);c=x.call($CLJS.pk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.cD,a],null)]));c=$CLJS.n(t)?$CLJS.T.j(c,$CLJS.ki,t):c;l=$CLJS.n(l)?$CLJS.T.j(c,
$CLJS.jA,l):c;v=$CLJS.n(v)?$CLJS.T.j(l,$CLJS.BV,v):l;m=$CLJS.n(m)?$CLJS.T.j(v,$CLJS.HV,m):v;u=$CLJS.n(u)?$CLJS.T.j(m,$CLJS.SJ,u):m;return $CLJS.n(e)?$CLJS.U2(u,e):u};V6=function(a,b,c){return $CLJS.n($CLJS.Ne(function(d){return $CLJS.E.g($CLJS.uk.h(d),c)},a))?$CLJS.Zj.g(function(d){var e=$CLJS.Dd(d,b)?$CLJS.Qj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.uk.h(d),c)?$CLJS.T.j(e,b,!0):e},a):a};
W6=function(a){var b=$CLJS.n($CLJS.a1.h(a))?null:function(){var e=$CLJS.EJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.DY,null,$CLJS.KY,null,$CLJS.mY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.pk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.pD,$CLJS.p.h($CLJS.zD()),$CLJS.jA,$CLJS.jA.h(a),$CLJS.ki,T6(a)],null),function(){var e=function(){var f=$CLJS.Ta(b);return f?(f=$CLJS.Ta($CLJS.SJ.h(a)))?(f=$CLJS.Wj.g($CLJS.wY,$CLJS.EJ.h(a)))?$CLJS.OY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.nO,e],null):null}(),function(){var e=$CLJS.U0(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.nO,e],null):null}(),function(){var e=$CLJS.BV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.gF,e],null):null}(),function(){var e=$CLJS.FV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.FV,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.nQ,e],null):null}(),function(){var e=$CLJS.SJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.pz($CLJS.AY,$CLJS.U):$CLJS.pz($CLJS.Di,$CLJS.U))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,c,d],null)};ota=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.EJ.h(c),$CLJS.uY)},$CLJS.I_.v(a,b,$CLJS.xV(a,b),new $CLJS.h(null,3,[$CLJS.q_,!1,$CLJS.w_,!0,$CLJS.l_,!1],null)))};pta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);$CLJS.X6=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);
Y6=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);Z6=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.R6=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);qta=new $CLJS.M(null,"earliest","earliest",-1928154382);rta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.B0.m(null,$CLJS.aF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mh.h(b),$CLJS.A0(c,new $CLJS.h(null,2,[$CLJS.gF,$CLJS.mh,$CLJS.nQ,lta],null)),a],null)});var Q6=!1,$6=function $6(a,b){var d=$CLJS.K0(a,Z6.h(b));a=$CLJS.n(Z6.h(d))?$6.g?$6.g(a,d):$6.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U);return $CLJS.ak.j(b,$CLJS.U,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.B_.m(null,$CLJS.CJ,function(a,b,c){return T6(c)});$CLJS.B_.m(null,$CLJS.aF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.gF);$CLJS.I(c,2,null);c=S6(a,b,c);c=$CLJS.n(d)?$CLJS.T.j(c,$CLJS.BV,d):c;return $CLJS.C_.j(a,b,c)});$CLJS.D_.m(null,$CLJS.CJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.T.j(a,$CLJS.U,b)});$CLJS.D_.m(null,$CLJS.aF,function(a,b,c){var d=S6(a,b,c);b=$CLJS.U6(a,b,d,c);return $CLJS.n(Z6.h(b))?$6(a,b):b});
$CLJS.x_.m(null,$CLJS.CJ,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.cD),f=$CLJS.J.g(c,$CLJS.U),k=$CLJS.J.g(c,$CLJS.uk),l=$CLJS.J.g(c,$CLJS.HV),m=$CLJS.J.g(c,$CLJS.OY),t=$CLJS.J.g(c,$CLJS.SJ),u=$CLJS.J.g(c,$CLJS.TJ);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.S0.g($CLJS.R0,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.XZ)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.K0(a,t),$CLJS.n(t)?a=$CLJS.Q2($CLJS.cD.h($CLJS.ZZ.j(a,b,t))):(u=$CLJS.T0(a,u),a=$CLJS.WZ.v(a,b,u,d))):a=null,m=$CLJS.n(a)?a:$CLJS.n(m)?m:$CLJS.U0(c)):
m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.Tz($CLJS.wz($CLJS.Xg(k),"-"," ")),$CLJS.ia.j?$CLJS.ia.j("%s: %s",m,c):$CLJS.ia.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.J4(l,c),$CLJS.ia.j?$CLJS.ia.j("%s: %s",m,c):$CLJS.ia.call(null,"%s: %s",m,c)):m});
$CLJS.x_.m(null,$CLJS.aF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.nQ);var k=$CLJS.J.g(f,$CLJS.nO),l=$CLJS.J.g(f,$CLJS.gF);f=$CLJS.J.g(f,$CLJS.wM);$CLJS.I(c,2,null);c=S6(a,b,c);c=$CLJS.n(k)?$CLJS.T.j(c,$CLJS.OY,k):c;l=$CLJS.n(l)?$CLJS.T.j(c,$CLJS.uk,l):c;e=$CLJS.n(e)?$CLJS.T.j(l,$CLJS.HV,e):l;e=$CLJS.n(f)?$CLJS.T.j(e,$CLJS.SJ,f):e;return $CLJS.n(e)?$CLJS.WZ.v(a,b,e,d):$CLJS.AD("[Unknown Field]")});
$CLJS.y_.m(null,$CLJS.CJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.y_.m(null,$CLJS.aF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=S6(a,b,c);return $CLJS.n(c)?$CLJS.z_.j(a,b,c):"unknown_field"});
$CLJS.E_.m(null,$CLJS.CJ,function(a,b,c){return $CLJS.pk.l($CLJS.H([function(){var d=$CLJS.cH($CLJS.E_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.EJ.h(c),$CLJS.DY)?function(){var d=$CLJS.NY.h(c);return $CLJS.n(d)?(d=$CLJS.SZ(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.GV,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.cD,$CLJS.U.h(d)],null)],null):null):null}():null]))});$CLJS.e0.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.gF.h(b)});
$CLJS.e0.m(null,$CLJS.CJ,function(a){return $CLJS.BV.h(a)});
$CLJS.d0.m(null,$CLJS.aF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Dd($CLJS.gG,b),e=$CLJS.hB($CLJS.FV,$CLJS.ki,$CLJS.jA)(c);c=$CLJS.T.l(c,$CLJS.gF,b,$CLJS.H([$CLJS.ki,d?$CLJS.Vi:e,$CLJS.FV,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,c,a],null)}b=$CLJS.FV.h(c);c=$CLJS.n(b)?$CLJS.Qj.g($CLJS.T.j(c,$CLJS.ki,b),$CLJS.FV):c;c=$CLJS.Qj.g(c,$CLJS.gF);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,c,a],null)});
$CLJS.d0.m(null,$CLJS.CJ,function(a,b){return $CLJS.n(b)?$CLJS.T.l(a,$CLJS.BV,b,$CLJS.H([$CLJS.FV,$CLJS.hB($CLJS.FV,$CLJS.ki,$CLJS.jA)(a)])):$CLJS.Qj.l(a,$CLJS.BV,$CLJS.H([$CLJS.FV]))});$CLJS.g0.m(null,$CLJS.aF,function(a,b,c){return $CLJS.h0.j(a,b,S6(a,b,c))});
$CLJS.g0.m(null,$CLJS.CJ,function(a,b,c){if($CLJS.Wj.g($CLJS.EJ.h(c),$CLJS.uY)){a=$CLJS.pz($CLJS.ki,$CLJS.jA)(c);b=null==c?null:$CLJS.KM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Ak.h($CLJS.Ni.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,qta),l=$CLJS.J.g(f,rta),m=$CLJS.npa.l($CLJS.H([$CLJS.b0.h(k),$CLJS.b0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.wq.g?$CLJS.wq.g(1,m):$CLJS.wq.call(null,1,m))?$CLJS.yk:$CLJS.n($CLJS.wq.g?$CLJS.wq.g(31,m):$CLJS.wq.call(null,31,m))?$CLJS.Fk:$CLJS.n($CLJS.wq.g?
$CLJS.wq.g(365,m):$CLJS.wq.call(null,365,m))?$CLJS.Qi:$CLJS.Wi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.tz(a,$CLJS.Ak)?$CLJS.ypa:$CLJS.tz(a,$CLJS.wk)?$CLJS.xpa:$CLJS.tz(a,$CLJS.Gk)?$CLJS.wpa:$CLJS.xf;d=$CLJS.n(d)?V6(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.BV.h(c))?V6(d,$CLJS.d_,$CLJS.BV.h(c)):d}return $CLJS.xf});
$CLJS.G4.m(null,$CLJS.aF,function(a){var b=null==a?null:$CLJS.wD(a);b=null==b?null:$CLJS.nQ.h(b);return null==b?null:$CLJS.T.l(b,$CLJS.Os,$CLJS.L4,$CLJS.H([$CLJS.K4,function(c,d){return S6(c,d,a)}]))});$CLJS.G4.m(null,$CLJS.CJ,function(a){var b=null==a?null:$CLJS.HV.h(a);return null==b?null:$CLJS.T.l(b,$CLJS.Os,$CLJS.L4,$CLJS.H([$CLJS.K4,$CLJS.Pe(a)]))});$CLJS.M4.m(null,$CLJS.aF,function(a,b){return $CLJS.xD(a,$CLJS.vD,$CLJS.H([$CLJS.nQ,b]))});
$CLJS.M4.m(null,$CLJS.CJ,function(a,b){return $CLJS.vD(a,$CLJS.HV,b)});$CLJS.N4.m(null,$CLJS.aF,function(a,b,c){return $CLJS.O4.j(a,b,S6(a,b,c))});
$CLJS.N4.m(null,$CLJS.CJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ki);var d=$CLJS.J.g(b,$CLJS.KM),e=$CLJS.J.g(b,$CLJS.mj);if($CLJS.Wj.g($CLJS.EJ.h(b),$CLJS.uY)){var f=function(){var m=null==a?null:$CLJS.J0(a);m=null==m?null:$CLJS.Wy.h(m);return null==m?null:$CLJS.Dd(m,$CLJS.nQ)}(),k=$CLJS.rz(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,$CLJS.ij],null)),l=$CLJS.H4(b);return function u(t){return new $CLJS.je(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.wd(v)){var x=$CLJS.jc(v),A=$CLJS.D(x),
C=$CLJS.me(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),Y=C,ba=S;S=P6(S,l)?$CLJS.T.j(ba,$CLJS.d_,!0):ba;Y.add(S);K+=1}else return!0}()?$CLJS.pe($CLJS.re(C),u($CLJS.kc(v))):$CLJS.pe($CLJS.re(C),null)}var G=$CLJS.z(v);return $CLJS.be(function(){var K=G;return P6(G,l)?$CLJS.T.j(K,$CLJS.d_,!0):K}(),u($CLJS.Ic(v)))}return null}},null,null)}($CLJS.Ta(function(){if($CLJS.n(f)){var t=$CLJS.bn.h(k);return $CLJS.n(t)?$CLJS.vj.h(k):t}return f}())?null:$CLJS.tz(e,$CLJS.Xi)?$CLJS.Zj.g(O6,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.I4(),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("Bin every 0.1 degrees"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.sD,$CLJS.sD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("Bin every 1 degree"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.sD,$CLJS.sD,1],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("Bin every 10 degrees"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.sD,$CLJS.sD,10],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("Bin every 20 degrees"),$CLJS.bD,new $CLJS.h(null,
2,[$CLJS.tD,$CLJS.sD,$CLJS.sD,20],null)],null)],null)):$CLJS.tz(c,$CLJS.ij)&&!$CLJS.tz(e,$CLJS.Yh)?$CLJS.Zj.g(O6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.I4(),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("10 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.aD,$CLJS.aD,10],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("50 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.aD,$CLJS.aD,50],null)],null),new $CLJS.h(null,2,[$CLJS.cD,$CLJS.AD("100 bins"),$CLJS.bD,new $CLJS.h(null,2,[$CLJS.tD,$CLJS.aD,$CLJS.aD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.gV.m(null,$CLJS.aF,function(a){return a});
$CLJS.gV.m(null,$CLJS.CJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.pD,$CLJS.p.h($CLJS.zD()),$CLJS.ki,$CLJS.pz($CLJS.ki,$CLJS.jA)(a),$CLJS.tK,$CLJS.U.h(a)],null),a=$CLJS.n0.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QE,b,a],null);case "source/expressions":return N6(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.gD.h(a))?N6(a):W6(a);default:return W6(a)}});
$CLJS.sta=function(){function a(e,f,k){k=$CLJS.Ce($CLJS.Zj.g($CLJS.iV,k));var l=ota(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.pl(function(t){return $CLJS.F1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.Yj.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.iV),m):null;return $CLJS.JV.l(e,f,$CLJS.vD,$CLJS.H([$CLJS.ID,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.a7=function(){function a(d,e){return $CLJS.ID.h($CLJS.xV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tta=function(){function a(d,e){var f=$CLJS.I_.v(d,e,$CLJS.xV(d,e),new $CLJS.h(null,3,[$CLJS.q_,!1,$CLJS.w_,!1,$CLJS.l_,!1],null)),k=$CLJS.a7.g(d,e);return $CLJS.pd(k)?$CLJS.Zj.g(function(l){return $CLJS.T.j(l,$CLJS.BY,!0)},f):$CLJS.S2(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X(Y6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wq,$CLJS.$i,$CLJS.pY,$CLJS.Vy],null));
$CLJS.X(pta,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JY,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,Y6],null)],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var vX,Qna,Rna,Sna,Tna,xX,Vna,Wna,Xna,Yna,zX,AX,BX,DX,Zna,EX,FX,$na,aoa,boa,GX,Una,HX,IX,coa,KX;vX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Qna=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni.j?$CLJS.Ni.j(k,l,m):$CLJS.Ni.call(null,k,l,m),$CLJS.Hk.j?$CLJS.Hk.j(k,l,m):$CLJS.Hk.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni.g?$CLJS.Ni.g(k,l):$CLJS.Ni.call(null,k,l),$CLJS.Hk.g?$CLJS.Hk.g(k,l):$CLJS.Hk.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni.h?$CLJS.Ni.h(k):$CLJS.Ni.call(null,k),$CLJS.Hk.h?$CLJS.Hk.h(k):$CLJS.Hk.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni.o?$CLJS.Ni.o():$CLJS.Ni.call(null),$CLJS.Hk.o?$CLJS.Hk.o():$CLJS.Hk.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.M($CLJS.Ni,m,t,u,v),$CLJS.P.M($CLJS.Hk,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Ic(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};$CLJS.wX=function(a,b){return $CLJS.IK(a)(b,$CLJS.xf,$CLJS.xf)};Rna=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.T.j(d,f,e)},null==b?a:$CLJS.T.j(a,$CLJS.QE,b),$CLJS.Pr(2,2,c))};Sna=function(a,b){var c=$CLJS.yl(a,$CLJS.zP);if($CLJS.n(c)){var d=$CLJS.T.j;c=$CLJS.Db(c);b=$CLJS.$r.g?$CLJS.$r.g(c,b):$CLJS.$r.call(null,c,b);a=d.call($CLJS.T,a,$CLJS.zP,b)}return a};
Tna=function(a){return $CLJS.bf.g($CLJS.EU(a),$CLJS.FU(a))};xX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.wr,null,$CLJS.Mk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.kt(2,c):$CLJS.kt(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?vX(2,c):vX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.nW.j(a,e,function(k){var l=$CLJS.T.j(k,f,null);return $CLJS.vd(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.Yj.h($CLJS.Tk),l):$CLJS.td(k)?$CLJS.zz($CLJS.Ua,l):l}):$CLJS.Qj.g(a,f)};
Vna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return xX(b,Una,d)},a,Tna(a))};
Wna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.kK.h(a),b);a:{var d=c;for(var e=$CLJS.xf;;){var f=$CLJS.z($CLJS.cf.g(Qna(),$CLJS.ff($CLJS.Xj.j(yX,$CLJS.z,$CLJS.Hk),$CLJS.Ar.h($CLJS.wX($CLJS.dV,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=xX(d,f,k),m=$CLJS.Cz($CLJS.wx);if($CLJS.n($CLJS.Bz("metabase.lib.convert",m))){var t=$CLJS.rA.l($CLJS.H([$CLJS.qy,$CLJS.xh.l($CLJS.H([k]))])),u=$CLJS.rA,v=u.l,x=$CLJS.xh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.NK($CLJS.wX($CLJS.dV,d));
$CLJS.Az("metabase.lib.convert",m,$CLJS.mV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.qy,A.call(x,$CLJS.H([C]))])),$CLJS.rA.l($CLJS.H([$CLJS.fy,$CLJS.nV($CLJS.z($CLJS.Ss(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.Zd.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=Vna(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.kK.h(a))-1))return a;b+=1}else a=$CLJS.ak.M(a,$CLJS.kK,$CLJS.T,b,d)}};
Xna=function(a){var b=$CLJS.AV();return $CLJS.Zj.g(function(c){return $CLJS.E.g($CLJS.MD.h(c),"__join")?$CLJS.ak.j(c,$CLJS.MD,b):c},a)};Yna=function(a){return"string"===typeof $CLJS.ZN.h(a)?$CLJS.Qj.g($CLJS.T.j(a,$CLJS.GU,$CLJS.yV($CLJS.ZN.h(a))),$CLJS.ZN):a};zX=function(){return $CLJS.Yj.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.ie(b))?(b=$CLJS.ee(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
AX=function(a){return $CLJS.Wf.j($CLJS.N,zX(),a)};BX=function(a){return $CLJS.Ce($CLJS.Wf.j($CLJS.N,$CLJS.Xj.g(zX(),$CLJS.Yj.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.ki)})),a))};
DX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.cf.h($CLJS.CX),c);a=$CLJS.E.g(a,$CLJS.JE)&&$CLJS.B(c)?$CLJS.Zd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Ce(BX(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mL,a,b],null):a};Zna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.cf.h(function(b){return $CLJS.uz(b,$CLJS.wA)}),$CLJS.cf.h($CLJS.CX)),$CLJS.uV.h(a))};
EX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.kK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.R;var f=$CLJS.CX.h(c);d=$CLJS.n(d)?$CLJS.T.j(f,$CLJS.BP,d):f;d=$CLJS.n(e)?$CLJS.T.j(d,$CLJS.LN,Zna(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.gU.h(c)],null)},null,a));return $CLJS.n($CLJS.cP.h(a))?$CLJS.rU(a,new $CLJS.h(null,1,[$CLJS.cP,$CLJS.wE],null)):a};
FX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.MV(a,b,$CLJS.Xj.g($CLJS.CX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.MV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.er],null),$CLJS.cf.h($CLJS.CX),e)}):d;return $CLJS.rU(a,$CLJS.Be([b,c]))};$na=function(a){var b=$CLJS.GU.h(a);return $CLJS.n(b)?$CLJS.T.j($CLJS.Qj.g(a,$CLJS.GU),$CLJS.ZN,["card__",$CLJS.p.h(b)].join("")):a};
aoa=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);boa=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);GX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Una=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);HX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
IX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.JX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);coa=new $CLJS.M(null,"m","m",1632677161);KX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var yX,doa,eoa,foa,goa,hoa,ioa,joa,koa,loa,moa;$CLJS.LX=$CLJS.N;$CLJS.MX=$CLJS.N;yX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.aV,null,$CLJS.ID,null,$CLJS.vM,null,$CLJS.TO,null,$CLJS.PE,null,$CLJS.HQ,null,$CLJS.QE,null],null),null);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.Se($CLJS.N);foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.NX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.OA,hoa,doa,eoa,foa,goa);$CLJS.NX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.td(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.hV($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.NX),d))}else b=a;return b});$CLJS.NX.m(null,$CLJS.$U,function(a){return a});
$CLJS.NX.m(null,$CLJS.NU,function(a){var b=$CLJS.NX.h($CLJS.QE.h(a)),c=$CLJS.Ce($CLJS.Zj.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.rV($CLJS.NX.h(t),u)},$CLJS.TO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.pD);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.pD);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.MX,k=$CLJS.LX;$CLJS.MX=d;$CLJS.LX=e;try{var l=Rna(Yna(a),b,$CLJS.H([$CLJS.TO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ta($CLJS.J.g(t,u))?t:$CLJS.ak.j(t,u,$CLJS.NX)},l,$CLJS.Rj.l(yX,$CLJS.QE,$CLJS.H([$CLJS.TO])));return $CLJS.n($CLJS.vM.h(m))?$CLJS.ak.j(m,$CLJS.vM,Xna):m}finally{$CLJS.LX=k,$CLJS.MX=f}});$CLJS.NX.m(null,$CLJS.UU,function(a){return Sna(a,function(b){return $CLJS.MV(b,$CLJS.tE,$CLJS.NX)})});
$CLJS.NX.m(null,$CLJS.fK,function(a){a=$CLJS.ak.j($CLJS.ak.j(a,$CLJS.nK,$CLJS.NX),$CLJS.kK,$CLJS.NX);var b=$CLJS.n($CLJS.ID.h(a))?$CLJS.ak.j(a,$CLJS.ID,function(c){return $CLJS.el(c)?$CLJS.Zj.g($CLJS.NX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ta($CLJS.MD.h(a))?$CLJS.T.j(b,$CLJS.MD,"__join"):b});$CLJS.NX.m(null,$CLJS.dA,function(a){return $CLJS.Zj.g($CLJS.NX,a)});
$CLJS.NX.m(null,$CLJS.fA,function(a){return $CLJS.n($CLJS.Ni.h(a))?Wna($CLJS.T.j($CLJS.ak.j($CLJS.vV(a),$CLJS.kK,function(b){return $CLJS.Zj.g($CLJS.NX,b)}),$CLJS.JX,!0)):$CLJS.$r(a,$CLJS.NX)});$CLJS.NX.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.td(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.hV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,a,b],null))});
$CLJS.NX.m(null,$CLJS.aj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.rU(a,new $CLJS.h(null,3,[$CLJS.UB,$CLJS.jA,$CLJS.xN,$CLJS.mj,$CLJS.PQ,$CLJS.jD],null));var c=$CLJS.T.j;var d=$CLJS.ki.h(a);$CLJS.n(d)||(d=$CLJS.jA.h(a),d=$CLJS.n(d)?d:$CLJS.DD(b));a=c.call($CLJS.T,a,$CLJS.ki,d);return $CLJS.hV(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,a,b],null))});
$CLJS.NX.m(null,$CLJS.JE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JE,$CLJS.Qj.g(c,$CLJS.Oh),$CLJS.Zj.g($CLJS.NX,b)],null);b=$CLJS.hV(b);return null!=a?$CLJS.Zd.g(b,$CLJS.NX.h(a)):b});$CLJS.NX.m(null,$CLJS.gA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.hV(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.NX.m(null,$CLJS.QE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.R;var f=$CLJS.J.g($CLJS.MX,c);if(!$CLJS.n(f))throw $CLJS.Th(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.SQ,a],null));return $CLJS.hV(new $CLJS.Q(null,3,5,e,[b,d,f],null))});
$CLJS.NX.m(null,$CLJS.mL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.NX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.pk.l($CLJS.H([c,a]))],null),d)});ioa=$CLJS.Se($CLJS.N);joa=$CLJS.Se($CLJS.N);koa=$CLJS.Se($CLJS.N);loa=$CLJS.Se($CLJS.N);moa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.CX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.OA,moa,ioa,joa,koa,loa);
$CLJS.CX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.td(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.CX,d);a=BX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.cf.g($CLJS.CX,$CLJS.be(a,d)))}else a=$CLJS.td(a)?$CLJS.$r(AX(a),$CLJS.CX):
a;return a});for(var OX=$CLJS.y(new $CLJS.Q(null,2,5,$CLJS.R,[KX,IX],null)),PX=null,QX=0,RX=0;;)if(RX<QX){var noa=PX.X(null,RX);$CLJS.gE(noa,HX);RX+=1}else{var SX=$CLJS.y(OX);if(SX){var TX=SX;if($CLJS.wd(TX)){var UX=$CLJS.jc(TX),ooa=$CLJS.kc(TX),poa=UX,qoa=$CLJS.D(UX);OX=ooa;PX=poa;QX=qoa}else{var roa=$CLJS.z(TX);$CLJS.gE(roa,HX);OX=$CLJS.B(TX);PX=null;QX=0}RX=0}else break}
for(var VX=$CLJS.y(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Jw,$CLJS.VE,$CLJS.jF,$CLJS.FE,$CLJS.vj,$CLJS.nF,$CLJS.bn,$CLJS.ME,$CLJS.hF,$CLJS.oF,$CLJS.mF,$CLJS.tF],null)),WX=null,XX=0,YX=0;;)if(YX<XX){var soa=WX.X(null,YX);$CLJS.gE(soa,KX);YX+=1}else{var ZX=$CLJS.y(VX);if(ZX){var $X=ZX;if($CLJS.wd($X)){var aY=$CLJS.jc($X),toa=$CLJS.kc($X),uoa=aY,voa=$CLJS.D(aY);VX=toa;WX=uoa;XX=voa}else{var woa=$CLJS.z($X);$CLJS.gE(woa,KX);VX=$CLJS.B($X);WX=null;XX=0}YX=0}else break}
for(var bY=$CLJS.y($CLJS.tf([$CLJS.Dq,$CLJS.Sr,$CLJS.Eq,$CLJS.uF,$CLJS.JE,$CLJS.dF,$CLJS.AE,$CLJS.wF,$CLJS.rE,$CLJS.OE,$CLJS.vE,$CLJS.ZE,$CLJS.fF,$CLJS.xE,$CLJS.QA,$CLJS.RA,$CLJS.xx,$CLJS.OH,$CLJS.TH,$CLJS.IH,$CLJS.SA,$CLJS.NH,$CLJS.GH,$CLJS.WH,$CLJS.aI,$CLJS.YH,$CLJS.MH,$CLJS.cI,$CLJS.TA,$CLJS.WA,$CLJS.zE,$CLJS.sE,$CLJS.xF,$CLJS.OG,$CLJS.cF,$CLJS.Hw,$CLJS.pF,$CLJS.rF,$CLJS.yF,$CLJS.cs,$CLJS.is],!0)),cY=null,dY=0,eY=0;;)if(eY<dY){var xoa=cY.X(null,eY);$CLJS.gE(xoa,IX);eY+=1}else{var fY=$CLJS.y(bY);
if(fY){var gY=fY;if($CLJS.wd(gY)){var hY=$CLJS.jc(gY),yoa=$CLJS.kc(gY),zoa=hY,Aoa=$CLJS.D(hY);bY=yoa;cY=zoa;dY=Aoa}else{var Boa=$CLJS.z(gY);$CLJS.gE(Boa,IX);bY=$CLJS.B(gY);cY=null;dY=0}eY=0}else break}$CLJS.CX.m(null,HX,function(a){return DX(a)});$CLJS.CX.m(null,$CLJS.fA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Xj.g(zX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.CX.h(b)],null)})),a)});
$CLJS.CX.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.td(b))try{var d=BX(b);b=$CLJS.R;var e=$CLJS.LX,f=$CLJS.J.j(e,c,GX);if($CLJS.E.g(f,GX))throw $CLJS.Th(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[coa,e,$CLJS.Nv,c],null));var k=new $CLJS.Q(null,2,5,b,[$CLJS.QE,f],null);return $CLJS.n(d)?$CLJS.Zd.g(k,d):k}catch(l){throw c=l,d=$CLJS.lU(c),d=$CLJS.ia.g?$CLJS.ia.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ia.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Sh(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.CX.m(null,$CLJS.dA,function(a){return $CLJS.Zj.g($CLJS.CX,a)});
$CLJS.CX.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.td(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aF,$CLJS.CX.h(a),BX(b)],null)});
$CLJS.CX.m(null,$CLJS.aj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=BX($CLJS.rU(b,new $CLJS.h(null,3,[$CLJS.jA,$CLJS.UB,$CLJS.mj,$CLJS.xN,$CLJS.jD,$CLJS.PQ],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,a,b],null)});
$CLJS.CX.m(null,$CLJS.fK,function(a){var b=AX(a);a=0==$CLJS.MD.h(a).lastIndexOf("__join",0)?$CLJS.Qj.g(b,$CLJS.MD):b;return $CLJS.pk.l($CLJS.H([$CLJS.$r($CLJS.Qj.l(a,$CLJS.kK,$CLJS.H([$CLJS.nK])),$CLJS.CX),FX($CLJS.xl(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.nK],null)),$CLJS.nK,$CLJS.JQ),EX(a)]))});
$CLJS.CX.m(null,$CLJS.NU,function(a){var b=$CLJS.QE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.pD);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.pD);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.MX,e=$CLJS.LX;$CLJS.MX=c;$CLJS.LX=b;try{return $CLJS.cb(function(f,k){return $CLJS.MV(f,
k,$CLJS.CX)},FX($CLJS.MV($CLJS.MV($na(AX(a)),$CLJS.QE,function(f){return $CLJS.Zj.g(DX,f)}),$CLJS.TO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.je(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.jc(t),v=$CLJS.D(u),x=$CLJS.me(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.CX.h(C);C=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qV(C),$CLJS.E.g($CLJS.aj,$CLJS.z(G))?$CLJS.ed(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.pe($CLJS.re(x),
m($CLJS.kc(t))):$CLJS.pe($CLJS.re(x),null)}x=$CLJS.z(t);u=$CLJS.CX.h(x);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qV(x),$CLJS.E.g($CLJS.aj,$CLJS.z(u))?$CLJS.ed(u):u],null),m($CLJS.Ic(t)))}return null}},null,null)}(f)}())}),$CLJS.aV,$CLJS.EE),$CLJS.Rj.l(yX,$CLJS.QE,$CLJS.H([$CLJS.aV,$CLJS.TO])))}finally{$CLJS.LX=e,$CLJS.MX=d}});$CLJS.CX.m(null,$CLJS.UU,function(a){return $CLJS.$r(AX(a),$CLJS.CX)});
$CLJS.CX.m(null,$CLJS.$U,function(a){try{var b=AX(a),c=$CLJS.mM.h(b),d=EX(b),e=$CLJS.E.g($CLJS.Os.h($CLJS.fd($CLJS.kK.h(a))),$CLJS.UU)?$CLJS.cP:$CLJS.wE;return $CLJS.pk.l($CLJS.H([$CLJS.$r($CLJS.Qj.l(b,$CLJS.kK,$CLJS.H([$CLJS.mM,$CLJS.JX])),$CLJS.CX),function(){var k=$CLJS.Be([$CLJS.Ni,e,e,d]);return $CLJS.y(c)?$CLJS.T.j(k,$CLJS.mM,c):k}()]))}catch(k){var f=k;throw $CLJS.Sh(function(){var l=$CLJS.lU(f);return $CLJS.ia.g?$CLJS.ia.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ia.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.wE,a],null),f);}});
$CLJS.iY=function(){function a(d,e,f){f=$CLJS.uX(null,$CLJS.Dy(f,$CLJS.H([$CLJS.fi,!0])));var k=$CLJS.xV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.QE);k=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.pD);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.zl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.pD);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.MX,t=$CLJS.LX;$CLJS.MX=k;$CLJS.LX=l;try{try{return $CLJS.NX.h(f)}catch(v){var u=v;throw $CLJS.Sh(function(){var x=$CLJS.lU(u);return $CLJS.ia.g?$CLJS.ia.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ia.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.wE,d,$CLJS.xK,e,boa,f,aoa,$CLJS.MX],null),u);}}finally{$CLJS.LX=t,$CLJS.MX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var JK,KK,LK,oja,pja,PK,qja,SK,TK,UK,VK,rja,WK,XK,ZK,$K,MK,aL,sja,bL,tja,cL,dL,eL,uja,fL,gL,vja,hL,wja,iL,jL,xja,lL,yja,zja,Aja,nL,oL,pL,qL,rL,sL,tL,uL,Bja,vL,wL,xL,zL,AL,Cja,BL,CL,DL,EL,Dja,FL,GL,HL,IL,Eja,JL,KL,LL,ML,Fja,NL,Gja,OL,PL,QL,Hja,RL,SL,TL,UL,WL,XL,Ija,YL,ZL,Jja,$L,aM,bM,cM,dM,Kja,eM,Lja,fM,gM,iM,Mja,jM,kM,lM,Nja,oM,pM,Oja,Pja,qM,sM,tM,uM,Qja,xM,yM,Rja,Sja,Tja,zM,AM,BM,CM,DM,EM,Uja,FM,Vja,GM,HM,JM,LM,MM,NM,OM,Wja,Xja,QM,Yja,Zja,RM,SM,TM,UM,VM,$ja,WM,aka,bka,XM,YM,ZM,$M,aN,
cka,dka,eka,bN,fka,cN,gka,eN,fN,hka,hN,iN,lN,mN,ika,pN,jka,kka,qN,rN,sN,lka,tN,uN,vN,wN,mka,yN,zN,AN,BN,CN,nka,DN,EN,oka,FN,GN,HN,IN,JN,KN,MN,pka,NN,qka,ON,PN,QN,rka,ska,tka,SN,TN,UN,uka,VN,WN,XN,YN,vka,wka,$N,xka,yka,aO,bO,cO,dO,eO,zka,fO,gO,hO,iO,Aka,jO,kO,lO,mO,oO,Bka,pO,qO,Cka,rO,sO,tO,Dka,uO,Eka,wO,Fka,xO,yO,zO,AO,BO,CO,DO,Gka,Hka,Ika,EO,Jka,FO,GO,Kka,Lka,HO,IO,JO,Mka,KO,Nka,LO,Oka,Pka,MO,Qka,Rka,Ska,PO,QO,Tka,RO,SO,UO,Uka,VO,Vka,WO,XO,YO,Wka,Xka,ZO,$O,aP,bP,Yka,dP,eP,Zka,fP,gP,hP,iP,jP,kP,lP,
mP,$ka,nP,oP,ala,pP,bla,qP,rP,sP,cla,tP,uP,dla,ela,vP,wP,xP,fla,gla,yP,hla,ila,jla,AP,kla,CP,lla,mla,DP,EP,nla,ola,pla,FP,GP,HP,IP,JP,KP,LP,qla,MP,NP,rla,OP,sla,tla,ula,PP,QP,RP,vla,SP,TP,wla,UP,xla,VP,yla,zla,XP,YP,ZP,$P,aQ,Ala,bQ,cQ,Bla,dQ,eQ,fQ,gQ,hQ,iQ,jQ,kQ,Cla,lQ,mQ,oQ,pQ,qQ,rQ,sQ,tQ,uQ,vQ,wQ,xQ,Dla,yQ,zQ,AQ,BQ,CQ,Ela,DQ,EQ,FQ,GQ,Fla,IQ,Gla,Hla,KQ,Ila,LQ,Jla,MQ,Kla,Lla,NQ,Mla,OQ,QQ,RQ,Nla,Ola,VQ,WQ,Pla,Qla,XQ,YQ,ZQ,$Q,aR,Rla,bR,cR,eR,fR,Sla;
$CLJS.IK=function(a){var b=$CLJS.wn.g(a,null),c=$CLJS.cE(b,$CLJS.ur,function(d){return $CLJS.Bm(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Kk,b,$CLJS.aj,k,$CLJS.Ar,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
JK=function(a,b){return $CLJS.td(a)?$CLJS.J.g(a,b):a};KK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Em($CLJS.wn.g($CLJS.Kk.h(a),null));if($CLJS.n(b)){var e=JK($CLJS.xs.h(b),c);$CLJS.n(e)?(e=$CLJS.up(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:JK($CLJS.ms.h(b),c)}return null};LK=function(a,b){return $CLJS.rd(a)||$CLJS.bl(a)?$CLJS.J.g(a,b):$CLJS.sd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
oja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Kk),d=$CLJS.J.g(a,$CLJS.Ni);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Ar,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.ys),l=$CLJS.J.j(b,$CLJS.vs,$CLJS.ts),m=KK(a,$CLJS.wp.h(c),k,b);if($CLJS.n(m))return m;m=KK(a,$CLJS.Mr.h(c),k,b);if($CLJS.n(m))return m;m=KK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=KK(a,function(){var t=$CLJS.hn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=KK(a,$CLJS.wp.h(c),
l,b);if($CLJS.n(m))return m;m=KK(a,$CLJS.Mr.h(c),l,b);if($CLJS.n(m))return m;d=KK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=KK(a,function(){var t=$CLJS.hn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?KK(a,e.h?e.h($CLJS.rs):e.call(null,$CLJS.rs),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?KK(a,e.h?e.h($CLJS.rs):e.call(null,$CLJS.rs),l,b):f};
pja=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.Hk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Kk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.ys);l=$CLJS.J.j(l,$CLJS.vs,$CLJS.ts);k=$CLJS.wp.h(k);f=JK($CLJS.us.h(k),f);f=$CLJS.n(f)?f:JK($CLJS.us.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,oja(b,c)],null)};
$CLJS.NK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.aj);a=$CLJS.J.g(b,$CLJS.Ar);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.ui),f=$CLJS.J.j(d,$CLJS.jda,pja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.T.j(l,$CLJS.ui,m);l=e.h?e.h(l):e.call(null,l);return MK(k,c,t,l)},null,a):null};
PK=function(a,b,c){var d=$CLJS.rz($CLJS.q($CLJS.PC),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.PC,$CLJS.OK,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.QK=function(a){return PK($CLJS.Fj,a,function(){return $CLJS.dE.h(a)})};qja=function(){var a=RK;return PK($CLJS.ur,a,function(){var b=$CLJS.dE.h(a),c=$CLJS.IK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
SK=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kk,$CLJS.ie(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,a],null):a],null)};TK=function(a){if($CLJS.vd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,SK(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Eq,SK(c)],null);default:return SK(a)}}else return SK(a)};
UK=function(a,b){var c=$CLJS.vd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
VK=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(UK,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qq,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,a],null)],null)],null),function(){return function e(d){return new $CLJS.je(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.jc(f),l=$CLJS.D(k),m=$CLJS.me(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,TK(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.pe($CLJS.re(m),e($CLJS.kc(f))):$CLJS.pe($CLJS.re(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[m,TK(k)],null),e($CLJS.Ic(f)))}return null}},null,null)}($CLJS.Pr(2,2,b))}())],null)};rja=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
WK=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.si,rja,$CLJS.ms,["valid instance of one of these MBQL clauses: ",$CLJS.as(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.je(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.jc(e),k=$CLJS.D(f),l=$CLJS.me(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ie(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.pe($CLJS.re(l),d($CLJS.kc(e))):$CLJS.pe($CLJS.re(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.be(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ie(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,f],null):f],null),d($CLJS.Ic(e)))}return null}},null,null)}(a)}())};
XK=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HK],null)],null)};$CLJS.YK={};ZK={};$K={};$CLJS.OK=function OK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.T.j;var k=$CLJS.J.g(a,b);c=OK.j?OK.j(k,f,c):OK.call(null,k,f,c);a=e.call($CLJS.T,a,b,c)}else a=$CLJS.T.j(a,b,c);return a};
MK=function MK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=LK(b,c);f=$CLJS.n(a)?a:$CLJS.sd(b)?$CLJS.xf:$CLJS.ud(b)?$CLJS.N:$CLJS.gd(b);return $CLJS.n($CLJS.n(c)?$CLJS.ss.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=LK(f,c),d=MK.v?MK.v(b,l,k,d):MK.call(null,b,l,k,d),$CLJS.gl(c)&&$CLJS.sd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.zd(f)?b:$CLJS.Wf.g($CLJS.gd(f),b)),null==f||$CLJS.bl(f)?$CLJS.T.j(f,c,d):$CLJS.rd(f)?$CLJS.Zd.g(f,d):$CLJS.P.g($CLJS.V,$CLJS.T.j($CLJS.yf(f),
c,d))):$CLJS.td(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ida],null),MK.v?MK.v(f,b,c,d):MK.call(null,f,b,c,d)):$CLJS.n($CLJS.ss.h($CLJS.ld(f)))?$CLJS.Zd.g(f,d):$CLJS.vd($CLJS.Ce(f))?f:$CLJS.kd(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.ss,!0],null))};aL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);sja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
bL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);tja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);cL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);dL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);eL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
uja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);fL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);gL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);vja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);hL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
wja=new $CLJS.M(null,"from","from",1815293044);iL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);jL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);xja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.kL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);lL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
yja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);zja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.mL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Aja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);nL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
oL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);pL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);qL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);rL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);sL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
tL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);uL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Bja=new $CLJS.M(null,"lon-max","lon-max",1590224717);vL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);wL=new $CLJS.r(null,"stddev","stddev",775056588,null);xL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.yL=new $CLJS.M(null,"snippet","snippet",953581994);zL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);AL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Cja=new $CLJS.M(null,"lat-field","lat-field",-830652957);BL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);CL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
DL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);EL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Dja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);FL=new $CLJS.M("location","country","location/country",1666636202);GL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
HL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);IL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Eja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);JL=new $CLJS.M(null,"unary","unary",-989314568);KL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
LL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);ML=new $CLJS.r(null,"ceil","ceil",-184398425,null);Fja=new $CLJS.M(null,"lon-min","lon-min",-787291357);NL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Gja=new $CLJS.M(null,"match","match",1220059550);OL=new $CLJS.r(null,"count-where","count-where",2025939247,null);PL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
QL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Hja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);RL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);SL=new $CLJS.r(null,"sum","sum",1777518341,null);TL=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);UL=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.VL=new $CLJS.M("date","range","date/range",1647265776);WL=new $CLJS.r(null,"between","between",-1523336493,null);XL=new $CLJS.M(null,"clause-form","clause-form",1820463737);Ija=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);YL=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);ZL=new $CLJS.r(null,"field","field",338095027,null);Jja=new $CLJS.M(null,"segment-id","segment-id",1810133590);
$L=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);aM=new $CLJS.r(null,"not-null","not-null",313812992,null);bM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);cM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);dM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Kja=new $CLJS.M(null,"template-tag","template-tag",310841038);
eM=new $CLJS.M(null,"invalid","invalid",412869516);Lja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);fM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);gM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.hM=new $CLJS.M(null,"context","context",-830191113);iM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Mja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);jM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);kM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);lM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.mM=new $CLJS.M(null,"parameters","parameters",-1229919748);Nja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.nM=new $CLJS.M(null,"card","card",-1430355152);oM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);pM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Oja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Pja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);qM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.rM=new $CLJS.M("date","month-year","date/month-year",1948031290);
sM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);tM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);uM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.vM=new $CLJS.M(null,"joins","joins",1033962699);Qja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.wM=new $CLJS.M(null,"source-field","source-field",933829534);xM=new $CLJS.r(null,"Field","Field",430385967,null);
yM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Rja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Sja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Tja=new $CLJS.M(null,"items","items",1031954938);zM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);AM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
BM=new $CLJS.M(null,"more","more",-2058821800);CM=new $CLJS.M(null,"first-clause","first-clause",-20953491);DM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);EM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Uja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);FM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Vja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);GM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);HM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.IM=new $CLJS.M(null,"widget-type","widget-type",1836256899);JM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.KM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
LM=new $CLJS.r(null,"is-null","is-null",-356519403,null);MM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);NM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);OM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Wja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.PM=new $CLJS.M(null,"required","required",1807647006);Xja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
QM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Yja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Zja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);RM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);SM=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);TM=new $CLJS.M("string","contains","string/contains",1602423827);UM=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
VM=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);$ja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);WM=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);aka=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);bka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
XM=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);YM=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);ZM=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);$M=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);aN=new $CLJS.r(null,"share","share",1051097594,null);
cka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);dka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);eka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);bN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);fka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
cN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);gka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.dN=new $CLJS.M(null,"collection","collection",-683361892);eN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);fN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.gN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);hka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);hN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);iN=new $CLJS.r(null,"metric","metric",2049329604,null);lN=new $CLJS.r(null,"concat","concat",-467652465,null);mN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.nN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
ika=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.oN=new $CLJS.M("date","relative","date/relative",25987732);pN=new $CLJS.M("location","city","location/city",-1746973325);jka=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);kka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);qN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);rN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
sN=new $CLJS.M("number","between","number/between",97700581);lka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);tN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);uN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);vN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);wN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.xN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);mka=new $CLJS.M(null,"metric-id","metric-id",-686486942);yN=new $CLJS.r(null,"*","*",345799209,null);zN=new $CLJS.r(null,"+","+",-740910886,null);AN=new $CLJS.r(null,"-","-",-471816912,null);BN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);CN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);nka=new $CLJS.M(null,"question","question",-1411720117);DN=new $CLJS.r(null,"asc","asc",1997386096,null);
EN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);oka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);FN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);GN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);HN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);IN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);JN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
KN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.LN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);MN=new $CLJS.M("string","ends-with","string/ends-with",302681156);pka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);NN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);qka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
ON=new $CLJS.r(null,"and","and",668631710,null);PN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);QN=new $CLJS.r(null,"round","round",-645002441,null);rka=new $CLJS.M(null,"to","to",192099007);$CLJS.RN=new $CLJS.M("date","single","date/single",1554682003);ska=new $CLJS.M(null,"action-id","action-id",-1727958578);tka=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);SN=new $CLJS.r(null,"exp","exp",1378825265,null);
TN=new $CLJS.r(null,"Filter","Filter",-424893332,null);UN=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);uka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);VN=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);WN=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);XN=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
YN=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.ZN=new $CLJS.M(null,"source-table","source-table",-225307692);vka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);wka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$N=new $CLJS.r(null,"floor","floor",-772394748,null);xka=new $CLJS.M(null,"middleware","middleware",1462115504);
yka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);aO=new $CLJS.M(null,"requires-features","requires-features",-101116256);bO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);cO=new $CLJS.M(null,"clause-name","clause-name",-996419059);dO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);eO=new $CLJS.r(null,"now","now",-9994004,null);
zka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);fO=new $CLJS.r(null,"not","not",1044554643,null);gO=new $CLJS.r(null,"avg","avg",1837937727,null);hO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);iO=new $CLJS.M(null,"max-results","max-results",-32858165);Aka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);jO=new $CLJS.r(null,"case","case",-1510733573,null);
kO=new $CLJS.r(null,"distinct","distinct",-148347594,null);lO=new $CLJS.r(null,"get-second","get-second",-425414791,null);mO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.nO=new $CLJS.M(null,"join-alias","join-alias",1454206794);oO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Bka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
pO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);qO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Cka=new $CLJS.M(null,"original","original",-445386197);rO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);sO=new $CLJS.r(null,"abs","abs",1394505050,null);tO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Dka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
uO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Eka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.vO=new $CLJS.M(null,"date","date",-1463434462);wO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Fka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);xO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
yO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);zO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);AO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);BO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);CO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);DO=new $CLJS.r(null,"or","or",1876275696,null);
Gka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Hka=new $CLJS.M(null,"constraints","constraints",422775616);Ika=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);EO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Jka=new $CLJS.M(null,"csv-download","csv-download",2141432084);FO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
GO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Kka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Lka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);HO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);IO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
JO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Mka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);KO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Nka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);LO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Oka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Pka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);MO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Qka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Rka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Ska=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.NO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.OO=new $CLJS.M(null,"database","database",1849087575);PO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);QO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Tka=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);RO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
SO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.TO=new $CLJS.M(null,"expressions","expressions",255689909);UO=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Uka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);VO=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Vka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);WO=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);XO=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);YO=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Wka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Xka=new $CLJS.M(null,"action","action",-811238024);
ZO=new $CLJS.r(null,"get-day","get-day",1768100384,null);$O=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);aP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);bP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.cP=new $CLJS.M(null,"native","native",-613060878);Yka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);dP=new $CLJS.M(null,"page","page",849072397);
eP=new $CLJS.r(null,"length","length",-2065447907,null);Zka=new $CLJS.M(null,"dashboard","dashboard",-631747508);fP=new $CLJS.r(null,"get-week","get-week",752472178,null);gP=new $CLJS.r(null,"get-month","get-month",1271156796,null);hP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);iP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);jP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
kP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);lP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);mP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);$ka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);nP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);oP=new $CLJS.r(null,"substring","substring",-1513569493,null);
ala=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);pP=new $CLJS.M(null,"internal","internal",-854870097);bla=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);qP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);rP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
sP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);cla=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);tP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);uP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);dla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
ela=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);vP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);wP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);xP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
fla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);gla=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);yP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.zP=new $CLJS.M(null,"template-tags","template-tags",1853115685);hla=new $CLJS.M(null,"public-question","public-question",629369976);ila=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
jla=new $CLJS.M(null,"binary","binary",-1802232288);AP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);kla=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.BP=new $CLJS.M(null,"source-query","source-query",198004422);CP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);lla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
mla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);DP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);EP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);nla=new $CLJS.M(null,"executed-by","executed-by",-739811161);ola=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
pla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);FP=new $CLJS.M(null,"amount","amount",364489504);GP=new $CLJS.r(null,"percentile","percentile",1039342775,null);HP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);IP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);JP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
KP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);LP=new $CLJS.r(null,"trim","trim",-1880116002,null);qla=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);MP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);NP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);rla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
OP=new $CLJS.M("string","\x3d","string/\x3d",983744235);sla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);tla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);ula=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);PP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);QP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
RP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);vla=new $CLJS.M(null,"lat-min","lat-min",1630784161);SP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);TP=new $CLJS.r(null,"inside","inside",-681932758,null);wla=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);UP=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
xla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);VP=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);yla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);zla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.WP=new $CLJS.M(null,"card-id","card-id",-1770060179);XP=new $CLJS.M(null,"variadic","variadic",882626057);
YP=new $CLJS.r(null,"upper","upper",1886775433,null);ZP=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);$P=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);aQ=new $CLJS.r(null,"optional","optional",-600484260,null);Ala=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);bQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
cQ=new $CLJS.M(null,"sugar","sugar",-73788488);Bla=new $CLJS.M(null,"lat-max","lat-max",841568226);dQ=new $CLJS.r(null,"power","power",702679448,null);eQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);fQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);gQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
hQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);iQ=new $CLJS.r(null,"median","median",-2084869638,null);jQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);kQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Cla=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);lQ=new $CLJS.M(null,"y","y",-1757859776);mQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.nQ=new $CLJS.M(null,"binning","binning",1709835866);oQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);pQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);qQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);rQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);sQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);tQ=new $CLJS.M(null,"b","b",1482224470);
uQ=new $CLJS.M(null,"a","a",-2123407586);vQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);wQ=new $CLJS.r(null,"replace","replace",853943757,null);xQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Dla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);yQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
zQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);AQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);BQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);CQ=new $CLJS.r(null,"segment","segment",675610331,null);Ela=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);DQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
EQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);FQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);GQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Fla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.HQ=new $CLJS.M(null,"order-by","order-by",1527318070);IQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Gla=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Hla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.JQ=new $CLJS.M(null,"condition","condition",1668437652);KQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Ila=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);LQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Jla=new $CLJS.M(null,"card-name","card-name",-2035606807);MQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Kla=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Lla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);NQ=new $CLJS.r(null,"log","log",45015523,null);Mla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
OQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.PQ=new $CLJS.M(null,"database_type","database_type",-54700895);QQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);RQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.SQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.TQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.UQ=new $CLJS.M("date","all-options","date/all-options",549325958);
Nla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Ola=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);VQ=new $CLJS.M("location","state","location/state",-114378652);WQ=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Pla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Qla=new $CLJS.M(null,"lon-field","lon-field",517872067);
XQ=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);YQ=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);ZQ=new $CLJS.M(null,"numeric","numeric",-1495594714);$Q=new $CLJS.r(null,"variable","variable",1359185035,null);aR=new $CLJS.r(null,"lower","lower",-1534114948,null);Rla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
bR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);cR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.dR=new $CLJS.M(null,"limit","limit",-1355822363);eR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);fR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Sla=new $CLJS.M(null,"pulse","pulse",-244494476);var gR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.hD],null),hR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.rD],null),iR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.dD],null),jR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$C],null),kR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.lD],null),lR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.aK],null),Tla=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.OJ],null),Ula=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HJ],null),mR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,
$CLJS.AH],null),nR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.EH],null),oR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.vH],null),pR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.$h,null,$CLJS.Fk,null,$CLJS.Qi,null,$CLJS.Oh,null,$CLJS.li,null,$CLJS.Wi,null,$CLJS.xj,null,$CLJS.cj,null,$CLJS.Vh,null,$CLJS.bj,null,$CLJS.ai,null,$CLJS.gi,null],null),null),qR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Ek,null,$CLJS.MF,null,$CLJS.Oh,null,$CLJS.vk,null,$CLJS.PF,null,$CLJS.Li,null,$CLJS.yk,null],null),null),
rR=$CLJS.Ps.g(pR,qR),Vla=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"date bucketing unit"],null)],null),pR),Wla=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"time bucketing unit"],null)],null),qR),sR=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"datetime bucketing unit"],null)],null),rR),tR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.$H],null),Xla=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,
1,[$CLJS.ms,"temporal extract unit"],null),$CLJS.QF,$CLJS.gi,$CLJS.xj,$CLJS.QH,$CLJS.dI,$CLJS.eI,$CLJS.cj,$CLJS.li,$CLJS.vk,$CLJS.Li,$CLJS.NF],null),Yla=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"datetime-diff unit"],null),$CLJS.MF,$CLJS.yk,$CLJS.Ek,$CLJS.Fk,$CLJS.Qi,$CLJS.Wi,$CLJS.$h,$CLJS.Vh],null),uR=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"temporal-extract week extraction mode"],null),$CLJS.KH,$CLJS.RH,$CLJS.VH],null),vR=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.yk,$CLJS.Ek,$CLJS.Fk,$CLJS.Qi,$CLJS.Wi,$CLJS.$h,$CLJS.Vh],null),CR,$la,KR,LR,MR,NR,OR,PR,QR,CT,bma,DT,cma,dma,ET,ema,fma,gma;$CLJS.X(LL,VK($CLJS.RA,$CLJS.H(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.Iu],null),$CLJS.jr],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,vR],null)])));var wR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,LL],null);
$CLJS.X(rO,VK($CLJS.QA,$CLJS.H(["n",$CLJS.jr,"unit",vR])));var Zla=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,rO],null);
$CLJS.X(fM,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"valid :absolute-datetime clause",$CLJS.si,function(a){if($CLJS.Ta(UK($CLJS.OH,a)))a=eM;else{a=$CLJS.ed(a);var b=$CLJS.QK(mR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.vO:$CLJS.SH}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[eM,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vO,VK($CLJS.OH,
$CLJS.H(["date",mR,"unit",Vla]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,VK($CLJS.OH,$CLJS.H(["datetime",nR,"unit",sR]))],null)],null));var xR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fM],null);$CLJS.X(uM,VK($CLJS.xx,$CLJS.H(["time",oR,"unit",Wla])));var yR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uM],null),zR=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"date or datetime literal"],null),xR,nR,mR],null);
$CLJS.X(fL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"time literal"],null),yR,oR],null));$CLJS.X(XQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"temporal literal"],null),zR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fL],null)],null));var AR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XQ],null);
$CLJS.X(rla,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof xR?new $CLJS.Dc(function(){return xR},$CLJS.kd(Rja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,pP,$CLJS.Oi,$CLJS.Hj,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",69,$CLJS.OH,1,!0,168,168,$CLJS.Hc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(xR)?xR.H:null])):null));return $CLJS.n(a)?a:YO}(),xR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof wR?new $CLJS.Dc(function(){return wR},$CLJS.kd(Nla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,
$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",29,$CLJS.RA,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.RA,$CLJS.Bj,$CLJS.V($CLJS.jj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.Iu],null),$CLJS.jr],null)),$CLJS.uk,$CLJS.V($CLJS.jj,$CLJS.V(aQ,NN))],null),$CLJS.Hc,"Schema for a valid relative-datetime clause.",
$CLJS.n(wR)?wR.H:null])):null));return $CLJS.n(a)?a:pO}(),wR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof yR?new $CLJS.Dc(function(){return yR},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,pP,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OQ,"metabase/mbql/schema.cljc",27,$CLJS.xx,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xx,$CLJS.xx,$CLJS.V($CLJS.jj,qla),$CLJS.uk,$CLJS.V($CLJS.jj,ika)],null),$CLJS.Hc,"Schema for a valid time clause.",$CLJS.n(yR)?yR.H:null])):null));return $CLJS.n(a)?a:OQ}(),yR],null)])));
$CLJS.X(MQ,VK($CLJS.aj,$CLJS.H(["value",$CLJS.pr,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,gR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UB,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,hR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Yq,iR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uk,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,sR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,gR],null)],null)],null)],null)])));var BR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,MQ],null);$CLJS.X(wN,VK($CLJS.gA,$CLJS.H(["expression-name",gR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,$CLJS.uj],null)])));
CR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,wN],null);
$la=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.tD);c=$CLJS.J.g(c,$CLJS.aD);return $CLJS.E.g(b,$CLJS.aD)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.tD);c=$CLJS.J.g(c,$CLJS.sD);return $CLJS.E.g(b,$CLJS.sD)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tD,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"binning strategy"],null),$CLJS.aD,$CLJS.sD,$CLJS.Oh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),jR],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.sD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,$CLJS.Uk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.ol)],null)],null)],null)],null)));$CLJS.DR=function DR(a){switch(arguments.length){case 1:return DR.h(arguments[0]);case 2:return DR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.DR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jA);b=$CLJS.J.g(b,$CLJS.gF);return $CLJS.DR.g(a,b)};$CLJS.DR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.tz(a,$CLJS.wk)?pR:$CLJS.tz(a,$CLJS.Gk)?qR:$CLJS.tz(a,$CLJS.Ak)?rR:null:null;return $CLJS.n(a)?$CLJS.Dd(a,b):!0};$CLJS.DR.A=2;
$CLJS.X(bQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jA,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,hR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gF,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,sR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,gR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nQ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$la],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.DR],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.tD)],null)],null));
$CLJS.X(DQ,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.jA);return"string"===typeof c?b:!0}],null)],null)}(VK($CLJS.aF,$CLJS.H(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,lR,gR],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bQ],null)],null)]))));var ER=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DQ],null);
$CLJS.X(EO,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof CR?new $CLJS.Dc(function(){return CR},$CLJS.kd(GM,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qM,"metabase/mbql/schema.cljc",60,$CLJS.gA,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gA,$CLJS.TQ,$CLJS.V($CLJS.jj,NL),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,$CLJS.uj))],null),$CLJS.Hc,"Schema for a valid expression clause.",$CLJS.n(CR)?CR.H:null])):null));return $CLJS.n(a)?a:qM}(),CR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof ER?new $CLJS.Dc(function(){return ER},
$CLJS.kd(YM,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Yp,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,$CLJS.bi,$CLJS.wj,$CLJS.zj],["0.39.0",$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZL,"metabase/mbql/schema.cljc",51,$CLJS.aF,1,382,382,$CLJS.Hc,"Schema for a `:field` clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:ZL}(),ER],null)])));
$CLJS.FR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EO],null);$CLJS.X(aL,VK($CLJS.QE,$CLJS.H(["aggregation-clause-index",$CLJS.jr,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,$CLJS.uj],null)])));var GR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,aL],null);
$CLJS.X(HL,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof GR?new $CLJS.Dc(function(){return GR},$CLJS.kd(ola,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",23,$CLJS.QE,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.QE,Pla,$CLJS.V($CLJS.jj,$CLJS.jr),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,$CLJS.uj))],null),$CLJS.Hc,"Schema for a valid aggregation clause.",$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:mQ}(),GR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof CR?new $CLJS.Dc(function(){return CR},
$CLJS.kd(GM,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qM,"metabase/mbql/schema.cljc",60,$CLJS.gA,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gA,$CLJS.TQ,$CLJS.V($CLJS.jj,NL),
$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,$CLJS.uj))],null),$CLJS.Hc,"Schema for a valid expression clause.",$CLJS.n(CR)?CR.H:null])):null));return $CLJS.n(a)?a:qM}(),CR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof ER?new $CLJS.Dc(function(){return ER},$CLJS.kd(YM,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Yp,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,
$CLJS.bi,$CLJS.wj,$CLJS.zj],["0.39.0",$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZL,"metabase/mbql/schema.cljc",51,$CLJS.aF,1,382,382,$CLJS.Hc,"Schema for a `:field` clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:ZL}(),ER],null)])));
var HR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HL],null),IR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.cs,null,$CLJS.rF,null,$CLJS.sE,null,$CLJS.xF,null,$CLJS.zE,null,$CLJS.dF,null,$CLJS.JE,null,$CLJS.is,null,$CLJS.pF,null,$CLJS.cF,null,$CLJS.yF,null],null),null),JR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EL],null);
$CLJS.X(hL,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,function(a){return"string"===typeof a?$CLJS.pj:$CLJS.n(UK(IR,a))?AL:$CLJS.n(UK($CLJS.aj,a))?$CLJS.aj:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[AL,JR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,$CLJS.FR],null)],null));KR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hL],null);
LR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.vE,null,$CLJS.cI,null,$CLJS.Eq,null,$CLJS.UA,null,$CLJS.YH,null,$CLJS.OE,null,$CLJS.Sr,null,$CLJS.rE,null,$CLJS.aI,null,$CLJS.SA,null,$CLJS.dF,null,$CLJS.uF,null,$CLJS.ZH,null,$CLJS.fF,null,$CLJS.xE,null,$CLJS.MH,null,$CLJS.Dq,null,$CLJS.AE,null,$CLJS.JE,null,$CLJS.GH,null,$CLJS.Hw,null,$CLJS.NH,null,$CLJS.WH,null,$CLJS.PA,null,$CLJS.wF,null,$CLJS.ZE,null],null),null);
MR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Bq,null,$CLJS.vq,null,$CLJS.Zq,null,$CLJS.ar,null,$CLJS.xq,null,$CLJS.qF,null,$CLJS.er,null,$CLJS.Cj,null,$CLJS.zq,null],null),null);NR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.bn,null,$CLJS.oF,null,$CLJS.jF,null,$CLJS.bF,null,$CLJS.tF,null,$CLJS.vF,null,$CLJS.FE,null,$CLJS.ME,null,$CLJS.mL,null,$CLJS.GE,null,$CLJS.nF,null,$CLJS.hF,null,$CLJS.vj,null,$CLJS.Jw,null,$CLJS.VE,null,$CLJS.WE,null,$CLJS.mF,null],null),null);
OR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.TH,null,$CLJS.IH,null,$CLJS.WA,null,$CLJS.Dq,null,$CLJS.TA,null],null),null);PR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,UP],null);QR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sM],null);$CLJS.RR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vP],null);$CLJS.SR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$P],null);
$CLJS.X(gQ,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"numeric expression argument",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Is:$CLJS.n(UK(LR,a))?YN:$CLJS.n(UK(NR,a))?$CLJS.QE:$CLJS.n(UK($CLJS.aj,a))?$CLJS.aj:$CLJS.aF}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,$CLJS.Uk],null),new $CLJS.Q(null,2,5,$CLJS.R,[YN,PR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,$CLJS.SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aF,
$CLJS.FR],null)],null));var TR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gQ],null);
$CLJS.X(qP,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"datetime expression argument",$CLJS.si,function(a){return $CLJS.n(UK(NR,a))?$CLJS.QE:$CLJS.n(UK($CLJS.aj,a))?$CLJS.aj:$CLJS.n(UK(OR,a))?zM:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QE,$CLJS.SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[zM,$CLJS.RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,zR,$CLJS.FR],null)],null)],null));
var UR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,qP],null);
$CLJS.X(UO,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"expression argument",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Is:$CLJS.yd(a)?$CLJS.or:$CLJS.n(UK(MR,a))?iP:$CLJS.n(UK(LR,a))?YN:$CLJS.n(UK(OR,a))?zM:"string"===typeof a?$CLJS.pj:$CLJS.n(UK(IR,a))?AL:$CLJS.n(UK($CLJS.aj,a))?$CLJS.aj:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,$CLJS.Uk],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.or,$CLJS.or],null),new $CLJS.Q(null,2,5,$CLJS.R,[iP,QR],null),new $CLJS.Q(null,
2,5,$CLJS.R,[YN,PR],null),new $CLJS.Q(null,2,5,$CLJS.R,[zM,$CLJS.RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,$CLJS.pj],null),new $CLJS.Q(null,2,5,$CLJS.R,[AL,JR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,$CLJS.FR],null)],null));var VR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,UO],null);$CLJS.X(SO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"numeric expression arg or interval"],null),Zla,TR],null));
var WR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,SO],null);$CLJS.X(sL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"int greater than zero or numeric expression",$CLJS.si,function(a){return"number"===typeof a?$CLJS.Is:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,jR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,PR],null)],null));var ama=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sL],null);
$CLJS.X(AM,VK($CLJS.dF,$CLJS.H(["a",VR,"b",VR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,VR],null)])));var YR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,AM],null);$CLJS.X(eL,VK($CLJS.sE,$CLJS.H(["s",KR,"start",ama,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,TR],null)])));var ZR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eL],null);$CLJS.X(jP,VK($CLJS.Hw,$CLJS.H(["s",KR])));var $R=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,jP],null);$CLJS.X(GL,VK($CLJS.pF,$CLJS.H(["s",KR])));
var aS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,GL],null);$CLJS.X(KL,VK($CLJS.yF,$CLJS.H(["s",KR])));var bS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,KL],null);$CLJS.X(cM,VK($CLJS.rF,$CLJS.H(["s",KR])));var cS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cM],null);$CLJS.X(OM,VK($CLJS.cs,$CLJS.H(["s",KR])));var dS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,OM],null);$CLJS.X(xL,VK($CLJS.is,$CLJS.H(["s",KR])));var eS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xL],null);
$CLJS.X(fQ,VK($CLJS.xF,$CLJS.H(["s",KR,"match",$CLJS.pj,"replacement",$CLJS.pj])));var fS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fQ],null);$CLJS.X(bR,VK($CLJS.zE,$CLJS.H(["a",KR,"b",KR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,KR],null)])));var gS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bR],null);$CLJS.X(FN,VK($CLJS.cF,$CLJS.H(["s",KR,"pattern",$CLJS.pj])));var hS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FN],null);
$CLJS.X(BQ,VK($CLJS.Dq,$CLJS.H(["x",WR,"y",WR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,WR],null)])));var iS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BQ],null);$CLJS.X(zQ,VK($CLJS.Sr,$CLJS.H(["x",TR,"y",WR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,WR],null)])));var jS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zQ],null);$CLJS.X(yQ,VK($CLJS.uF,$CLJS.H(["x",TR,"y",TR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,TR],null)])));var kS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,yQ],null);
$CLJS.X(AQ,VK($CLJS.Eq,$CLJS.H(["x",TR,"y",TR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,TR],null)])));var lS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,AQ],null);$CLJS.X(RO,VK($CLJS.ZE,$CLJS.H(["x",TR])));var mS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RO],null);$CLJS.X(JP,VK($CLJS.vE,$CLJS.H(["x",TR])));var nS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JP],null);$CLJS.X(eR,VK($CLJS.fF,$CLJS.H(["x",TR])));var oS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eR],null);$CLJS.X(WN,VK($CLJS.AE,$CLJS.H(["x",TR])));
var pS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WN],null);$CLJS.X(bO,VK($CLJS.xE,$CLJS.H(["x",TR,"y",TR])));var qS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bO],null);$CLJS.X(yO,VK($CLJS.OE,$CLJS.H(["x",TR])));var rS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,yO],null);$CLJS.X(tL,VK($CLJS.rE,$CLJS.H(["x",TR])));var sS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tL],null);$CLJS.X(XO,VK($CLJS.wF,$CLJS.H(["x",TR])));var tS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XO],null);
$CLJS.X(TL,VK($CLJS.PA,$CLJS.H(["datetime-x",UR,"datetime-y",UR,"unit",Yla])));var uS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,TL],null);$CLJS.X(kM,VK($CLJS.UA,$CLJS.H(["datetime",UR,"unit",Xla,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,uR],null)])));var vS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,kM],null);$CLJS.X(dO,VK($CLJS.NH,$CLJS.H(["date",UR])));var wS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dO],null);$CLJS.X(FO,VK($CLJS.cI,$CLJS.H(["date",UR])));
var xS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FO],null);$CLJS.X(gL,VK($CLJS.GH,$CLJS.H(["date",UR])));var yS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,gL],null);$CLJS.X(cR,VK($CLJS.SA,$CLJS.H(["date",UR,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,uR],null)])));var zS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cR],null);$CLJS.X(qN,VK($CLJS.WH,$CLJS.H(["date",UR])));var AS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,qN],null);$CLJS.X(zO,VK($CLJS.ZH,$CLJS.H(["date",UR])));
var BS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,zO],null);$CLJS.X(PL,VK($CLJS.aI,$CLJS.H(["datetime",UR])));var CS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,PL],null);$CLJS.X(vN,VK($CLJS.YH,$CLJS.H(["datetime",UR])));var DS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vN],null);$CLJS.X(IL,VK($CLJS.MH,$CLJS.H(["datetime",UR])));var ES=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,IL],null);$CLJS.X(ZM,VK($CLJS.IH,$CLJS.H(["datetime",UR,"to",tR,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,tR],null)])));
var FS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,ZM],null),GS=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"datetime arithmetic unit"],null),$CLJS.PF,$CLJS.MF,$CLJS.yk,$CLJS.Ek,$CLJS.Fk,$CLJS.Qi,$CLJS.Wi,$CLJS.$h,$CLJS.Vh],null);$CLJS.X(dL,VK($CLJS.TA,$CLJS.H(["datetime",UR,"amount",TR,"unit",GS])));var HS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dL],null);
$CLJS.X(BL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return VK(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.TH));var IS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BL],null);$CLJS.X(cL,VK($CLJS.WA,$CLJS.H(["datetime",UR,"amount",TR,"unit",GS])));var JS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cL],null);
$CLJS.X(vP,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof iS?new $CLJS.Dc(function(){return iS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",51,$CLJS.Dq,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Dq,$CLJS.qD,$CLJS.V($CLJS.jj,PO),lQ,$CLJS.V($CLJS.jj,PO),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,PO))],null),$CLJS.Hc,"Schema for a valid + clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:zN}(),iS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof HS?new $CLJS.Dc(function(){return HS},
$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SM,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",67,$CLJS.TA,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.TA,$CLJS.SH,$CLJS.V($CLJS.jj,YQ),FP,$CLJS.V($CLJS.jj,
KP),$CLJS.uk,$CLJS.V($CLJS.jj,SP)],null),$CLJS.Hc,"Schema for a valid datetime-add clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:GO}(),HS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof JS?new $CLJS.Dc(function(){return JS},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,
$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SM,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",72,$CLJS.WA,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.WA,$CLJS.SH,$CLJS.V($CLJS.jj,YQ),FP,$CLJS.V($CLJS.jj,KP),$CLJS.uk,$CLJS.V($CLJS.jj,SP)],null),$CLJS.Hc,"Schema for a valid datetime-subtract clause.",$CLJS.n(JS)?JS.H:null])):null));
return $CLJS.n(a)?a:WQ}(),JS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof FS?new $CLJS.Dc(function(){return FS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.IH,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yM,"metabase/mbql/schema.cljc",71,$CLJS.IH,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.IH,$CLJS.SH,$CLJS.V($CLJS.jj,YQ),rka,$CLJS.V($CLJS.jj,oQ),wja,$CLJS.V($CLJS.jj,$CLJS.V(aQ,oQ))],null),$CLJS.Hc,"Schema for a valid convert-timezone clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:yM}(),FS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&
"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof IS?new $CLJS.Dc(function(){return IS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TH,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eO,"metabase/mbql/schema.cljc",
45,$CLJS.TH,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TH],null),$CLJS.Hc,"Schema for a valid now clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:eO}(),IS],null)])));$CLJS.KS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,BO],null);$CLJS.X(iL,VK($CLJS.er,$CLJS.H(["first-clause",$CLJS.KS,"second-clause",$CLJS.KS,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,$CLJS.KS],null)])));var LS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,iL],null);
$CLJS.X(VM,VK($CLJS.Zq,$CLJS.H(["first-clause",$CLJS.KS,"second-clause",$CLJS.KS,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,$CLJS.KS],null)])));var MS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,VM],null);$CLJS.X(DL,VK($CLJS.ar,$CLJS.H(["clause",$CLJS.KS])));
var NS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DL],null),OS=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,3,[$CLJS.ms,":field or :expression reference or :relative-datetime",$CLJS.xs,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.si,function(a){return $CLJS.n(UK($CLJS.RA,a))?$CLJS.RA:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RA,wR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,$CLJS.FR],null)],null);
$CLJS.X(sP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.ms,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Zq,$CLJS.or,$CLJS.Uk,$CLJS.pj,AR,OS,VR,BR],null)],null));var PS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,sP],null);
$CLJS.X(KN,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"order comparable",$CLJS.si,function(a){return $CLJS.n(UK($CLJS.aj,a))?$CLJS.aj:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Zq,$CLJS.Uk,$CLJS.pj,AR,VR,OS],null)],null)],null));var QS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,KN],null);
$CLJS.X(rQ,VK($CLJS.Cj,$CLJS.H(["field",PS,"value-or-field",PS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,PS],null)])));var RS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,rQ],null);$CLJS.X(vQ,VK($CLJS.qF,$CLJS.H(["field",PS,"value-or-field",PS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,PS],null)])));var SS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vQ],null);$CLJS.X(qQ,VK($CLJS.zq,$CLJS.H(["field",QS,"value-or-field",QS])));
var TS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,qQ],null);$CLJS.X(pQ,VK($CLJS.vq,$CLJS.H(["field",QS,"value-or-field",QS])));var US=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,pQ],null);$CLJS.X(dM,VK($CLJS.Bq,$CLJS.H(["field",QS,"value-or-field",QS])));var VS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,dM],null);$CLJS.X(jM,VK($CLJS.xq,$CLJS.H(["field",QS,"value-or-field",QS])));var WS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,jM],null);$CLJS.X(aP,VK($CLJS.CE,$CLJS.H(["field",QS,"min",QS,"max",QS])));
var XS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,aP],null);$CLJS.X(CP,VK($CLJS.IE,$CLJS.H(["lat-field",QS,"lon-field",QS,"lat-max",QS,"lon-min",QS,"lat-min",QS,"lon-max",QS])));var YS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,CP],null);$CLJS.X(NM,VK($CLJS.BE,$CLJS.H(["field",$CLJS.FR])));var ZS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,NM],null);$CLJS.X(EN,VK($CLJS.NE,$CLJS.H(["field",$CLJS.FR])));var $S=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EN],null);$CLJS.X(eN,VK($CLJS.DE,$CLJS.H(["field",$CLJS.FR])));
var aT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,eN],null);$CLJS.X(lL,VK($CLJS.KE,$CLJS.H(["field",$CLJS.FR])));var bT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lL],null),cT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iF,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null);$CLJS.X(tN,VK($CLJS.SE,$CLJS.H(["field",KR,"string-or-field",KR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,cT],null)])));var dT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,tN],null);
$CLJS.X(mO,VK($CLJS.lF,$CLJS.H(["field",KR,"string-or-field",KR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,cT],null)])));var eT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,mO],null);$CLJS.X(cN,VK($CLJS.$E,$CLJS.H(["field",KR,"string-or-field",KR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,cT],null)])));var fT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,cN],null);$CLJS.X(VO,VK($CLJS.TE,$CLJS.H(["field",KR,"string-or-field",KR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,cT],null)])));
var gT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,VO],null);$CLJS.X(uL,VK($CLJS.VA,$CLJS.H(["field",$CLJS.FR,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.jr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wq,$CLJS.Iu,$CLJS.Nw,$CLJS.HE],null)],null),"unit",vR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null)],null)])));var hT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uL],null);
$CLJS.X(lP,VK($CLJS.yE,$CLJS.H(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.FJ],null),gR],null)])));var iT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lP],null);
$CLJS.X(sM,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof LS?new $CLJS.Dc(function(){return LS},$CLJS.kd(eQ,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ON,"metabase/mbql/schema.cljc",15,$CLJS.er,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.er,CM,$CLJS.V($CLJS.jj,TN),wO,$CLJS.V($CLJS.jj,TN),MM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,TN))],null),$CLJS.Hc,"Schema for a valid and clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:ON}(),LS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof MS?new $CLJS.Dc(function(){return MS},
$CLJS.kd(AO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",14,$CLJS.Zq,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zq,CM,$CLJS.V($CLJS.jj,TN),wO,$CLJS.V($CLJS.jj,TN),MM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,TN))],null),$CLJS.Hc,
"Schema for a valid or clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:DO}(),MS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof NS?new $CLJS.Dc(function(){return NS},$CLJS.kd(mP,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fO,"metabase/mbql/schema.cljc",15,$CLJS.ar,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.SQ,$CLJS.V($CLJS.jj,TN)],null),$CLJS.Hc,"Schema for a valid not clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:fO}(),NS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==
typeof TS?new $CLJS.Dc(function(){return TS},$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",13,$CLJS.zq,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],
null),$CLJS.Hc,"Schema for a valid \x3c clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:HN}(),TS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof VS?new $CLJS.Dc(function(){return VS},$CLJS.kd(LQ,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",14,$CLJS.Bq,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:GN}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&
"undefined"!==typeof $K&&"undefined"!==typeof US?new $CLJS.Dc(function(){return US},$CLJS.kd(HO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",13,$CLJS.vq,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.vq,$CLJS.aF,$CLJS.V($CLJS.jj,
EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3e clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:IN}(),US],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof WS?new $CLJS.Dc(function(){return WS},$CLJS.kd(EQ,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],
[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",14,$CLJS.xq,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:PN}(),WS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&
"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof RS?new $CLJS.Dc(function(){return RS},$CLJS.kd(IO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Cj,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,
[$CLJS.Cj,$CLJS.aF,$CLJS.V($CLJS.jj,QQ),QP,$CLJS.V($CLJS.jj,QQ),tP,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,QQ))],null),$CLJS.Hc,"Schema for a valid \x3d clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:JN}(),RS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof SS?new $CLJS.Dc(function(){return SS},$CLJS.kd(UL,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,
$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pM,"metabase/mbql/schema.cljc",14,$CLJS.qF,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qF,$CLJS.aF,$CLJS.V($CLJS.jj,QQ),QP,$CLJS.V($CLJS.jj,QQ),tP,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,QQ))],null),$CLJS.Hc,"Schema for a valid !\x3d clause.",$CLJS.n(SS)?SS.H:null])):null));
return $CLJS.n(a)?a:pM}(),SS],null)])));
$CLJS.X(BO,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"valid filter expression",$CLJS.si,function(a){return $CLJS.n(UK(OR,a))?$CLJS.SH:$CLJS.n(UK(LR,a))?ZQ:$CLJS.n(UK(IR,a))?$CLJS.pj:$CLJS.n(UK(MR,a))?$CLJS.or:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,$CLJS.RR],null),new $CLJS.Q(null,2,5,$CLJS.R,[ZQ,PR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,JR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.or,QR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,WK($CLJS.H([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof LS?new $CLJS.Dc(function(){return LS},$CLJS.kd(eQ,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",
15,$CLJS.er,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.er,CM,$CLJS.V($CLJS.jj,TN),wO,$CLJS.V($CLJS.jj,TN),MM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,TN))],null),$CLJS.Hc,"Schema for a valid and clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:ON}(),LS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof MS?new $CLJS.Dc(function(){return MS},$CLJS.kd(AO,new $CLJS.h(null,1,[$CLJS.qj,
!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",14,$CLJS.Zq,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zq,CM,$CLJS.V($CLJS.jj,TN),wO,$CLJS.V($CLJS.jj,TN),MM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,TN))],null),$CLJS.Hc,"Schema for a valid or clause.",$CLJS.n(MS)?
MS.H:null])):null));return $CLJS.n(a)?a:DO}(),MS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof NS?new $CLJS.Dc(function(){return NS},$CLJS.kd(mP,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fO,"metabase/mbql/schema.cljc",15,$CLJS.ar,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ar,$CLJS.SQ,$CLJS.V($CLJS.jj,TN)],null),$CLJS.Hc,"Schema for a valid not clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:fO}(),NS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof RS?new $CLJS.Dc(function(){return RS},$CLJS.kd(IO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,
$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",13,$CLJS.Cj,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Cj,$CLJS.aF,$CLJS.V($CLJS.jj,QQ),QP,$CLJS.V($CLJS.jj,QQ),tP,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,QQ))],null),$CLJS.Hc,"Schema for a valid \x3d clause.",$CLJS.n(RS)?RS.H:null])):
null));return $CLJS.n(a)?a:JN}(),RS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof SS?new $CLJS.Dc(function(){return SS},$CLJS.kd(UL,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pM,"metabase/mbql/schema.cljc",14,$CLJS.qF,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qF,$CLJS.aF,$CLJS.V($CLJS.jj,QQ),QP,$CLJS.V($CLJS.jj,QQ),tP,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,QQ))],null),$CLJS.Hc,"Schema for a valid !\x3d clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:pM}(),SS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof TS?new $CLJS.Dc(function(){return TS},
$CLJS.kd(JO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",13,$CLJS.zq,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3c clause.",
$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:HN}(),TS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof US?new $CLJS.Dc(function(){return US},$CLJS.kd(HO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",13,$CLJS.vq,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.vq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3e clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:IN}(),US],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof VS?new $CLJS.Dc(function(){return VS},$CLJS.kd(LQ,new $CLJS.h(null,
1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",14,$CLJS.Bq,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Bq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(VS)?VS.H:null])):
null));return $CLJS.n(a)?a:GN}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof WS?new $CLJS.Dc(function(){return WS},$CLJS.kd(EQ,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PN,"metabase/mbql/schema.cljc",14,$CLJS.xq,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xq,$CLJS.aF,$CLJS.V($CLJS.jj,EM),QP,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:PN}(),WS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof XS?new $CLJS.Dc(function(){return XS},$CLJS.kd(Rka,new $CLJS.h(null,
1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WL,"metabase/mbql/schema.cljc",19,$CLJS.CE,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.CE,$CLJS.aF,$CLJS.V($CLJS.jj,EM),$CLJS.bn,$CLJS.V($CLJS.jj,EM),$CLJS.vj,$CLJS.V($CLJS.jj,EM)],null),$CLJS.Hc,"Schema for a valid between clause.",
$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:WL}(),XS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof dT?new $CLJS.Dc(function(){return dT},$CLJS.kd(Tka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VP,"metabase/mbql/schema.cljc",23,$CLJS.SE,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.SE,$CLJS.aF,$CLJS.V($CLJS.jj,PP),oM,$CLJS.V($CLJS.jj,PP),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,kP))],null),$CLJS.Hc,"Schema for a valid starts-with clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:VP}(),dT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof eT?new $CLJS.Dc(function(){return eT},
$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tM,"metabase/mbql/schema.cljc",21,$CLJS.lF,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lF,$CLJS.aF,$CLJS.V($CLJS.jj,PP),oM,$CLJS.V($CLJS.jj,PP),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,kP))],null),
$CLJS.Hc,"Schema for a valid ends-with clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:tM}(),eT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof fT?new $CLJS.Dc(function(){return fT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",20,$CLJS.$E,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.$E,$CLJS.aF,$CLJS.V($CLJS.jj,PP),oM,$CLJS.V($CLJS.jj,PP),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,kP))],null),$CLJS.Hc,"Schema for a valid contains clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:FM}(),fT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==
typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof gT?new $CLJS.Dc(function(){return gT},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jL,"metabase/mbql/schema.cljc",36,$CLJS.TE,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.TE,$CLJS.aF,$CLJS.V($CLJS.jj,PP),oM,$CLJS.V($CLJS.jj,PP),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,kP))],null),$CLJS.Hc,"Schema for a valid does-not-contain clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:jL}(),gT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof YS?new $CLJS.Dc(function(){return YS},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),
$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",26,$CLJS.IE,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.IE,Cja,$CLJS.V($CLJS.jj,EM),Qla,$CLJS.V($CLJS.jj,EM),Bla,$CLJS.V($CLJS.jj,EM),Fja,$CLJS.V($CLJS.jj,EM),vla,$CLJS.V($CLJS.jj,EM),Bja,$CLJS.V($CLJS.jj,
EM)],null),$CLJS.Hc,"Schema for a valid inside clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:TP}(),YS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof aT?new $CLJS.Dc(function(){return aT},$CLJS.kd(hka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",28,$CLJS.DE,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DE,$CLJS.aF,$CLJS.V($CLJS.jj,xM)],null),$CLJS.Hc,"Schema for a valid is-empty clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:oO}(),aT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&
"undefined"!==typeof bT?new $CLJS.Dc(function(){return bT},$CLJS.kd(fla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",29,$CLJS.KE,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KE,$CLJS.aF,$CLJS.V($CLJS.jj,xM)],null),
$CLJS.Hc,"Schema for a valid not-empty clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:bP}(),bT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof ZS?new $CLJS.Dc(function(){return ZS},$CLJS.kd(Gla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LM,"metabase/mbql/schema.cljc",27,$CLJS.BE,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BE,$CLJS.aF,$CLJS.V($CLJS.jj,xM)],null),$CLJS.Hc,"Schema for a valid is-null clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:LM}(),ZS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&
"undefined"!==typeof $S?new $CLJS.Dc(function(){return $S},$CLJS.kd(tka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aM,"metabase/mbql/schema.cljc",28,$CLJS.NE,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NE,$CLJS.aF,$CLJS.V($CLJS.jj,xM)],null),
$CLJS.Hc,"Schema for a valid not-null clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:aM}(),$S],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof hT?new $CLJS.Dc(function(){return hT},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",33,$CLJS.VA,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.VA,$CLJS.aF,$CLJS.V($CLJS.jj,xM),$CLJS.Bj,$CLJS.V($CLJS.jj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.jr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wq,$CLJS.Iu,$CLJS.Nw,$CLJS.HE],null)],null)),$CLJS.uk,$CLJS.V($CLJS.jj,NN),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,Wka))],null),$CLJS.Hc,"Schema for a valid time-interval clause.",
$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:AP}(),hT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof iT?new $CLJS.Dc(function(){return iT},$CLJS.kd(Bka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CQ,"metabase/mbql/schema.cljc",27,$CLJS.yE,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yE,Jja,$CLJS.V($CLJS.jj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,lla,NL],null))],null),$CLJS.Hc,"Schema for a valid segment clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:CQ}(),iT],null)]))],null)],null));
$CLJS.X(nP,VK($CLJS.JE,$CLJS.H(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ms,":case subclause"],null),$CLJS.KS,VR],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),VR],null)],null)],null)])));
var jT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,nP],null);
$CLJS.X(UP,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof iS?new $CLJS.Dc(function(){return iS},$CLJS.kd(LO,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",51,$CLJS.Dq,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Dq,$CLJS.qD,$CLJS.V($CLJS.jj,PO),lQ,$CLJS.V($CLJS.jj,PO),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,PO))],null),$CLJS.Hc,"Schema for a valid + clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:zN}(),iS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof jS?new $CLJS.Dc(function(){return jS},
$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AN,"metabase/mbql/schema.cljc",51,$CLJS.Sr,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Sr,$CLJS.qD,$CLJS.V($CLJS.jj,KP),
lQ,$CLJS.V($CLJS.jj,PO),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,PO))],null),$CLJS.Hc,"Schema for a valid - clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:AN}(),jS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K?new $CLJS.Dc(function(){return kS},$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,cO,$CLJS.wi,$CLJS.Oi,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Uy,"metabase/mbql/schema.cljc",$CLJS.uF,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uF,$CLJS.qD,$CLJS.V($CLJS.jj,KP),lQ,$CLJS.V($CLJS.jj,KP),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,KP))],null),$CLJS.Hc,"Schema for a valid / clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.Uy}(),kS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof lS?new $CLJS.Dc(function(){return lS},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yN,"metabase/mbql/schema.cljc",51,$CLJS.Eq,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Eq,$CLJS.qD,$CLJS.V($CLJS.jj,KP),lQ,$CLJS.V($CLJS.jj,KP),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,KP))],null),$CLJS.Hc,"Schema for a valid * clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:yN}(),lS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof YR?new $CLJS.Dc(function(){return YR},
$CLJS.kd(xP,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",58,$CLJS.dF,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dF,uQ,$CLJS.V($CLJS.jj,oL),tQ,$CLJS.V($CLJS.jj,
oL),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,oL))],null),$CLJS.Hc,"Schema for a valid coalesce clause.",$CLJS.n(YR)?YR.H:null])):null));return $CLJS.n(a)?a:MP}(),YR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof $R?new $CLJS.Dc(function(){return $R},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,
$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",56,$CLJS.Hw,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Hw,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],null),$CLJS.Hc,"Schema for a valid length clause.",$CLJS.n($R)?$R.H:null])):null));return $CLJS.n(a)?a:eP}(),$R],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof mS?new $CLJS.Dc(function(){return mS},$CLJS.kd(Vja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$N,"metabase/mbql/schema.cljc",55,$CLJS.ZE,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZE,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid floor clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:$N}(),mS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof nS?new $CLJS.Dc(function(){return nS},$CLJS.kd(Pja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),
$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ML,"metabase/mbql/schema.cljc",54,$CLJS.vE,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vE,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid ceil clause.",$CLJS.n(nS)?
nS.H:null])):null));return $CLJS.n(a)?a:ML}(),nS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof oS?new $CLJS.Dc(function(){return oS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",55,$CLJS.fF,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fF,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid round clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:QN}(),oS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&
"undefined"!==typeof pS?new $CLJS.Dc(function(){return pS},$CLJS.kd(Mla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",53,$CLJS.AE,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.AE,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid abs clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:sO}(),pS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof qS?new $CLJS.Dc(function(){return qS},$CLJS.kd(zla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,
XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",69,$CLJS.xE,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xE,$CLJS.qD,$CLJS.V($CLJS.jj,KP),lQ,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid power clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:dQ}(),qS],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof rS?new $CLJS.Dc(function(){return rS},$CLJS.kd(wka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uN,"metabase/mbql/schema.cljc",68,$CLJS.OE,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OE,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid sqrt clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:uN}(),rS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof sS?new $CLJS.Dc(function(){return sS},$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),
$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",67,$CLJS.rE,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rE,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid exp clause.",$CLJS.n(sS)?
sS.H:null])):null));return $CLJS.n(a)?a:SN}(),sS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof tS?new $CLJS.Dc(function(){return tS},$CLJS.kd(Ija,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[IQ,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",67,$CLJS.wF,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wF,$CLJS.qD,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid log clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:NQ}(),tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&
"undefined"!==typeof jT?new $CLJS.Dc(function(){return jT},$CLJS.kd(zL,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",61,$CLJS.JE,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.JE,$CLJS.Uv,$CLJS.V($CLJS.jj,RM),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,CO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:jO}(),jT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof uS?new $CLJS.Dc(function(){return uS},$CLJS.kd(Lja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,
$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.PA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",65,$CLJS.PA,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.PA,Yja,$CLJS.V($CLJS.jj,YQ),Zja,$CLJS.V($CLJS.jj,YQ),$CLJS.uk,$CLJS.V($CLJS.jj,Eka)],null),$CLJS.Hc,"Schema for a valid datetime-diff clause.",
$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:tO}(),uS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof vS?new $CLJS.Dc(function(){return vS},$CLJS.kd(ila,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,
new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",71,$CLJS.UA,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UA,$CLJS.SH,$CLJS.V($CLJS.jj,YQ),$CLJS.uk,$CLJS.V($CLJS.jj,Fla),$CLJS.ei,$CLJS.V($CLJS.jj,$CLJS.V(aQ,fR))],null),$CLJS.Hc,"Schema for a valid temporal-extract clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:MO}(),vS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof wS?new $CLJS.Dc(function(){return wS},$CLJS.kd(pka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iM,"metabase/mbql/schema.cljc",71,$CLJS.NH,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,$CLJS.vO,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-year clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:iM}(),wS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof xS?new $CLJS.Dc(function(){return xS},$CLJS.kd(eka,new $CLJS.h(null,1,[$CLJS.qj,!0],
null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",74,$CLJS.cI,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cI,$CLJS.vO,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-quarter clause.",
$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:KO}(),xS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof yS?new $CLJS.Dc(function(){return yS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),
$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",72,$CLJS.GH,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,$CLJS.vO,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-month clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:gP}(),yS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==
typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof zS?new $CLJS.Dc(function(){return zS},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",
71,$CLJS.SA,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.SA,$CLJS.vO,$CLJS.V($CLJS.jj,YQ),$CLJS.ei,$CLJS.V($CLJS.jj,$CLJS.V(aQ,fR))],null),$CLJS.Hc,"Schema for a valid get-week clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:fP}(),zS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof AS?new $CLJS.Dc(function(){return AS},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.qj,
!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",70,$CLJS.WH,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,$CLJS.vO,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-day clause.",
$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:ZO}(),AS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof BS?new $CLJS.Dc(function(){return BS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),
$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",78,$CLJS.ZH,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.vO,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-day-of-week clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:QO}(),BS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==
typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof CS?new $CLJS.Dc(function(){return CS},$CLJS.kd(Dka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pL,"metabase/mbql/schema.cljc",
71,$CLJS.aI,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aI,$CLJS.SH,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-hour clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:pL}(),CS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof DS?new $CLJS.Dc(function(){return DS},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,
$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",73,$CLJS.YH,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,$CLJS.SH,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-minute clause.",$CLJS.n(DS)?DS.H:null])):null));
return $CLJS.n(a)?a:RQ}(),DS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof ES?new $CLJS.Dc(function(){return ES},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UA,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,
[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",73,$CLJS.MH,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,$CLJS.SH,$CLJS.V($CLJS.jj,YQ)],null),$CLJS.Hc,"Schema for a valid get-second clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:lO}(),ES],null)])));
$CLJS.X(EL,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof ZR?new $CLJS.Dc(function(){return ZR},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oP,"metabase/mbql/schema.cljc",59,$CLJS.sE,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.sE,$CLJS.dx,$CLJS.V($CLJS.jj,PP),$CLJS.nu,$CLJS.V($CLJS.jj,aka),$CLJS.Hw,$CLJS.V($CLJS.jj,$CLJS.V(aQ,KP))],null),$CLJS.Hc,"Schema for a valid substring clause.",$CLJS.n(ZR)?ZR.H:null])):null));return $CLJS.n(a)?a:oP}(),ZR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof aS?new $CLJS.Dc(function(){return aS},
$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",54,$CLJS.pF,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pF,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],
null),$CLJS.Hc,"Schema for a valid trim clause.",$CLJS.n(aS)?aS.H:null])):null));return $CLJS.n(a)?a:LP}(),aS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof cS?new $CLJS.Dc(function(){return cS},$CLJS.kd(Gka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",55,$CLJS.rF,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rF,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],null),$CLJS.Hc,"Schema for a valid ltrim clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:uP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&
"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof bS?new $CLJS.Dc(function(){return bS},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",
55,$CLJS.yF,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yF,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],null),$CLJS.Hc,"Schema for a valid rtrim clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:xO}(),bS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof fS?new $CLJS.Dc(function(){return fS},$CLJS.kd(uja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,
$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",57,$CLJS.xF,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.xF,$CLJS.dx,$CLJS.V($CLJS.jj,PP),Gja,$CLJS.V($CLJS.jj,$CLJS.pj),$CLJS.az,$CLJS.V($CLJS.jj,$CLJS.pj)],null),$CLJS.Hc,"Schema for a valid replace clause.",
$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:wQ}(),fS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof eS?new $CLJS.Dc(function(){return eS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,
new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",55,$CLJS.is,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.is,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],null),$CLJS.Hc,"Schema for a valid lower clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:aR}(),eS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==
typeof $K&&"undefined"!==typeof dS?new $CLJS.Dc(function(){return dS},$CLJS.kd(Dla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",55,$CLJS.cs,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cs,$CLJS.dx,$CLJS.V($CLJS.jj,PP)],null),$CLJS.Hc,"Schema for a valid upper clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:YP}(),dS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof gS?new $CLJS.Dc(function(){return gS},$CLJS.kd(Vka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,
$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lN,"metabase/mbql/schema.cljc",56,$CLJS.zE,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.zE,uQ,$CLJS.V($CLJS.jj,PP),tQ,$CLJS.V($CLJS.jj,PP),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,PP))],null),$CLJS.Hc,"Schema for a valid concat clause.",
$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:lN}(),gS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof hS?new $CLJS.Dc(function(){return hS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.TO,"null",$CLJS.Bk,"null"],
null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",74,$CLJS.cF,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.cF,$CLJS.dx,$CLJS.V($CLJS.jj,PP),$CLJS.$y,$CLJS.V($CLJS.jj,$CLJS.pj)],null),$CLJS.Hc,"Schema for a valid regex-match-first clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:gM}(),hS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==
typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof YR?new $CLJS.Dc(function(){return YR},$CLJS.kd(xP,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TO,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
MP,"metabase/mbql/schema.cljc",58,$CLJS.dF,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dF,uQ,$CLJS.V($CLJS.jj,oL),tQ,$CLJS.V($CLJS.jj,oL),BM,$CLJS.V($CLJS.jj,$CLJS.V($CLJS.qi,oL))],null),$CLJS.Hc,"Schema for a valid coalesce clause.",$CLJS.n(YR)?YR.H:null])):null));return $CLJS.n(a)?a:MP}(),YR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof jT?new $CLJS.Dc(function(){return jT},
$CLJS.kd(zL,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",61,$CLJS.JE,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.JE,$CLJS.Uv,$CLJS.V($CLJS.jj,RM),
$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,CO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:jO}(),jT],null)])));
$CLJS.kT=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,":field or :expression reference or expression",$CLJS.si,function(a){return $CLJS.n(UK(LR,a))?ZQ:$CLJS.n(UK(IR,a))?$CLJS.pj:$CLJS.n(UK(MR,a))?$CLJS.or:$CLJS.n(UK(OR,a))?$CLJS.SH:$CLJS.n(UK($CLJS.JE,a))?$CLJS.JE:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[ZQ,PR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pj,JR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.or,QR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,$CLJS.RR],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JE,jT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,$CLJS.FR],null)],null);$CLJS.X(FQ,VK($CLJS.Jw,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,$CLJS.FR],null)])));var lT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,FQ],null);$CLJS.X(uO,VK($CLJS.bF,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tr,$CLJS.FR],null)])));var mT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,uO],null);$CLJS.X(CL,VK($CLJS.VE,$CLJS.H(["field-or-expression",$CLJS.kT])));
var nT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,CL],null);$CLJS.X(xQ,VK($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.kT])));var oT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,xQ],null);$CLJS.X(bN,VK($CLJS.FE,$CLJS.H(["field-or-expression",$CLJS.kT])));var pT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bN],null);$CLJS.X(NP,VK($CLJS.mF,$CLJS.H(["field-or-expression",$CLJS.kT])));var qT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,NP],null);$CLJS.X(XM,VK($CLJS.bn,$CLJS.H(["field-or-expression",$CLJS.kT])));
var rT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XM],null);$CLJS.X(JM,VK($CLJS.vj,$CLJS.H(["field-or-expression",$CLJS.kT])));var sT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,JM],null);$CLJS.X(DM,VK($CLJS.tF,$CLJS.H(["field-or-expression",$CLJS.kT,"pred",$CLJS.KS])));var tT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,DM],null);$CLJS.X(UM,VK($CLJS.jF,$CLJS.H(["pred",$CLJS.KS])));var uT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,UM],null);$CLJS.X(EP,VK($CLJS.hF,$CLJS.H(["pred",$CLJS.KS])));
var vT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,EP],null);$CLJS.X($L,VK($CLJS.oF,$CLJS.H(["field-or-expression",$CLJS.kT])));var wT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$L],null);$CLJS.X(bL,VK($CLJS.GE,$CLJS.H(["field-or-expression",$CLJS.kT])));var xT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bL],null);$CLJS.X(fN,VK($CLJS.nF,$CLJS.H(["field-or-expression",$CLJS.kT])));var yT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,fN],null);$CLJS.X(VN,VK($CLJS.ME,$CLJS.H(["field-or-expression",$CLJS.kT,"percentile",TR])));
var zT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,VN],null);$CLJS.X(HP,VK($CLJS.WE,$CLJS.H(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.DJ],null),gR],null)])));var AT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,HP],null);
$CLJS.X(DP,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"unnamed aggregation clause or numeric expression",$CLJS.si,function(a){return $CLJS.n(UK(LR,a))?YN:$CLJS.es}],null),new $CLJS.Q(null,2,5,$CLJS.R,[YN,PR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.es,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof nT?new $CLJS.Dc(function(){return nT},$CLJS.kd(ala,
new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",60,$CLJS.VE,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VE,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid avg clause.",
$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:gO}(),nT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof oT?new $CLJS.Dc(function(){return oT},$CLJS.kd(lka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,
new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",64,$CLJS.vF,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid cum-sum clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:kQ}(),oT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==
typeof $K&&"undefined"!==typeof pT?new $CLJS.Dc(function(){return pT},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",65,$CLJS.FE,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FE,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid distinct clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:kO}(),pT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof wT?new $CLJS.Dc(function(){return wT},$CLJS.kd(wla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,
$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.kF,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wL,"metabase/mbql/schema.cljc",76,$CLJS.oF,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oF,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid stddev clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:wL}(),wT],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof qT?new $CLJS.Dc(function(){return qT},$CLJS.kd(Qka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SL,"metabase/mbql/schema.cljc",60,$CLJS.mF,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mF,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid sum clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:SL}(),qT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof rT?new $CLJS.Dc(function(){return rT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,
$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Fr,"metabase/mbql/schema.cljc",60,$CLJS.bn,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bn,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid min clause.",$CLJS.n(rT)?rT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Fr}(),rT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof sT?new $CLJS.Dc(function(){return sT},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Jr,"metabase/mbql/schema.cljc",60,$CLJS.vj,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vj,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid max clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Jr}(),sT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&
"undefined"!==typeof AT?new $CLJS.Dc(function(){return AT},$CLJS.kd($ja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",18,$CLJS.WE,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WE,mka,$CLJS.V($CLJS.jj,new $CLJS.Q(null,3,
5,$CLJS.R,[$CLJS.Zq,qka,NL],null))],null),$CLJS.Hc,"Schema for a valid metric clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:iN}(),AT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof vT?new $CLJS.Dc(function(){return vT},$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,
$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aN,"metabase/mbql/schema.cljc",62,$CLJS.hF,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hF,$CLJS.qn,$CLJS.V($CLJS.jj,TN)],null),$CLJS.Hc,"Schema for a valid share clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:aN}(),vT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof uT?new $CLJS.Dc(function(){return uT},$CLJS.kd(Eja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OL,"metabase/mbql/schema.cljc",68,$CLJS.jF,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jF,$CLJS.qn,$CLJS.V($CLJS.jj,TN)],null),$CLJS.Hc,"Schema for a valid count-where clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:OL}(),uT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof tT?new $CLJS.Dc(function(){return tT},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.qj,!0],
null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hO,"metabase/mbql/schema.cljc",66,$CLJS.tF,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.tF,ZP,$CLJS.V($CLJS.jj,RP),$CLJS.qn,$CLJS.V($CLJS.jj,TN)],null),$CLJS.Hc,"Schema for a valid sum-where clause.",
$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:hO}(),tT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof jT?new $CLJS.Dc(function(){return jT},$CLJS.kd(zL,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,
new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",61,$CLJS.JE,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.JE,$CLJS.Uv,$CLJS.V($CLJS.jj,RM),$CLJS.Ji,$CLJS.V($CLJS.jj,$CLJS.V(aQ,CO))],null),$CLJS.Hc,"Schema for a valid case clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:jO}(),jT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&
"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof yT?new $CLJS.Dc(function(){return yT},$CLJS.kd(pla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",
68,$CLJS.nF,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nF,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid median clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:iQ}(),yT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof zT?new $CLJS.Dc(function(){return zT},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,
cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.XE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",72,$CLJS.ME,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ME,ZP,$CLJS.V($CLJS.jj,RP),$CLJS.ME,$CLJS.V($CLJS.jj,KP)],null),$CLJS.Hc,"Schema for a valid percentile clause.",$CLJS.n(zT)?zT.H:null])):
null));return $CLJS.n(a)?a:GP}(),zT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof xT?new $CLJS.Dc(function(){return xT},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rL,"metabase/mbql/schema.cljc",77,$CLJS.GE,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GE,ZP,$CLJS.V($CLJS.jj,RP)],null),$CLJS.Hc,"Schema for a valid var clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:rL}(),xT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof mT?new $CLJS.Dc(function(){return mT},$CLJS.kd(Aja,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,
$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",74,$CLJS.bF,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bF,$CLJS.aF,$CLJS.V($CLJS.jj,$CLJS.V(aQ,xM))],null),$CLJS.Hc,"Schema for a valid cum-count clause.",
$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:UN}(),mT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof lT?new $CLJS.Dc(function(){return lT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([aO,$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,cQ,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uE,"null"],null),null),
$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.di,"metabase/mbql/schema.cljc",70,$CLJS.Jw,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Jw,$CLJS.aF,$CLJS.V($CLJS.jj,$CLJS.V(aQ,xM))],null),$CLJS.Hc,"Schema for a valid count clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:$CLJS.di}(),lT],null)]))],null)],null));
$CLJS.X(WO,VK($CLJS.mL,$CLJS.H(["aggregation",DP,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.ms,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null)],null)])));
$CLJS.X($P,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,2,[$CLJS.ms,"aggregation clause or numeric expression",$CLJS.si,function(a){return $CLJS.n(UK($CLJS.mL,a))?$CLJS.mL:QM}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,WO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[QM,DP],null)],null));$CLJS.X(vL,VK($CLJS.YE,$CLJS.H(["field",HR])));var BT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,vL],null);$CLJS.X(XN,VK($CLJS.RE,$CLJS.H(["field",HR])));
CT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,XN],null);
bma=WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof BT?new $CLJS.Dc(function(){return BT},$CLJS.kd(tla,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
DN,"metabase/mbql/schema.cljc",15,$CLJS.YE,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YE,$CLJS.aF,$CLJS.V($CLJS.jj,sQ)],null),$CLJS.Hc,"Schema for a valid asc clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:DN}(),BT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof CT?new $CLJS.Dc(function(){return CT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,
$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",16,$CLJS.RE,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RE,$CLJS.aF,$CLJS.V($CLJS.jj,sQ)],null),$CLJS.Hc,"Schema for a valid desc clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:yP}(),CT],null)]));
DT=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wq,$CLJS.yL,$CLJS.nM,$CLJS.tE,$CLJS.Is,$CLJS.nA,$CLJS.vO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,gR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cD,gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null)],null);
cma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,DT,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.yL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kL,gR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NO,jR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),jR],null)],null)],null);
dma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,DT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.nM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WP,jR],null)],null)],null);
ET=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,DT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pr],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null)],null)],null);ema=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,jQ],null);
fma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,ET,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cj,$CLJS.tE],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tE,ER],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,RL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ji,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,$CLJS.Bi,$CLJS.pr],null)],null)],null)],null);
$CLJS.FT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.vO,null,$CLJS.Is,null,$CLJS.or,null,$CLJS.nA,null],null),null);gma=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wq],null),$CLJS.FT);
$CLJS.X(rP,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Ni],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tE,fma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yL,cma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nM,dma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rn,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,ET,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,gma],null)],null)],null)],null)],null));
var GT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,gR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,rP],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,gR],null)],null)],null),hma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,GT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wE,$CLJS.pr],null)],null)],null),HT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$O],null),IT=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.si,function(a){return $CLJS.n($CLJS.WC($CLJS.td,
$CLJS.cP)(a))?$CLJS.cP:$CLJS.bD}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,GT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cP,$CLJS.pr],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bD,HT],null)],null),JT=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.U,gR],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UB,hR],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ska,gR],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.xN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,iR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.uj],null)],null)],null),KT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,Ula,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$q,new $CLJS.h(null,1,[$CLJS.ms,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),ima=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Wq],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.YD,null,$CLJS.HD,null,$CLJS.RD,null,$CLJS.VD,null],null),null)),LT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,hQ],null);
$CLJS.X($M,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),KT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),IT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JQ,$CLJS.KS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),ima],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,$CLJS.ox,$CLJS.Vy],null),LT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MD,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,lR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LN,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,JT],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.WC($CLJS.pz($CLJS.ZN,$CLJS.BP),$CLJS.Oe($CLJS.WC($CLJS.ZN,$CLJS.BP)))],null)],null));var jma=$CLJS.R,MT;var NT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$M],null)],null);
if($CLJS.sd(NT)&&$CLJS.E.g($CLJS.z(NT),$CLJS.Yi)){var OT=$CLJS.y(NT);$CLJS.z(OT);var PT=$CLJS.B(OT),kma=$CLJS.td($CLJS.z(PT))?PT:$CLJS.be(null,PT),QT=$CLJS.y(kma),lma=$CLJS.z(QT),mma=$CLJS.B(QT);MT=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,$CLJS.T.j(lma,$CLJS.bn,1)],null),mma)}else MT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,NT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(bM,new $CLJS.Q(null,3,5,jma,[$CLJS.er,MT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"All join aliases must be unique."],null),function(a){return $CLJS.GK($CLJS.ff($CLJS.Ua,$CLJS.cf.g($CLJS.MD,a)))}],null)],null));var nma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bM],null);
$CLJS.X(hQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kk,new $CLJS.h(null,1,[$CLJS.ms,"Distinct, non-empty sequence of Field clauses"],null),XK(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),$CLJS.FR],null))],null));
$CLJS.X($O,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),IT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),KT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),$CLJS.SR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PE,new $CLJS.h(null,1,
[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),$CLJS.FR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,gR,$CLJS.kT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.tr,!0],null),LT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.KS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dR,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),kR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),XK(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.bn,1],null),bma],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[dP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[dP,jR],null),new $CLJS.Q(null,2,5,$CLJS.R,[Tja,jR],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),nma],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,JT],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.xl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BP,$CLJS.ZN],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,
new $CLJS.h(null,1,[$CLJS.ms,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.PE);b=$CLJS.J.g(b,$CLJS.ID);return $CLJS.pd($CLJS.Ws.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.RT=$CLJS.zg([$CLJS.sF,$CLJS.vO,WM,HM,MN,VQ,sN,$CLJS.UQ,$CLJS.Is,rN,FL,OP,KQ,$CLJS.VL,GQ,TM,$CLJS.RN,pN,$CLJS.Di,$CLJS.oN,nL,$CLJS.rM,$CLJS.nN,qO,$CLJS.or,$CLJS.nA,hN],[new $CLJS.h(null,1,[CN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.vO,null,$CLJS.Is,null,$CLJS.or,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.vO,null,$CLJS.UQ,null,$CLJS.RN,null,$CLJS.Di,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,XP,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[WM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,ZQ,$CLJS.kD,XP,CN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,HM,null,$CLJS.Is,null,$CLJS.Di,null,nL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[MN,null],null),null)],null),new $CLJS.h(null,1,[CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[VQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,
ZQ,$CLJS.kD,jla,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[sN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.UQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,ZQ,CN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,HM,null,$CLJS.Is,null,$CLJS.Di,null,nL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,ZQ,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[rN,null],null),null)],null),new $CLJS.h(null,1,[CN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[FL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,XP,CN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.sF,null,VQ,null,FL,null,OP,null,pN,null,$CLJS.Di,null,nL,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[KQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.UQ,null,$CLJS.VL,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[GQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,$CLJS.pj,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[TM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.sF,null,$CLJS.vO,null,$CLJS.UQ,null,$CLJS.RN,null,$CLJS.Di,null],null),null)],null),new $CLJS.h(null,1,[CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[pN,null],null),null)],null),new $CLJS.h(null,1,[CN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Di,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.UQ,null,$CLJS.oN,null],null),null)],null),new $CLJS.h(null,1,[CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[nL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.UQ,null,$CLJS.rM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.vO,CN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.UQ,
null,$CLJS.nN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,ZQ,$CLJS.kD,XP,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[qO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.or,CN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.sF,null,$CLJS.Di,null,$CLJS.or,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ni,$CLJS.pj,CN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.sF,null,VQ,null,FL,null,OP,null,pN,null,$CLJS.Di,null,nL,null,$CLJS.nA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ni,
ZQ,$CLJS.kD,JL,CN,new $CLJS.Rg(null,new $CLJS.h(null,1,[hN,null],null),null)],null)]);$CLJS.X(jQ,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.RT)));$CLJS.X(RL,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ms,"valid template tag widget type"],null),$CLJS.Vy],null),$CLJS.Ng($CLJS.RT)));
$CLJS.X(wP,VK(Kja,$CLJS.H(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,gR,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Di,gR],null)],null)],null)])));var ST=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,wP],null);$CLJS.X(qL,VK($CLJS.tE,$CLJS.H(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.FR,ST],null)])));var TT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,qL],null);$CLJS.X(lM,VK(mN,$CLJS.H(["target",ST])));
var UT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,lM],null),oma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,$CLJS.FR,WK($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof TT?new $CLJS.Dc(function(){return TT},$CLJS.kd(Ika,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,
1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",21,$CLJS.tE,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tE,$CLJS.fI,$CLJS.V($CLJS.jj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zq,xM,BN],null))],null),$CLJS.Hc,"Schema for a valid dimension clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:hP}(),TT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=cO.h($CLJS.ld("undefined"!==typeof $CLJS.YK&&
"undefined"!==typeof ZK&&"undefined"!==typeof $K&&"undefined"!==typeof UT?new $CLJS.Dc(function(){return UT},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.qj,!0],null)),$CLJS.zg([$CLJS.Ai,$CLJS.U,$CLJS.Aj,$CLJS.ci,cO,$CLJS.wi,$CLJS.Oi,$CLJS.Hj,XL,$CLJS.bi,$CLJS.wj,$CLJS.zj],[$CLJS.kd(IP,new $CLJS.h(null,1,[$CLJS.wj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",20,mN,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[mN,
$CLJS.fI,$CLJS.V($CLJS.jj,BN)],null),$CLJS.Hc,"Schema for a valid variable clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:$Q}(),UT],null)]))],null),RK=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,YL],null);
$CLJS.X(YL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.er,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.WC($CLJS.pz($CLJS.cP,$CLJS.wE),$CLJS.Oe($CLJS.WC($CLJS.cP,$CLJS.wE)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.cP);var d=$CLJS.J.g(c,$CLJS.wE);c=$CLJS.J.g(c,$CLJS.Ni);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zq,new $CLJS.h(null,1,[$CLJS.ms,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.WJ],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.W,$CLJS.PJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wq,$CLJS.wE,$CLJS.cP],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),hma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.tr,!0],null),HT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,
9,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ni,ema],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.tr,!0],null),oma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pr],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.tr,!0],null),gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[Qja,new $CLJS.h(null,1,[$CLJS.tr,!0],null),
gR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pr],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.pr],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[Ila,new $CLJS.h(null,1,[$CLJS.tr,!0],null),tR],null)],null)],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Hka,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.er,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[iO,new $CLJS.h(null,1,[$CLJS.tr,!0],null),kR],null),new $CLJS.Q(null,3,5,$CLJS.R,[QL,new $CLJS.h(null,1,[$CLJS.tr,!0],null),kR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,iO);b=$CLJS.J.g(b,QL);return $CLJS.Ta($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[xka,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[sla,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[Mja,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[vja,new $CLJS.h(null,1,[$CLJS.tr,
!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sja,new $CLJS.h(null,1,[$CLJS.tr,!0],null),$CLJS.or],null),new $CLJS.Q(null,3,5,$CLJS.R,[kla,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[xja,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[yla,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.or],
null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yx,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hM,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Wq,Xka,Uka,$CLJS.dN,Wja,Sla,Zka,nka,Jka,fka,Nja,jka,hla,vka,Hja,Xja,Rla,Ola],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[nla,new $CLJS.h(null,1,[$CLJS.tr,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[ska,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,Tla],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Jla,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,gR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[uka,new $CLJS.h(null,
1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Hla,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,$CLJS.pr,$CLJS.pr],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[oka,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,jR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gN,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.Yq,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,$CLJS.pr,$CLJS.pr],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BJ,new $CLJS.h(null,1,[$CLJS.tr,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yq,$CLJS.pr],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ms,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.LN)],
null)],null));var VT=$CLJS.QK(RK);(function(){var a=qja();return function(b){if($CLJS.n(VT.h?VT.h(b):VT.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.NK(b);throw $CLJS.Th($CLJS.YC("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.ux,c,Cka,b],null));}})();
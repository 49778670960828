var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var rpa,spa,tpa,upa,vpa,f0;
$CLJS.c0=function(){function a(e,f){if($CLJS.Ta(f))return"";e=$CLJS.oz(e);var k=$CLJS.mh.h(f);switch(k instanceof $CLJS.M?k.S:null){case "default":return $CLJS.qU("Default period","Default periods",e);case "millisecond":return $CLJS.qU("Millisecond","Milliseconds",e);case "second":return $CLJS.qU("Second","Seconds",e);case "minute":return $CLJS.qU("Minute","Minutes",e);case "hour":return $CLJS.qU("Hour","Hours",e);case "day":return $CLJS.qU("Day","Days",e);case "week":return $CLJS.qU("Week","Weeks",
e);case "month":return $CLJS.qU("Month","Months",e);case "quarter":return $CLJS.qU("Quarter","Quarters",e);case "year":return $CLJS.qU("Year","Years",e);case "minute-of-hour":return $CLJS.qU("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.qU("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.qU("Day of week","Days of week",e);case "day-of-month":return $CLJS.qU("Day of month","Days of month",e);case "day-of-year":return $CLJS.qU("Day of year","Days of year",e);case "week-of-year":return $CLJS.qU("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.qU("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.qU("Quarter of year","Quarters of year",e);default:return f=$CLJS.Gs($CLJS.Xg(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.as(" ",$CLJS.be($CLJS.lz(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.d0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.OA(f)},e,a,b,c,d)}();rpa=$CLJS.Se($CLJS.N);spa=$CLJS.Se($CLJS.N);
tpa=$CLJS.Se($CLJS.N);upa=$CLJS.Se($CLJS.N);vpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.e0=new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.OA,vpa,rpa,spa,tpa,upa);$CLJS.e0.m(null,$CLJS.Oh,function(){return null});$CLJS.e0.m(null,$CLJS.TF,function(a){return $CLJS.uk.h(a)});f0=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.MF,null,$CLJS.PF,null,$CLJS.QF,null,$CLJS.NF,null],null),null);
$CLJS.wpa=$CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.Yj.h(f0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Os,$CLJS.TF,$CLJS.uk,a],null);return $CLJS.E.g(a,$CLJS.Ek)?$CLJS.T.j(b,$CLJS.Oh,!0):b})),$CLJS.dG);$CLJS.xpa=$CLJS.Zj.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Os,$CLJS.TF,$CLJS.uk,a],null);return $CLJS.E.g(a,$CLJS.Fk)?$CLJS.T.j(b,$CLJS.Oh,!0):b},$CLJS.ZF);
$CLJS.ypa=$CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.Yj.h(f0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Os,$CLJS.TF,$CLJS.uk,a],null);return $CLJS.E.g(a,$CLJS.Fk)?$CLJS.T.j(b,$CLJS.Oh,!0):b})),$CLJS.eG);$CLJS.x_.m(null,$CLJS.TF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.uk);return $CLJS.c0.h(a)});
$CLJS.E_.m(null,$CLJS.TF,function(a,b,c){return $CLJS.pk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.cD,$CLJS.WZ.j(a,b,c),$CLJS.v_,$CLJS.Qz($CLJS.e0.h(c)),$CLJS.e_,$CLJS.Dd($CLJS.gG,$CLJS.e0.h(c))],null),$CLJS.xl(c,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Oh,$CLJS.d_],null))]))});
$CLJS.g0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.zk,$CLJS.YA],null),$CLJS.zk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.OA(l)},e,a,b,c,d)}();$CLJS.g0.m(null,$CLJS.Oh,function(){return $CLJS.Sg});
$CLJS.h0=function(){function a(d,e,f){return $CLJS.g0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();